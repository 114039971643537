import React,{createContext} from 'react';
//import {FirebaseApi} from './firebaseApi';
//import { Subtract } from 'utility-types';

const FirebaseContext = createContext(null);



export const withFirebaseApi = (Component) => {
 return (props) => (
  <FirebaseContext.Consumer>
    {firebaseApi => <Component {...props } firebaseApi={firebaseApi} />}
  </FirebaseContext.Consumer>
 );
};

export default FirebaseContext;
