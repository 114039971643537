import { Typography, Box, useTheme } from "@mui/material";
import { checkSex, showSexInfo } from "../utils/sex";
import { tokens } from "../theme";
import useMediaQuery from "@mui/material/useMediaQuery";

const UserInfo = ({user, date, fullWidth})=>{
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:1366px)");
  const isNonTablet = useMediaQuery("(max-width:375px)");
  //console.log(user)

  return (
    <Box
      display="grid"
      gap="10px"
      gridTemplateColumns="repeat(4, minmax(0, 1fr))"
      backgroundColor={colors.primary[400]}
      borderRadius="10px"
      sx={{
        "& > div": { gridColumn: isNonMobile ? "span 1" :isNonTablet? fullWidth? "span 1":"span 4" :"span 2"},
      }}
    >
      {user.name && (
        <Box sx={{ gridColumn: "span 1" }}>
          {" "}
          <Typography variant="h4" fontWeight="bold">
            이름 : <span>{user.name}</span>
          </Typography>
        </Box>
      )}
      {user.age && (
        <Box sx={{ gridColumn: "span 1" }}>
          <Typography variant="h4" fontWeight="bold">
            나이 : <span>{user.age}</span>
          </Typography>
        </Box>
      )}

      {user.weight && (
        <Box sx={{ gridColumn: "span 1" }}>
          <Typography variant="h4" fontWeight="bold">
            몸무게 : <span>{user.weight}</span>
          </Typography>
        </Box>
      )}

      {user.height && (
        <Box sx={{ gridColumn: "span 1" }}>
          <Typography variant="h4" fontWeight="bold">
            키 : <span>{user.height}</span>
          </Typography>
        </Box>
      )}

      {checkSex(user.sex) && (
        <Box sx={{ gridColumn: "span 1" }}>
          <Typography variant="h4" fontWeight="bold">
            성별 : <span> {showSexInfo(user.sex)}</span>
          </Typography>
        </Box>
      )}

      {user.phoneNumber && (
        <Box sx={{ gridColumn: "span 1" }}>
          <Typography variant="h4" fontWeight="bold">
            연락처 : <span> {user.phoneNumber}</span>
          </Typography>
        </Box>
      )}

      {date && (
        <Box sx={{ gridColumn: "span 1" }}>
          <Typography variant="h4" fontWeight="bold">
            검사 일자 : <span>{date}</span>
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default UserInfo;