import { collection, addDoc, getDoc, doc, deleteDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import { query, orderBy, getDocs ,where} from "firebase/firestore";

const baseUrl = "users";

export const getFbTrainerList = async (name) => {
  //console.log(name);
  const q = query(
    collection(db, baseUrl),
    where("username", "==", name)
  );
  const fbTrainerNameList = [];
  try {
    await getDocs(q).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        fbTrainerNameList.push({ id: doc.id, ...doc.data() });
      });
    });
    //console.log("fbTrainerNameList", fbTrainerNameList);
    return fbTrainerNameList;

    //console.log(fbSeniorNameList);
  } catch (e) {
    console.log(e);
  }
};
