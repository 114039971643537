import { useTheme, Box, Typography, Stack } from "@mui/material";
import { tokens } from "../../../../theme";
import { getOverAllColorByValue } from "../../../../utils/fitness";

const CompareOverAll = ({ record }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  //console.log(record)
  return (
    <Box>
      <Typography
        variant="h3"
        color={colors.greenAccent[400]}
        fontWeight="bold"
        m="18px 0"
      >
        기록 요약
      </Typography>
      <Box
        display="flex"
        /*width="100%"*/
        width="25rem"
        margin="3px 0"
        flexDirection="column"
        justifyContent="space-between"
      >
        {record.map((item) => (
          <Box
            display="flex"
            margin="8px 0"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
            key={item.date}
          >
            <Typography variant="h4" fontWeight="bold" color="textPrimary">
              {item.date}
            </Typography>
            <Stack direction="column" width="55%" marginBottom="8px">
              <Stack direction="row" spacing={1} marginBottom="8px">
                <Box
                  backgroundColor={getOverAllColorByValue(
                    colors,
                    item.overAll.value
                  )}
                  padding="5px 10px"
                  borderRadius="10px"
                  color="white"
                  fontWeight="bold"
                >
                  {item.overAll.score}점 / {item.overAll.total}점
                </Box>
                <Box
                  backgroundColor={getOverAllColorByValue(
                    colors,
                    item.overAll.value
                  )}
                  padding="5px 10px"
                  borderRadius="10px"
                  color="white"
                  fontWeight="bold"
                >
                  {item.overAll.value}
                </Box>
              </Stack>
              <Stack direction="row" spacing={3}>
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  color="textPrimary"
                >
                  {item.overAll.step} 상태
                </Typography>
              </Stack>
            </Stack>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default CompareOverAll;
