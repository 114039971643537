import { useEffect, useState } from "react";
import { tokens } from "../../../../theme";
import { useTheme, Box, useMediaQuery, Typography } from "@mui/material";
import { getFbSeniorById } from "../../../../apis/senior";
import { getFbNutritionById } from "../../../../apis/nutrition";
import UserInfo from "../../../userInfo";
import {Question} from "./question";
import BMI from "./bmi";
import OverAll from "./overAll";
import PngBtn from "../../../pngBtn";

const SingleReport = ({ id, name, recordId }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:1366px)");
  const isNonTablet = useMediaQuery("(max-width:500px)");
  const [fullWidth, setFullWidth] = useState(false);
  const [senior, setSenior] = useState(null);
  const [record, setRecord] = useState(null);


  useEffect(() => {
    async function fetchSeniorData() {
      const response = await getFbSeniorById(id);
      setSenior(response);
    }
    fetchSeniorData();
  }, [id]);

  useEffect(() => {
    async function fetchNutritionData(){
      const response = await getFbNutritionById(id, recordId[0])
      //console.log("fetchNutritionData",response);
      setRecord(response);
    }
    fetchNutritionData();

  }, [recordId]);

  //console.log("record", record)


  return (
    <Box m="20px">
      <Box
        className="PdfArea"
        display="grid"
        gap="10px"
        gridTemplateColumns="repeat(8, minmax(0, 1fr))"
        backgroundColor={colors.primary[400]}
        borderRadius="10px"
        sx={{
          width: isNonMobile
            ? "auto"
            : isNonTablet
            ? fullWidth
              ? "1366px !important"
              : "500px"
            : fullWidth
            ? "1366px !important"
            : undefined,
        }}
      >
        {/* User Info */}
        <Box m="0 20px" p="20px" sx={{ gridColumn: "span 8" }}>
          {senior && record && (
            <UserInfo user={senior} date={record.date} fullWidth={fullWidth} />
          )}
        </Box>
        <Box m="0 20px" p="20px" sx={{ gridColumn: "span 8" }}>
          {senior && record && (
            <Question record={record} fullWidth={fullWidth} />
          )}
        </Box>
        {/* BMI */}
        <Box
          m="0 20px"
          p="20px"
          sx={{ gridColumn: isNonMobile ? "span 4" : "span 8" }}
        >
          {senior && record && (
            <BMI record={{ bmi: record.BMIData }} fullWidth={fullWidth} />
          )}
        </Box>
        <Box
          m="0 20px"
          p="20px"
          sx={{ gridColumn: isNonMobile ? "span 4" : "span 8" }}
        >
          {senior && record && (
            <OverAll
              record={{ overAll: record.overAll }}
              fullWidth={fullWidth}
            />
          )}
        </Box>
      </Box>
      <PngBtn setReportWidth={setFullWidth} />
    </Box>
  );

};

export default SingleReport;
