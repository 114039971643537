import {useState, useEffect} from "react";
import { ResponsiveRadar } from "@nivo/radar";
import { useTheme, Box, Typography } from "@mui/material";
import { tokens, nivoTheme } from "../../../../theme";
import useMediaQuery from "@mui/material/useMediaQuery";

const CompareRadial = ({current, previous}) => {
  //console.log(current, previous)

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:1366px)");
    const [data, setData] = useState([]);

    useEffect(() => {
      if (current && previous) {
        const response = [
          {
            id: "balance",
            [current.date]: current.balance.score,
            [previous.date]: previous.balance.score,
          },
          {
            id: "speed",
            [current.date]: current.speed.score,
            [previous.date]: previous.speed.score,
          },
          {
            id: "musculus",
            [current.date]: current.musculus.score,
            [previous.date]: previous.musculus.score,
          },
        ];

        setData(response);
      } else {
        return;
      }
    }, [current, previous]);

    return (
      <Box height="45vh" width="35vh">


        {current && previous && data && (
                  <><Box>
            <Typography
              variant="h3"
              color={colors.greenAccent[400]}
              fontWeight="bold"
              m="18px 0"
            >
              총점 비교
            </Typography>
          </Box>
          <ResponsiveRadar
              data={data}
              keys={[`${current.date}`, `${previous.date}`]}
              theme={nivoTheme(colors)}
              colors={{ scheme: "pastel1" }}
              indexBy="id"
              valueFormat=">-.2f"
              fillOpacity={0.9}
              blendMode="multiply"
              motionConfig="wobbly"
    
              margin={{ top: 50, right: 80, bottom: 50, left: 80 }}
              borderColor={{ from: "color" }}
              gridLabelOffset={20}
              dotSize={3}
              dotColor={{ theme: "background" }}
              dotBorderWidth={2}
              legends={[
                {
                  anchor: "top-left",
                  direction: "column",
                  translateX: -50,
                  translateY: -40,
                  itemWidth: 80,
                  itemHeight: 20,
                  itemTextColor: colors.grey[100],
                  symbolSize: 12,
                  symbolShape: "circle",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemTextColor: colors.grey[100],
                      },
                    },
                  ],
                },
              ]} />
              
              </>
        )}
      </Box>
    );
}


export default CompareRadial;