import { Typography, Box,Button, useTheme } from "@mui/material";
import { tokens, nivoTheme, ColorModeContext } from "../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useContext, useEffect } from "react";

// canvas : 레포트 이미지 로컬에 저장하는 함수
function onSaveAs(uri, filename) {
  // console.log('onSaveAs');
  const link = document.createElement('a');
  link.href = uri;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

// canvas : 레포트 이미지 캡쳐하는 함수 -> 곰돌이 아바타 로컬 저장 버튼 클릭
function convertToPng() {
  window.scrollTo(0, 0);

  const date = new Date(); // 현재 시간 이용해 파일명 지정
  const html = document.querySelector(".PdfArea");
  html2canvas(html).then((canvas) => {
    onSaveAs(canvas.toDataURL("image/png"), `${date}_Report.png`);
  });

}






const PngBtn = ({ setReportWidth }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const themeState = theme.palette.mode;

const setThemeToLight = async (themeState, colorMode) => {
  if (themeState === "dark") {
    colorMode.toggleColorMode();
  }
  return true
};
const setWidthToFull = async (set, state) => {
  set(state)
  return true
};

  const handleDownLoadBtn = async (themeState, colorMode, setReportWidth) => {
      const resStyle = await setThemeToLight(themeState, colorMode);
      const fullWidth = await setWidthToFull(setReportWidth, true);
      setTimeout(() => {/*console.log("2초 후에 실행됨");*/convertToPng();}, 1000);
      setTimeout(() => {
        setReportWidth(false);
      }, 1000);

  };
  return (
    <Box m="20px 0">
      <Button
        sx={{
          backgroundColor: colors.blueAccent[700],
          color: colors.grey[100],
          fontSize: "14px",
          fontWeight: "bold",
          padding: "10px 20px",
        }}
        onClick={() => handleDownLoadBtn(themeState, colorMode, setReportWidth)}
      >
        <DownloadOutlinedIcon sx={{ mr: "10px" }} />
        Download Report
      </Button>
    </Box>
  );
};

export default PngBtn;