import Header from "../Header";
import { Typography, Box, Button, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import SignUp from "./SignUp";
import SPPB from "./SPPB";
import Nutrition from "./Nutrition";
import Fitness from "./Fitness"

const CompleteNotice = ({ type }) => {
  //console.log("회원가입")
  const navigation = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleNavigationBtnClick = () => {
    if (type === "signup") {
      navigation("/login");
    } else if (type === "sppb") {
      navigation("/record?type=exercise");
    } else if (type === "nutrition") {
      navigation("/record?type=nutrition");
    } else if (type === "fitness") {
      navigation("/record?type=fitness");
    }
    
  };
  return (
    <Box m="20px">
      {type === "signup" && (
        <SignUp handleBtnClick={handleNavigationBtnClick} />
      )}
      {type === "sppb" && <SPPB handleBtnClick={handleNavigationBtnClick} />}
      {type === "nutrition" && (
        <Nutrition handleBtnClick={handleNavigationBtnClick} />
      )}
      {type === "fitness" && (
        <Fitness handleBtnClick={handleNavigationBtnClick} />
      )}
    </Box>
  );
};

export default CompleteNotice;
