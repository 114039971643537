export const BodyQuestions = [
  {
    element: "신체 조성",
    category: "체지방률",
    method: "허리 둘레(최하단 갈비뼈와 장골능의 중간부위)를 측정한다(cm)",
    count:"(cm)",
    total: 3,
    name: "fat",
  },
  {
    element: "신체 조성",
    category: "근육량",
    method: "종아리 둘레 중 가장 두꺼운 부분을 측정한다(cm)",
    count:"(cm)",
    total: 3,
    name: "muscle",
  },
];

export const MuscleFlexQuestions = [
  {
    element: "상지근기능",
    category: "벽푸쉬업",
    method: "30초 최대개수 측정(회)",
    count: "(회)",
    total: 3,
    name: "supMuscle",
    ache: {
      name: "ache",
      label: "통증",
      value: -1,
    },
  },
  {
    element: "하지근기능",
    category: "스쿼트",
    method: "30초 최대개수 측정(회)",
    count: "(회)",
    total: 3,
    name: "inferMuscle",
    ache: {
      name: "ache",
      label: "통증",
      value: -1,
    },
  },
  {
    element: "상지유연성",
    category: "백스크레치",
    method: "주먹쥐고 사이 거리 측정(회)",
    count: "(cm)",
    total: 3,
    name: "supFlex",
    ache: {
      name: "ache",
      label: "통증",
      value: -1,
    },
  },
  {
    element: "하지유연성",
    category: "싯앤리치",
    method: "의자에 앉은 채로 다리 쭉 펴고 중지손가락과 발끝 직선거리 측정(cm)",
    count: "(cm)",
    total: 3,
    name: "inferFlex",
    ache: {
      name: "ache",
      label: "통증",
      value: -1,
    },
  },
  {
    element: "심폐지구력",
    category: "2분 제자리 걷기",
    method: "2분 제자리 걸을때 무릎 들어올린 횟수 측정(회)",
    count: "(회)",
    total: 3,
    name: "cardio",
    ache: {
      name: "ache",
      label: "통증",
      value: -1,
    },
  },
];

export const Questions = [...BodyQuestions, ...MuscleFlexQuestions];

export const Elements = [
  {
    name: "근육량(종아리 둘레) ",
    type: "muscle",
  },
  {
    name: "체지방률(허리 둘레)",
    type: "fat",
  },

  {
    name: "상지근기능",
    type: "supMuscle",
  },
  {
    name: "하지근기능",
    type: "inferMuscle",
  },
  {
    name: "상지유연성",
    type: "supFlex",
  },
  {
    name: "하지유연성",
    type: "inferFlex",
  },
  {
    name: "심폐지구력",
    type: "cardio",
  },
];


export const ache = {
    value: "통증",
    score: 0,
    total:3
  };


export const getSupMuscleScore = (item,ache, title) =>{

  // 15, 12, 8, 미만
const data = { value: item, total: 3 ,title:title, ache:ache};
  if(item>=15){
    data.score = 3;
  }
  else if(item>=12){
    data.score = 2;
  }
  else if (item>=8){
     data.score = 1;
  }
  else{
    data.score = 0;
  }
return data
}

export const getInferMuscleScore = (item, ache, title) => {
  // 20, 15, 12, 8 ~
  const data = { value: item, total: 3 , title:title, ache:ache};
    if (item >= 20) {
      data.score = 3;
    } 
    else if (item >= 15) {
      data.score = 2;
    } 
    else if (item >= 12) {
     data.score = 1;
    } 
    else {
     data.score = 0;
    }
return data
};

export const getSupFlexScore = (item, ache,title) => {
  //25이하, 30이하, 35이하, 35 ~
  const data = { value: item, total: 3, title: title, ache:ache };
  if (item >= 35) {
    data.score = 0;
  } else if (item >= 30) {
    data.score = 1;
  } else if (item >= 25) {
    data.score = 2;
  } else {
    data.score = 3;
  }
  return data;
};

export const getInferFlexScore = (item,ache,title) => {
  const data = { value: item, total: 3,title,ache:ache };
    if (item >= 15) {
      data.score = 0;
    } else if (item >= 10) {
      data.score = 1;
    } else if (item >= 5) {
     data.score = 2;
    } else {
     data.score = 3;
    }
    return data
};

export const getCardioScore = (item,ache, title) => {
  const data = {value:item, total:3,title,ache}
  if (item>=100){
    data.score=3
  }
  else if (item >= 85) {
     data.score = 2;
  }
  else if (item >= 70) {
     data.score = 1;
  }
  else {
    data.score = 0;
  }

  return data
};

export const getFatScore = (item, sex,title) => {
  
  const data = { value: item, total: 3 , title:title};
  if (sex==="0"){
      if (item >= 90) {
        data.score = 0;
      } else if (item >= 85) {
      data.score = 1;
      } else if (item >= 80) {
       data.score = 2;
      } else {
       data.score = 3;
      }
  }
  else if (sex==="1"){
      if (item >= 85) {
       data.score = 0;
      } else if (item >= 80) {
       data.score = 1;
      } else if (item >= 75) {
        data.score = 2;
      } else {
       data.score = 3;
      }
  }



  return data
};

export const getMuscleScore = (item,title) => {
  const data = { value: item, total: 3, title:title };
    if (item >= 38) {
        data.score = 3;
    } else if (item >= 36) {
        data.score = 2;
    } else if (item >= 34) {
        data.score = 1;
    } else {
        data.score = 0;
    }
    return data
};

export const acheData = (title)=>{
  return {...ache,title}
}
export const checkAche = (item,title)=>{

  if (typeof(item)==="object"){
    if (item[0]==="-1"){ // 통증임
      return true
    }
  }
  else if (typeof(item)==="number"){ // 숫자임
     return false
  }
  else { 
    return false
  }
}

export const getScore = (item,sex) =>{
  

  const {
    supMuscle,supMuscleAche,
    inferMuscle,inferMuscleAche,
    supFlex,supFlexAche,
    inferFlex,inferFlexAche,
    cardio,cardioAche,
    fat,
    muscle,
  } = item;


  const supMuscleData = checkAche(supMuscle)? acheData("상지근기능") : getSupMuscleScore(supMuscle,supMuscleAche,"상지근기능")
  const inferMuscleData = checkAche(inferMuscle)
    ? acheData("하지근기능")
    : getInferMuscleScore(inferMuscle,inferMuscleAche, "하지근기능");
  const supFlexData = checkAche(supFlex)
    ? acheData("상지유연성")
    : getSupFlexScore(supFlex,supFlexAche, "상지유연성");  
  const inferFlexData = checkAche(inferFlex)
    ? acheData("하지유연성")
    : getInferFlexScore(inferFlex, inferFlexAche,"하지유연성"); 
  const cardioData = checkAche(cardio)
    ? acheData("심폐지구력")
    : getCardioScore(cardio,cardioAche, "심폐지구력");
  const fatData = getFatScore(fat, sex, "체지방률(허리 둘레)");
  const muscleData = getMuscleScore(muscle, "근육량(종아리 둘레)");
  

  const data = {
    supMuscle: supMuscleData,
    inferMuscle: inferMuscleData,
    supFlex: supFlexData,
    inferFlex: inferFlexData,
    cardio: cardioData,
    fat: fatData,
    muscle: muscleData,
  };

  //console.log("getScore", data)

  return data

}

export function getOverAllScore(results){
  //console.log(results)
  let score = 0;
  const keys = Object.keys(results)

  for (let i = 0 ; i<keys.length ; i++){
    const k = keys[i]
    //console.log(k,results[k]);
    const v = results[k].score;
    //console.log(v)
    score+=v
  }

  return score
}

const overAll = [
  { value: "양호", step: "정상" },
  { value: "주의", step: "신체기능 저하 위험" },
  { value: "미흡", step: "신체기능 저하" },
];

export function getOverAllColorByValue(colors, value) {
  //console.log(value, overAllData[0].value)

  if (value === overAll[0].value) {
    return colors.greenAccent[300];
  } else if (value === overAll[1].value) {
    return colors.redAccent[700];
  } else if (value === overAll[2].value) {
    return colors.redAccent[500];
  } else {
    return colors.grey[800];
  }
}

export function getDataColorByScore(colors, score){
    if (score === 3) {
      return colors.greenAccent[400];
    } else if (score === 2) {
      return colors.greenAccent[300];
    } else if (score === 1) {
      return colors.redAccent[700];
    } else {
      return colors.redAccent[500];
    }
}


export function getOverAllValue(score){

  let value;
  let step;


  if(score>=15){
    value = overAll[0].value;
    step = overAll[0].step;

  }
  else if (score>=10){
        value = overAll[1].value;
        step = overAll[1].step;
  }
  else{
        value = overAll[2].value;
        step = overAll[2].step;
    
  }

  return {value, step}
}

export const getOverAllData = (item) =>{

  const score = getOverAllScore(item)
  const {value, step}=getOverAllValue(score)
  const data = {score, value, step, total:24}
  return data

}


const CompareResult = ["저하", "유지", "향상"];

export function getCompareResult(current, previous) {
  if (current < previous) {
    return CompareResult[0];
  } else if (current === previous) {
    return CompareResult[1];
  } else if (current > previous) {
    return CompareResult[2];
  }
}

export function getCompareResultColor(colors, result) {
  if (result === CompareResult[0]) {
    return colors.redAccent[500];
  } else if (result === CompareResult[1]) {
    return colors.greenAccent[400];
  } else if (result === CompareResult[2]) {
    return colors.greenAccent[300];
  }
}