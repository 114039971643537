import { useTheme, Box, Typography, useMediaQuery, Stack } from "@mui/material";
import { ResponsiveRadar } from "@nivo/radar";
import { tokens, nivoTheme } from "../../../../theme";
import { useState, useEffect } from "react";

const CompareRadial = ({ current, previous }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:1366px)");
  const [data, setData] = useState([]);

  useEffect(() => {
    if (current && previous) {
      const response = [
        {
          id: current.muscle.title,
          [current.date]: current.muscle.score,
          [previous.date]: previous.muscle.score,
        },
        {
          id: current.fat.title,
          [current.date]: current.fat.score,
          [previous.date]: previous.fat.score,
        },
        {
          id: current.cardio.title,
          [current.date]: current.cardio.score,
          [previous.date]: previous.cardio.score,
        },
        {
          id: current.inferFlex.title,
          [current.date]: current.inferFlex.score,
          [previous.date]: previous.inferFlex.score,
        },
        {
          id: current.inferMuscle.title,
          [current.date]: current.inferMuscle.score,
          [previous.date]: previous.inferMuscle.score,
        },
        {
          id: current.supMuscle.title,
          [current.date]: current.supMuscle.score,
          [previous.date]: previous.supMuscle.score,
        },
      ];

      setData(response);
    } else {
      return;
    }
  }, [current, previous]);
  return (
    <Box height="45vh" width="35vh">
      <Typography
        variant="h3"
        color={colors.greenAccent[400]}
        fontWeight="bold"
        m="18px 0"
      >
        총점 비교
      </Typography>
      <ResponsiveRadar
        data={data}
        keys={[`${current.date}`, `${previous.date}`]}
        theme={nivoTheme(colors)}
        colors={{ scheme: "pastel1" }}
        indexBy="id"
        valueFormat=">-.2f"
        fillOpacity={0.9}
        blendMode="multiply"
        motionConfig="wobbly"
        margin={{ top: 50, right: 80, bottom: 50, left: 80 }}
        borderColor={{ from: "color" }}
        gridLabelOffset={20}
        dotSize={3}
        dotColor={{ theme: "background" }}
        dotBorderWidth={2}
        legends={[
          {
            anchor: "top-left",
            direction: "column",
            translateX: -50,
            translateY: -40,
            itemWidth: 80,
            itemHeight: 20,
            itemTextColor: colors.grey[100],
            symbolSize: 12,
            symbolShape: "circle",
            effects: [
              {
                on: "hover",
                style: {
                  itemTextColor: colors.grey[100],
                },
              },
            ],
          },
        ]}
      />
    </Box>
  );
};

export default CompareRadial;
