import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Box, Button, TextField, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik } from "formik";
import * as yup from "yup";
import moment from "moment";
import Header from "../Header";
import LabeledCheckBox from "./labeledCheckBox";
import GetSenior from "./getSenior";
import { FitnessShowScore } from "./showScore";
import { MuscleFlexQuestions, BodyQuestions } from "../../utils/fitness";

const Fitness = () => {
  const isNonMobile = useMediaQuery("(min-width:1366px)");
  const searchParams = useLocation().search;
  //const query = qs.parse(searchParams);
  const id = new URLSearchParams(searchParams).get("id");
  const name = new URLSearchParams(searchParams).get("name");
  const sex = new URLSearchParams(searchParams).get("sex");
  const [showGrade, setShowGrade] = useState(null);

  const handleFormSubmit = async (values) => {
    //console.log("handleFormSubmit", values);
    const data = {
      name: name,
      id: id,
      ...values,
    };
    setShowGrade((showGrade) => ({ ...data }));
  };

  return (
    <Box m="20px">
      {id && name ? (
        <>
          <Header
            title="체력 요소 평가 진행"
            subtitle={`${name}님 (회원번호 : ${id})의 체력 요소 평가를 진행합니다`}
          />
        </>
      ) : (
        <>
          <Header
            title="체력 요소 평가 진행"
            subtitle="체력 요소 평가를 진행합니다"
          />
          <GetSenior type="fitness" />
        </>
      )}

      {id && name && (
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(3, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 3" },
                }}
              >
                <TextField
                  fullWidth
                  variant="filled"
                  disabled={true}
                  type="text"
                  label="name"
                  defaultValue={name}
                  value={values.name}
                  name="name"
                  sx={{ gridColumn: "span 1" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  disabled={true}
                  type="text"
                  label="id"
                  defaultValue={id}
                  value={values.id}
                  name="id"
                  sx={{ gridColumn: "span 1" }}
                />
                <TextField
                  id="date"
                  type="date"
                  label="등록일"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.date}
                  name="date"
                  error={!!touched.date && !!errors.date}
                  helperText={touched.date && errors.date}
                  sx={{ gridColumn: "span 1" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {MuscleFlexQuestions.map((item, idx) => (
                  <InputCheckBoxQuestion
                    item={item}
                    key={idx}
                    idx={idx}
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                ))}

                {BodyQuestions.map((item, idx) => (
                  <InputQuestion
                    item={item}
                    key={idx}
                    idx={idx}
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                ))}
              </Box>
              <Box display="flex" justifyContent="end" mt="20px">
                <Button type="submit" color="secondary" variant="contained">
                  Preview Test Result
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      )}
      {showGrade && (
        <FitnessShowScore
          item={showGrade}
          sex={sex}
          setShowGrade={setShowGrade}
        />
      )}
    </Box>
  );
};

const InputQuestion = ({
  item,
  idx,
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
}) => {
  const { element, category, method, name,count} = item;
  //console.log(method)
  //console.log(count)
  return (
    <Box sx={{ gridColumn: "span 3" }}>
      <h3>
        {idx + 6}. {element} : {category}
      </h3>
      <Typography margin="8px 0">{method}</Typography>

      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(3, minmax(0, 1fr))"
        sx={{ gridColumn: "span 3" }}
      >
        <TextField
          variant="filled"
          type="number"
          label={count}
          value={values[name]}
          name={name}
          onBlur={handleBlur}
          onChange={handleChange}
          error={!!touched[name] && !!errors[name]}
          helperText={touched[name] && errors[name]}
          sx={{ gridColumn: "span 2" }}
        />
      </Box>
    </Box>
  );
};
const InputCheckBoxQuestion = ({
  item,
  idx,
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
}) => {
  const { element, category, method, name, count, ache } = item;
  const [textInputShow, setTextInputShow] = useState(true);

  return (
    <Box sx={{ gridColumn: "span 3" }}>
      <h3>
        {idx + 1}. {element} : {category}
      </h3>
      <Typography margin="8px">{method}</Typography>

      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(3, minmax(0, 1fr))"
        sx={{ gridColumn: "span 3" }}
      >
        <TextField
          variant="filled"
          type="number"
          label={count}
          value={values[name]}
          name={name}
          onBlur={handleBlur}
          onChange={handleChange}
          error={!!touched[name] && !!errors[name]}
          helperText={touched[name] && errors[name]}
          sx={{ gridColumn: "span 2" }}
        />
        <LabeledCheckBox
          key={idx}
          name={`${name}AcheShow`}
          value={String(0)}
          label="통증 발생 여부"
          type="checkbox"
          setTextInputShow={setTextInputShow}
          sx={{ gridColumn: "span 1" }}
        />
        {!textInputShow && (
          <TextField
            variant="filled"
            type="number"
            label="통증 발생 회차"
            value={values[`${name}Ache`]}
            name={`${name}Ache`}
            onBlur={handleBlur}
            onChange={handleChange}
            error={!!touched[`${name}Ache`] && !!errors[`${name}Ache`]}
            helperText={touched[`${name}Ache`] && errors[`${name}Ache`]}
            sx={{ gridColumn: "span 2" }}
          />
        )}
      </Box>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  date: yup.string().required("입력값을 확인해주세요"),
  supMuscle: yup
    .number()
    .required("입력값을 확인해주세요")
    .min(0, "음수는 유효하지 않습니다"),
  supMuscleAche: yup.number().min(0, "음수는 유효하지 않습니다"),
  inferMuscle: yup
    .number()
    .required("입력값을 확인해주세요")
    .min(0, "음수는 유효하지 않습니다"),
  inferMuscleAche: yup.number().min(0, "음수는 유효하지 않습니다"),
  supFlex: yup
    .number()
    .required("입력값을 확인해주세요")
    .min(0, "음수는 유효하지 않습니다"),
  supFlexAche: yup.number().min(0, "음수는 유효하지 않습니다"),
  inferFlex: yup
    .number()
    .required("입력값을 확인해주세요")
    .min(0, "음수는 유효하지 않습니다"),
  inferFlexAche: yup.number().min(0, "음수는 유효하지 않습니다"),
  cardio: yup
    .number()
    .required("입력값을 확인해주세요")
    .min(0, "음수는 유효하지 않습니다"),
  cardioAche: yup.number().min(0, "음수는 유효하지 않습니다"),
  fat: yup
    .number()
    .required("입력값을 확인해주세요")
    .min(0, "음수는 유효하지 않습니다"),
  muscle: yup
    .number()
    .required("입력값을 확인해주세요")
    .min(0, "음수는 유효하지 않습니다"),
});
const initialValues = {
  date: moment().format("YYYY-MM-DD"),
  supMuscle: "",
  supMuscleAche: 0,
  inferMuscle: "",
  inferMuscleAche: 0,
  supFlex: "",
  supFlexAche: 0,
  inferFlex: "",
  inferFlexAche: 0,
  cardio: "",
  cardioAche: 0,
  fat: "",
  muscle: "",
};

export default Fitness;
