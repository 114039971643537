import { useTheme, Box, Typography } from "@mui/material";
import { tokens } from "../../../../../theme";
import { getOverallColorForScore } from "../../../../../utils";

export const OverAll = ({ record }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);


  console.log(record)
  //console.log(record.length)
  return (
    <Box>
      <Typography
        variant="h3"
        color={colors.greenAccent[400]}
        fontWeight="bold"
        m="18px 0"
      >
        기록 요약
      </Typography>
      <Box
        display="flex"
        width="300px"
        margin="3px 0"
        flexDirection="column"
        justifyContent="space-between"
      >
        {record.map((item, idx) => (
          <>
            {idx === 0 && (
              <Typography
                variant="h4"
                fontWeight="bold"
                color="textPrimary"
                sx={{ margin: "10px 0" }}
              >
                가장 최근 검사 결과
              </Typography>
            )}
            {idx === 1 && (
              <Typography
                variant="h4"
                fontWeight="bold"
                color="textPrimary"
                sx={{ margin: "10px 0" }}
              >
                이전 검사 결과 추이
              </Typography>
            )}
            <Box
              display="flex"
              width="300px"
              margin="3px 0"
              flexDirection="row"
              justifyContent="space-between"
              key={item.date}
            >
              <Typography variant="h4" fontWeight="bold" color="textPrimary">
                {item.date}
              </Typography>
              <Box
                backgroundColor={getOverallColorForScore(
                  colors,
                  item.overAll.value
                )}
                padding="5px 8px"
                borderRadius="10px"
                color="white"
                fontWeight="bold"
              >
                {item.overAll.score}점 / 12점
              </Box>
              <Box
                backgroundColor={getOverallColorForScore(
                  colors,
                  item.overAll.value
                )}
                padding="5px 8px"
                borderRadius="10px"
                color="white"
                fontWeight="bold"
              >
                {item.overAll.value}
              </Box>
            </Box>
            {idx === 0 && (
              <Typography
                variant="body1"
                color="textSecondary"
                sx={{ margin: "5px 0" }}
              >
                {item.overAll.description}
              </Typography>
            )}
          </>
        ))}
      </Box>
    </Box>
  );
};
