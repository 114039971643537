import { useEffect, useState } from "react";
import { Box, useTheme, Button, Stack } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../Header";
import { useNavigate } from "react-router-dom";
import GetSenior from "../Form/getSenior";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useLocation } from "react-router-dom";
import {
  getFbNutritionData,
  deleteFbMultiNutritionById,
} from "../../apis/nutrition";

const Nutrition = () => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [recordList, setRecordList] = useState([]);
  const [selectedRecordList, setSelectedRecordList] = useState([]);
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:1366px)");
  const searchParams = useLocation().search;
  const id = new URLSearchParams(searchParams).get("id");
  const name = new URLSearchParams(searchParams).get("name");


    useEffect(() => {
      async function fetchData() {
        const response = await getFbNutritionData(id);
        //console.log(response)
        setRecordList(response);
      }
      fetchData();
    }, [name, id]);

const columns = [
  {
    field: "id",
    headerName: "ID",
    headerAlign: "center",
    align: "center",
    flex: 0.5,
  },
  {
    field: "name",
    headerName: "Name",
    cellClassName: "name-column--cell",
    flex: 0.5,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "q1",
    headerName: "식사량 변화",
    flex: 0.5,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "q2",
    headerName: "몸무게 변화",
    flex: 0.5,
    type: "number",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "q3",
    headerName: "정신 및 신체 건강",
    flex: 0.5,
    type: "number",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "q4",
    headerName: "거동 능력",
    flex: 0.5,
    type: "number",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "q5",
    headerName: "신경 정신 건강",
    flex: 0.5,
    type: "string",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "bmi",
    headerName: "BMI 지수",
    flex: 0.5,
    type: "string",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "date",
    headerName: "등록일",
    flex: 0.5,
    type: "string",
    headerAlign: "center",
    align: "center",
  },
];

const handleCreateReportBtn=()=>{

      if (!selectedRecordList.length) {
        alert("선택된 기록이 없어 리포트 생성이 불가능 합니다!");
      } else if (selectedRecordList.length === 1) {
        //console.log("create report", selectedRecordList);
        navigate(`/report?type=nutrition&id=${id}&name=${name}`, {
          state: { selectedRecordList },
        });
      } else {
        navigate(`/report?type=nutrition&id=${id}&name=${name}`, {
          state: { selectedRecordList },
        });
      }
}
  function createDeleteWarn(deleteList) {
    let deleteInfo = "";
    deleteList.map((item) => (deleteInfo += `${item}, `));

    if (deleteInfo.length === 0) {
      return "선택한 기록이 존재하지 않습니다.";
    } else if (deleteInfo.length > 0) {
      return `선택한 영양 기록에 ${deleteInfo} 삭제를 진행하겠습니까?`;
    }
  }
async function handleNutritionDeleteBtn  (){
    if (window.confirm(createDeleteWarn(selectedRecordList))) {
      const response = await deleteFbMultiNutritionById(id,selectedRecordList);
      //console.log(response);
      //alert("삭제되었습니다.");
      window.location.replace(`/record?type=nutrition&id=${id}&name=${name}`);
    } else {
      alert("취소합니다.");
    }
}

  return (
    <Box m="20px">
      <Header
        title="영양 평가 보고서"
        subtitle="영양 평가 보고서를 다운로드 받습니다"
      />
      {!id | !name ? <GetSenior type="report-nutrition" /> : <></>}
      {id && name && (
        <>
          <Box
            m="20px 0 0 0"
            height="75vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .css-1ny7bi9-MuiButtonBase-root-MuiButton-root ": {
                color: `${colors.primary[200]} !important`,
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
            }}
          >
            <DataGrid
              checkboxSelection
              loading={!recordList}
              rows={recordList}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
              onSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedRows = recordList
                  .filter((item) => selectedIDs.has(item.id))
                  .map((item) => item.id);
                //setSelectedRow(selectedRows);
                setSelectedRecordList(selectedRows);
                //console.log("data grid", selectedRows.length);
              }}
            />
          </Box>
          <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={1}
            mt="10px"
          >
            <Button
              color="secondary"
              variant="contained"
              onClick={handleNutritionDeleteBtn}
            >
              검사 기록 삭제
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={handleCreateReportBtn}
            >
              보고서 생성
            </Button>
          </Stack>
        </>
      )}
    </Box>
  );
};

export default Nutrition;
