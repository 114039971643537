import { Box, Button, useTheme, useMediaQuery } from "@mui/material";
import { tokens } from "../../../theme";
import { getScore } from "../../../utils";
import StatBox from "../../StateCard";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import ElderlyIcon from "@mui/icons-material/Elderly";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { getOverallScore } from "../../../utils/grade";
import { AddRecordById } from "../../../apis/sppb";
import { useNavigate } from "react-router-dom";

const ShowScore = ({ item, setShowGrade }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:1366px)");
  const navigate = useNavigate();

  const handleSubmitBtn = async (e, balance, musculus, speed, overAll) => {
    //console.log("handleSubmitBtn");
    //console.log(item);
    const data = {
      record: {
        side_by_side: item.side_by_side,
        semi_tandem: item.semi_tandem,
        tendem: item.tandem,
        musculus: item.musculus,
        gait_speed: item.gait_speed,
      },
      senior: {
        id: item.id,
        name: item.name,
      },
      balance: {
        score: balance.score,
        value: balance.value,
      },
      musculus: {
        score: musculus.score,
        value: musculus.value,
      },
      speed: {
        score: speed.score,
        value: speed.value,
      },
      overAll: {
        score: overAll.score,
        value: overAll.value,
        description: overAll.description
      },
      date: item.date,
      /*balanceScore: balance.score,
      balanceValue: balance.value,
      musculusScore: musculus.score,
      musculusValue: musculus.value,
      speedScore: speed.score,
      speedValue: speed.value,
      overAllScore: overAll.score,
      overAllValue: overAll.value,*/
    };
    //console.log("data", data);

    const response = await AddRecordById(item.id,data);
    setShowGrade(null);
    //navigate("/register?type=exercise");
    navigate("/complete?type=sppb");

  };

  //console.log(item)
  //console.log(getScore(item));
  const { balance, musculus, speed } = getScore(item);
  //console.log(balance,musculus, speed);
  const overAll = getOverallScore({ balance, musculus, speed });
  return (
    <>
      <Box
        display="grid"
        mt="30px"
        gap="30px"
        gridTemplateColumns="repeat(6, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 3" },
        }}
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 1"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={balance.value}
            subtitle="균형 측정"
            progress={balance.score / 4}
            content={balance.score}
            icon={
              <AccessibilityIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 1"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={speed.value}
            subtitle="보행 속도"
            progress={speed.score / 4}
            content={speed.score}
            /*increase="+21%"*/
            icon={
              <DirectionsWalkIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 1"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={musculus.value}
            subtitle={`일어서기`}
            progress={musculus.score / 4}
            content={musculus.score}
            icon={
              <ElderlyIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span "
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={overAll.value}
            subtitle="종합 점수"
            progress={overAll.score / 12}
            content={`${overAll.score}`}
            icon={
              <ContentPasteIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
      </Box>
      <Box display="flex" justifyContent="end" m="20px 0">
        <Button
          color="secondary"
          variant="contained"
          onClick={(e) => handleSubmitBtn(e, balance, musculus, speed, overAll)}
        >
          Submit Test Result
        </Button>
      </Box>
    </>
  );
};

export default ShowScore;
