import {
  collection,
  addDoc,
  doc,
  getDoc,
  deleteDoc,
  query,
  orderBy,
  getDocs,
} from "firebase/firestore";
import { db } from "../firebase/firebase";


const baseUrl = "fitness";

// 특정 시니어 Id에 영양 정보 추가
export const AddFitnessById = async (seniorId, data) => {

  //console.log(data)
  try {
    // 영양 기록 기록 구현
    const response = await addDoc(
      collection(db, `/senior/${seniorId}/${baseUrl}`),
      data
    );
    //console.log("handleFormSubmit finish", response);
    return response;
  } catch (e) {
      //console.log(data);
    console.log(e);
  }
};



export const getFbFitnessData = async (seniorId) =>{

  const q = query(
    collection(db, `/senior/${seniorId}/${baseUrl}`),
    orderBy("date", "asc")
  );

  try {
    const fbRecordList = [];
    await getDocs(q).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        //console.log(doc.data())
        const {
          senior,
          date,
          muscle,
          cardio,
          fat,
          inferFlex,
          inferMuscle,
          supFlex,
          supMuscle,
          /*overAll,*/
        } = doc.data();
        fbRecordList.push({
          id: doc.id,
          name: senior.name,
          muscle: muscle.value,
          cardio: cardio.value,
          fat: fat.value,
          inferFlex: inferFlex.value,
          inferMuscle: inferMuscle.value,
          supFlex: supFlex.value,
          supMuscle: supMuscle.value,
          /*overAll: overAll.value,*/
          
          date: date,
          seniorId: senior.id,
        });
      });
    });
    console.log("fbSeniorNameList", fbRecordList);
    return fbRecordList;

    //console.log(fbSeniorNameList);
  } catch (e) {
    console.log(e);
  }
}


  // 특정 시니어 Id와 특정 Nutrition 문서 Id에 해당하는 데이터 조회
  export const getFbFitnessById = async (seniorId, recordId) => {
    const docRef = doc(db, `/senior/${seniorId}/${baseUrl}`, recordId);
    try {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        //console.log("getFbFitnessById", docSnap.data());
        return docSnap.data();
      } else {
        console.log("document do not exist");
      }
    } catch (e) {
      console.log(e);
    }
  };


  export const getFbMultiFitnessById = async (seniorId, recordIds)=>{
    const fbFitnessDataList = await Promise.all(
      recordIds.map(async (recordId) => {
        const data = await getFbFitnessById(seniorId, recordId);
        return data;
      })
    );
    //console.log(fbFitnessDataList);
    return fbFitnessDataList;
  }


  //특정 fitness Id로 영양 정보 삭제
export const deleteFbFitnessById = async (seniorId, recordId) => {
  const docFitnessRef = doc(db, `/senior/${seniorId}/${baseUrl}`, recordId);
  //console.log("delete", id, docRef);
  try {
    const docSnap = await deleteDoc(docFitnessRef);
    //console.log(docSnap); // res는 undefined
  } catch (e) {
    console.log(e);
  } finally {
    //console.log("end");
  }
};


  // 다수의 fitness Id에 해당하는 모든 fitness 정보 삭제
export const deleteFbMultiFitnessById = async (seniorId,fitnessIds) =>{
  try{
    const fbRecordDataList = await Promise.all(
      fitnessIds.map(async (recordId) => {
        const data = await deleteFbFitnessById(seniorId, recordId);
        //console.log(data)
        return data;
      })
    );
    return fbRecordDataList;
    //console.log(fbRecordDataList);
  }
  catch(e){
    console.log(e)
}
}