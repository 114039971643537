import {
  SPBBSingleReport,
  SPBBMultiReport,
} from "../../components/Report/SPBB";
import {
  NutritionSingleReport,
  NutritionMultiReport,
} from "../../components/Report/Nutrition";
import {
  FitnessSingleReport,
  FitnessMultiReport,
} from "../../components/Report/Fitness";
import { useLocation } from "react-router-dom";

const Report  = ()=>{
  const searchParams = useLocation().search;
  const id = new URLSearchParams(searchParams).get("id");
  const name = new URLSearchParams(searchParams).get("name");
  const type = new URLSearchParams(searchParams).get("type");
  const { selectedRecordList } = useLocation().state;
  //console.log(selectedRecordList);

  return (
    <>
      {type === "exercise" && selectedRecordList.length === 1 && (
        <SPBBSingleReport id={id} name={name} recordId={selectedRecordList} />
      )}

      {type === "exercise" && selectedRecordList.length > 1 && (
        <SPBBMultiReport id={id} name={name} recordId={selectedRecordList} />
      )}

      {type === "nutrition" && selectedRecordList.length === 1 && (
        <NutritionSingleReport
          id={id}
          name={name}
          recordId={selectedRecordList}
        />
      )}
      {type === "nutrition" && selectedRecordList.length > 1 && (
        <NutritionMultiReport
          id={id}
          name={name}
          recordId={selectedRecordList}
        />
      )}
      {type === "fitness" && selectedRecordList.length === 1 && (
        <FitnessSingleReport
          id={id}
          name={name}
          recordId={selectedRecordList}
        />
      )}
      {type === "fitness" && selectedRecordList.length > 1 && (
        <FitnessMultiReport
          id={id}
          name={name}
          recordId={selectedRecordList}
        />
      )}
    </>
  );
}

export default Report;