import LocalDiningIcon from "@mui/icons-material/LocalDining";
import ScaleIcon from "@mui/icons-material/Scale";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import { tokens } from "../../../../theme";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
} from "@mui/lab";
import { Typography, useTheme, Stack, Box, useMediaQuery } from "@mui/material";
import {getQ3LabelMean} from "../../../../utils/nutrition"; 


export function Question1To3({record}) {
  //console.log(record)

  const {q1Data, q2Data, q3Data} = record
  return (
    <Box

      sx={{
        /*width: "40%",*/
      }}
    >
      <Timeline position="alternate">
        {/* Q1 */}
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: "auto 0" }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            {q1Data.score}점 / {q1Data.total}점
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot>
              <LocalDiningIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography variant="h6" component="span">
              {q1Data.sub}
            </Typography>
            <Typography> {q1Data.value}</Typography>
          </TimelineContent>
        </TimelineItem>
        {/* Q2 */}
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: "auto 0" }}
            variant="body2"
            color="text.secondary"
          >
            {q2Data.score}점 / {q2Data.total}점
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="primary">
              <ScaleIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography variant="h6" component="span">
              {q2Data.sub}
            </Typography>
            <Typography>{q2Data.value}</Typography>
          </TimelineContent>
        </TimelineItem>
        {/* Q3 */}
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: "auto 0" }}
            variant="body2"
            color="text.secondary"
          >
            {q3Data.score}점 / {q3Data.total}점
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="primary" variant="outlined">
              <FitnessCenterIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography variant="h6" component="span">
              {q3Data.sub}
            </Typography>
            <Typography> {getQ3LabelMean(q3Data.value)}</Typography>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </Box>
  );
}


export function Question4To5({record}){
  const {q4Data, q5Data} = record
  return (
    <Box

      sx={{
       /* width: "40%",*/
        
      }}
    >
      <Timeline position="alternate">
        {/* Q4 */}
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: "auto 0" }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            {q4Data.score}점 / {q4Data.total}점
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot>
              <DirectionsRunIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography variant="h6" component="span">
              {q4Data.sub}
            </Typography>
            <Typography> {q4Data.value}</Typography>
          </TimelineContent>
        </TimelineItem>
        {/* Q5 */}
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: "auto 0" }}
            variant="body2"
            color="text.secondary"
          >
            {q5Data.score}점 / {q5Data.total}점
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="primary">
              <PsychologyAltIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography variant="h6" component="span">
              {q5Data.sub}
            </Typography>
            <Typography>{q5Data.value}</Typography>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </Box>
  );
}


export function Question ({record,fullWidth}){
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width:1366px)");
  const isNonTablet = useMediaQuery("(max-width:500px)");
  const colors = tokens(theme.palette.mode);
  return (
    record && 
        <>
      <Typography

        sx={{ gridColumn: "span 4" }}
        variant="h3"
        color={colors.greenAccent[400]}
        fontWeight="bold"
        mb="15px"
      >
        영양평가
      </Typography>
      <Stack direction={fullWidth?"row":isNonMobile?"row":isNonTablet?"column":"column"} spacing={3} justifyContent="flex-start"> 
        <Question1To3
          record={{
            q1Data: record.q1Data,
            q2Data: record.q2Data,
            q3Data: record.q3Data,
          }}
        />
        <Question4To5
          record={{ q4Data: record.q4Data, q5Data: record.q5Data }}
        />
      </Stack>
    </>
  )



}
