import { useEffect, useState } from "react";
import { Box, Typography, useTheme, Button, Stack} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import { getAllSenior, deleteFbMultiSeniorById,getFbSeniorDataByCurrentTrainer } from "../../apis/senior";
import Header from "../../components/Header";
import { useAppSelector } from "../../redux/hooks";
import {checkAbleToHandle,checkAdmin} from "../../utils"


const Senior = () => {
  //const user = "김블라";
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [senior, setSenior] = useState([]);
  const navigate = useNavigate();
  const userInfo = useAppSelector((state) => state.user.userInfo.value);
  const userId = useAppSelector((state) => state.user.userId);
  const [selectedRowsList, setSelectedRowsList]=useState([])


  const handleRegisterButton = (e, currentCoachId, initialCoachId, id, name,type,sex) => {
    //console.log("handleRegisterButton");
    if (checkAbleToHandle(userId, currentCoachId, initialCoachId)) {
      //console.log("운동 등록하기");
      if (type === "fitness" && typeof sex != "undefined") {
        navigate(`/register?type=${type}&id=${id}&name=${name}&sex=${sex}`);
      } else {
        navigate(`/register?type=${type}&id=${id}&name=${name}`);
      }

      //console.log("페이지 이동");
    } else {
      console.log("운동 등록 불가능");
    }
  };


  function createDeleteWarn(selectedRowsList){

    let deleteInfo = ""
    selectedRowsList.map((item) => (deleteInfo += `${item[1]}(${item[0]}) `));

    if (deleteInfo.length===0){
      return "선택한 시니어에 대한 삭제 권한이 존재하지 않습니다."
    }
    else if (deleteInfo.length>0){
      return `선택한 시니어 중 삭제 권한이 존재하는 ${deleteInfo}에 대해 삭제를 진행하겠습니까?`
    }
  }

  function createDeleteIdList(selectedRowsList) {
    const idList=selectedRowsList.map((item) =>item[0]);
    return idList;
  }



  async function handleSeniorDeleteBtn() {

    if (window.confirm(createDeleteWarn(selectedRowsList))) {
      const response =await deleteFbMultiSeniorById(createDeleteIdList(selectedRowsList));
      if (selectedRowsList.length>0){
        window.location.replace("/record?type=senior");
      }
     
    } else {
      alert("취소합니다.");
    }
  }

    function createEditWarn(selectedRowsList) {
      let deleteInfo = "";
      selectedRowsList.map((item) => (deleteInfo += `${item[1]}(${item[0]}) `));
      //console.log(selectedRowsList.length);

      if (selectedRowsList.length === 0) {
        return "선택한 시니어에 대한 수정 권한이 존재하지 않습니다.";
      } else if (selectedRowsList.length === 1) {
        return `선택한 시니어 중 수정 권한이 존재하는 ${deleteInfo}에 대해 수정을 진행하겠습니까?`;
      } else {
        return `수정 권한이 존재하는 ${deleteInfo} 중 1명의 시니어만 선택해 수정을 진행하시오.`;
      }
    }

  const  handleSeniorEditBtn=async ()=>{
      //console.log(selectedRowsList)
      if (window.confirm(createEditWarn(selectedRowsList))) {
      //const response =await deleteFbMultiSeniorById(createDeleteIdList(selectedRowsList));
      if (selectedRowsList.length=== 1) {
        //console.log(selectedRowsList);
        window.location.replace(`/profile?type=senior&id=${selectedRowsList[0][0]}&name=${selectedRowsList[0][1]}`);
      }
    } else {
      alert("취소합니다.");
    }

  }
  



  useEffect(() => {
    async function fetchData(uid){
      const response = checkAdmin(uid)
        ? await getAllSenior()
        : await getFbSeniorDataByCurrentTrainer(uid);
      setSenior(response);
    }
    console.log(userId)
    fetchData(userId);
  }, []);

  const columns = [
    /*{
      field: "id",
      headerName: "ID",
      headerAlign: "center",
      align: "center",
      flex: 0.2,
    },*/
    {
      field: "name",
      headerName: "이름",
      cellClassName: "name-column--cell",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "sex",
      headerName: "성별",
      cellClassName: "name-column--cell",
      /*flex: 1,*/
      headerAlign: "center",
      align: "center",
      renderCell: ({ row: { sex } }) => {
        return <Typography>{sex === 0 ? "남성" : "여성"}</Typography>;
      },
    },
    {
      field: "phoneNumber",
      headerName: "연락처",
      width: 200,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "age",
      headerName: "연령",
      type: "number",
      width: 180,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "weight",
      headerName: "체중",
      width: 180,
      type: "number",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "height",
      headerName: "키",
      width: 180,
      type: "number",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "initialCoach",
      headerName: "등록자",
      width: 200,
      type: "string",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "initialCoachId",
      headerName: "등록자 Id",
      width: 200,
      type: "string",
      headerAlign: "center",
      align: "center",
      hide:true
    },
    {
      field: "currentCoach",
      headerName: "트레이너",
      width: 200,
      type: "string",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "currentCoachId",
      headerName: "트레이너 Id",
      width: 200,
      type: "string",
      headerAlign: "center",
      align: "center",
      hide:true
    },
    {
      field: "createdAt",
      headerName: "등록일",
      width: 200,
      type: "string",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "spbb",
      headerName: "SPPB 기록",
      /*flex: 0.5,*/
      headerAlign: "center",
      align: "center",
      renderCell: ({ row: { currentCoachId, initialCoachId, id, name } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              checkAbleToHandle(userId, currentCoachId, initialCoachId)
                ? colors.blueAccent[600]
                : colors.redAccent[700]
            }
            borderRadius="4px"
            onClick={(e) => {
              handleRegisterButton(
                e,
                currentCoachId,
                initialCoachId,
                id,
                name,
                "exercise"
              );
            }}
          >
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {checkAbleToHandle(userId, currentCoachId, initialCoachId)
                ? "등록"
                : "불가"}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "nutrition",
      headerName: "영양 기록",
      /*flex: 0.5,*/
      headerAlign: "center",
      align: "center",
      renderCell: ({ row: { currentCoachId, initialCoachId, id, name } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              checkAbleToHandle(userId, currentCoachId, initialCoachId)
                ? colors.blueAccent[600]
                : colors.redAccent[700]
            }
            borderRadius="4px"
            onClick={(e) => {
              handleRegisterButton(
                e,
                currentCoachId,
                initialCoachId,
                id,
                name,
                "nutrition"
              );
            }}
          >
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {checkAbleToHandle(userId, currentCoachId, initialCoachId)
                ? "등록"
                : "불가"}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "fitness",
      headerName: "건강체력 기록",
      /*flex: 0.5,*/
      headerAlign: "center",
      align: "center",
      renderCell: ({
        row: { currentCoachId, initialCoachId, id, name, sex },
      }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              checkAbleToHandle(userId, currentCoachId, initialCoachId)
                ? colors.blueAccent[600]
                : colors.redAccent[700]
            }
            borderRadius="4px"
            onClick={(e) => {
              handleRegisterButton(
                e,
                currentCoachId,
                initialCoachId,
                id,
                name,
                "fitness",
                sex
              );
            }}
          >
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {checkAbleToHandle(userId, currentCoachId, initialCoachId)
                ? "등록"
                : "불가"}
            </Typography>
          </Box>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      <Header title="회원검색" subtitle="회원을 검색합니다" />
      <Box
        m="20px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .css-1ny7bi9-MuiButtonBase-root-MuiButton-root ": {
            color: `${colors.primary[200]} !important`,
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .css-1mmlah6-MuiTypography-root": {
            marginLeft: 0,
          },
        }}
      >
        <DataGrid
          checkboxSelection
          loading={!senior}
          rows={senior}
          columns={columns}

          components={{ Toolbar: GridToolbar }}
          onSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            const selectedRows = senior
              .filter((item) => selectedIDs.has(item.id))
              .filter((item) =>
                checkAbleToHandle(
                  userId,
                  item.currentCoachId,
                  item.initialCoachId
                )
              )
              .map((item) => [item.id, item.name]);
            //setSelectedRow(selectedRows);
            //console.log(selectedRows);
            setSelectedRowsList(selectedRows);
          }}
          sx={{
            "& .MuiDataGrid-toolbarContainer": {
              "& .MuiButton-text": {
                color: colors.grey[100],
              },
            },
          }}
        />
      </Box>
      <Stack direction="row" justifyContent="flex-end" spacing={1} mt="10px">
        <Button
          color="secondary"
          variant="contained"
          onClick={handleSeniorEditBtn}
          ml="2px"
        >
          Edit Senior
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={handleSeniorDeleteBtn}
        >
          Delete Senior
        </Button>
      </Stack>
    </Box>
  );
};

export default Senior;
