import {
  Typography,
  Stack,
  Box,
  Button,
  IconButton,
  useTheme,
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { withFirebaseApi, /*FirebaseApi as firebaseApi*/ } from "../../firebase";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { asyncUpdateUserInfo } from "../../redux/userSlice";
import OnBoarding from "./onBoarding";
import SeniorProfile from "./senior";
import {  useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { tokens } from "../../theme";


const EditProfilePicViewModeBase = (props) => {
  const [profilePicUrl, setProfilePicUrl] = useState(null);
  const { userInfo, firebaseApi, onEditClick } = props;
  //console.log(userInfo)
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigation = useNavigate();
  useEffect(() => {
    if (userInfo.profilePicHandle == null) {
      return;
    }
    firebaseApi.asyncGetURLFromHandle(userInfo.profilePicHandle).then((url) => {
      setProfilePicUrl(url);
    });
  }, [userInfo.profilePicHandle]);
  let profilePic = null;
  if (profilePicUrl) {
    profilePic = (
      <img
        src={profilePicUrl}
        width={200}
        style={{ borderRadius: "50%" }}
        alt="user image"
      />
    );
  }

  return (
    <Stack direction="column" spacing={2}>
      <Typography
        variant="h4"
        color={colors.primary[100]}
        fontWeight="bold"
        m="18px 0"
      >
       프로필 사진:
      </Typography>

      {/*<Typography
        variant="body1"
        align="left"
        sx={{ marginTop: "auto", marginBottom: "auto" }}
      >
        Profile Pic:
  </Typography>*/}
      <Stack direction="row" spacing={2}>
        {profilePic}

        <IconButton onClick={() => onEditClick()}>
          <EditIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};

const EditProfilePicViewMode = withFirebaseApi(EditProfilePicViewModeBase);

const EditProfilePicEditModeBase = (props) => {
  const [file, setFile] = useState(null);
  const [profilePicUrl, setProfilePicUrl] = useState(null);
  const dispatch = useAppDispatch();
  const navigation = useNavigate();
  const { userId, userInfo, onSubmitClick, firebaseApi } = props;

  useEffect(() => {
    if (userInfo.profilePicHandle == null) {
      return;
    }
    firebaseApi.asyncGetURLFromHandle(userInfo.profilePicHandle).then((url) => {
      setProfilePicUrl(url);
    });
  }, [userInfo.profilePicHandle]);
  let profilePic = null;
  if (profilePicUrl) {
    profilePic = (
      <img
        src={profilePicUrl}
        width={200}
        style={{ borderRadius: "10px" }}
        alt="user Profile"
      />
    );
  }
  if (file !== null) {
    profilePic = <img src={URL.createObjectURL(file)} width={200} alt = "user Profile"/>;
  }

  return (
    <Stack direction="row" spacing={2}>
      <Typography variant="body1" align="left" sx={{ marginTop: "auto", marginBottom: "auto" }}>
        Profile Pic:
      </Typography>
      {profilePic}
      <Button variant="contained" component="label">
        Select Image
        <input hidden accept="image/*" onChange={(e) => {
          const files = e.target.files;
          if (files == null || files.length === 0) {
            setFile(null);
          } else {
            setFile(files[0]);
          }
        }} type="file" />
      </Button>
      <Button
        variant="contained"
        sx={{ marginTop: 2 }}
        onClick={async () => {
          const handle = await firebaseApi.asyncUploadImage(userId, file);
          dispatch(asyncUpdateUserInfo({
            firebaseApi: firebaseApi,
            userId: userId,
            userInfo: { profilePicHandle: handle },
          }))
          navigation("/profile");
        }}
      >SUBMIT</Button>
    </Stack>
  )
};

const EditProfilePicEditMode = withFirebaseApi(EditProfilePicEditModeBase);

const EditProfilePicBase = (props) => {
  const [isEditMode, setIsEditMode] = useState(false);
   const { userId, userInfo } = props;


  return isEditMode ? <EditProfilePicEditMode
    userId={userId}
    userInfo={userInfo}
    onSubmitClick={() => { setIsEditMode(false) }}
  /> : <EditProfilePicViewMode
    userInfo={userInfo}
    onEditClick={() => { setIsEditMode(true) }}
  />;
}

const EditProfilePic = withFirebaseApi(EditProfilePicBase);

const ShowUsername = ({ userInfo }) => {
  //console.log(userInfo)
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Stack direction="row" spacing={2}>
      <Typography
        variant="h4"
        color={colors.primary[100]}
        fontWeight="bold"
        m="18px 0"
      >
        Username: {userInfo.username}
      </Typography>
    </Stack>
  );
};




const EditProfileBase = (props) => {
  const userId = useAppSelector((state) => state.user.userId);
  const userInfo = useAppSelector((state) => state.user.userInfo.value);
  //console.log(userId,userInfo)
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const searchParams = useLocation().search;
  const id = new URLSearchParams(searchParams).get("id");
  const name = new URLSearchParams(searchParams).get("name");
  const type = new URLSearchParams(searchParams).get("type");
  //console.log(props.firebaseApi.asyncGetURLFromHandle);

  return (
    <>
      <Box m="20px">
        {userId && type === "senior" ? (
          <>
            <Header title="Senior Profile" subtitle="Set My Senior Info" />
            <SeniorProfile getProfileImgUrl={props.firebaseApi.asyncGetURLFromHandle} />
          </>
        ) : !userInfo ? (
          <>
            <Header title="My Profile" subtitle="Set My Profile Image" />
            <OnBoarding />
          </>
        ) : (
          <>
            <ShowUsername userId={userId} userInfo={userInfo} />
            <EditProfilePic userId={userId} userInfo={userInfo} />
          </>
        )}
      </Box>
    </>
  );
}

export default withFirebaseApi(EditProfileBase);