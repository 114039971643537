import {  useAppSelector } from './redux/hooks';
import { Routes, Route } from "react-router-dom";
import Register from "./scenes/register";
import Invoices from "./scenes/invoices";
import Form from "./scenes/register";
import FAQ from "./scenes/faq";
import Login from "./scenes/login";
import { EditProfile, OnboardingProfile } from "./scenes/profile";
import Report from "./scenes/report";
import Signup from "./scenes/signup";
import Complete from './scenes/complete';
import {
  CircularProgress,
} from "@mui/material";
import Calendar from "./scenes/calendar/calendar";
import Record from "./scenes/record";
import PrivateRoute from './route/PrivateRoute';

const AppNav = (props) => {
  //const userId = useAppSelector((state) => state.user.userId);
  const userInfo = useAppSelector(
    (state) => state.user.userInfo.value
  );
  const userInfoLoadState = useAppSelector(
    (state) => state.user.userInfo.loadState
  );
    console.log(userInfo)

  if (userInfoLoadState === "loading") {
    return <CircularProgress />;
  }

  if (userInfo === null ) {
    return <OnboardingProfile />;
  }

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route element={<PrivateRoute authentication={true} />}>
        <Route path="/" element={<Record />} />
        <Route path="/profile" element={<EditProfile />} />
        <Route path="/onboarding" element={<OnboardingProfile />} />
        <Route path="/register" element={<Register />} />
        <Route path="/record" element={<Record />} />
        <Route path="/report" element={<Report />} />
        <Route path="/complete" element={<Complete />} />
      </Route>

      <Route path="/invoices" element={<Invoices />} />
      <Route path="/form" element={<Form />} />

      <Route path="/faq" element={<FAQ />} />
      <Route path="/calendar" element={<Calendar />} />
    </Routes>
  );
}

export default AppNav;