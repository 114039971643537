import { useTheme, Box, Typography, Stack } from "@mui/material";
import { tokens } from "../../../../../theme";
import { getMusculusAndSpeedColorForScore } from "../../../../../utils";

export const Speed = ({record}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box>
      <Typography
        variant="h3"
        color={colors.greenAccent[400]}
        fontWeight="bold"
        m="18px 0"
      >
        보행 속도 기록 요약
      </Typography>
      <Box
        display="flex"
        width="100%"
        margin="3px 0"
        flexDirection="column"
        justifyContent="space-between"
      >
        {record.map((item) => (
          <Box
            display="flex"
            margin="5px 0"
            flexDirection="row"
            justifyContent="space-between"
            key={item.date}
          >
            <Typography variant="h4" fontWeight="bold" color="textPrimary">
              {item.date}
            </Typography>

            <Stack direction="row" spacing={3} alignItems="center" width="65%">
              <Box
                backgroundColor={getMusculusAndSpeedColorForScore(
                  colors,
                  item.speed.value
                )}
                padding="5px 10px"
                borderRadius="10px"
                color="white"
                fontWeight="bold"
              >
                {" "}
                {item.speed.value}
              </Box>
              <Box
                backgroundColor={getMusculusAndSpeedColorForScore(
                  colors,
                  item.speed.value
                )}
                padding="5px 10px"
                borderRadius="10px"
                color="white"
                fontWeight="bold"
              >
                {" "}
                {item.speed.score}점 / 4점
              </Box>
              <Typography variant="body2" fontWeight="bold" color="textPrimary">
                {item.record.gait_speed}초
              </Typography>
            </Stack>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
