import { Typography, Box, useTheme, Avatar } from "@mui/material";
import { tokens } from "../../../../theme";

const OverAllStep=({value})=>{
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box display="flex"  margin="8px 0">
      <Avatar
        sx={{
          bgcolor:
            value === "미흡"
              ? `${colors.redAccent[500]}`
              : `${colors.grey[800]}`,
          width: "50px",
          height: "50px",
          marginRight: "8px",
        }}
      >
        미흡
      </Avatar>
      <Avatar
        sx={{
          bgcolor:
            value === "주의4"
              ? `${colors.redAccent[500]}`
              : `${colors.grey[800]}`,
          width: "50px",
          height: "50px",
          marginRight: "8px",
        }}
      >
        주의4
      </Avatar>
      <Avatar
        sx={{
          bgcolor:
            value === "주의3"
              ? `${colors.redAccent[300]}`
              : `${colors.grey[800]}`,
          width: "50px",
          height: "50px",
          marginRight: "8px",
        }}
      >
        주의3
      </Avatar>
      <Avatar
        sx={{
          bgcolor:
            value === "주의2"
              ? `${colors.redAccent[500]}`
              : `${colors.grey[800]}`,
          width: "50px",
          height: "50px",
          marginRight: "8px",
        }}
      >
        주의2
      </Avatar>
      <Avatar
        sx={{
          bgcolor:
            value === "주의1"
              ? `${colors.redAccent[500]}`
              : `${colors.grey[800]}`,
          width: "50px",
          height: "50px",
          marginRight: "8px",
        }}
      >
        주의1
      </Avatar>
      <Avatar
        sx={{
          bgcolor:
            value === "양호"
              ? `${colors.greenAccent[500]}`
              : `${colors.grey[800]}`,
          width: "50px",
          height: "50px",
          marginRight: "8px",
        }}
      >
        양호
      </Avatar>
    </Box>
  );

}

const OverAllScore = ({score})=>{
  return (
    <Box display="flex" alignItems="baseline" margin="3px 0">
      <Typography variant="h3" fontWeight="bold" color="textPrimary">
        {score}점
      </Typography>
      <Typography variant="h6" color="textSecondary">
        /12점
      </Typography>
    </Box>
  );
}

const OverAllValueInfo = ({ name, value, description }) => {
  return (
    <>
      <Box display="flex" alignItems="baseline" margin="3px 0">
        <Typography
          variant="h3"
          fontWeight="bold"
          color="textPrimary"
          margin="0 3px"
        >
          {value}
        </Typography>
        <Typography variant="h6" color="textSecondary">
          단계 입니다.
        </Typography>
      </Box>
      <Typography variant="body1" color="textSecondary">
        {description}
      </Typography>
    </>
  );
};

const OverAll = ({user, data})=>{
  //console.log(data)
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (data &&
    <>
      <Typography
        variant="h3"
        color={colors.greenAccent[400]}
        fontWeight="bold"
        mb="15px"
      >
        전체 분석
      </Typography>
      <Box height="60%" display="flex" flexDirection="column" justifyContent="space-between">
        <OverAllScore score={data.score} />
        <OverAllStep value={data.value} />
        <OverAllValueInfo value={data.value} name={user.name} description={data.description}/>
      </Box>
    </>
  );
}

export default OverAll;