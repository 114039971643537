import { Component } from "react"
import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";
import { CircularProgress } from "@mui/material";
import { EditProfile, OnboardingProfile } from "../scenes/profile";

const PrivateRoute = ({ authentication }) => {
  const userId = useAppSelector((state) => state.user.userId);
  const userInfo = useAppSelector((state) => state.user.userInfo.value);
  const userInfoLoadState = useAppSelector(
    (state) => state.user.userInfo.loadState
  );
  if (userInfoLoadState === "loading") {
    return <CircularProgress />;
  }

  if (authentication){ //인증이 필요한 페이지
    //console.log(userId)

    return (!userId? <Navigate to="/login"/>:userInfo===null?<Navigate to="/onboarding" />:<Outlet/>)

  }else{ //인증이 필요하지 않은 페이지
    return <Outlet/>
  }
  /*if (userInfoLoadState === "failed" || userInfo === undefined) {
    return (<>
      <Typography>Something failed</Typography>
    </>);
  }*/
  /*  if (userInfo === null) {
      return <OnboardingProfile />;
    }*/

};

export default PrivateRoute;