import {  useState } from "react";
import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import moment from "moment";
import { useLocation } from "react-router-dom";
import GetSenior from "./getSenior";
import {SPBBShowScore} from "./showScore";
import Header from "../Header";
import { side_by_side_State, semi_tandem_State } from "../../utils";

/**
 * 
"side_by_side": 0, - 일반 자세
"semi_tandem": 10, - 반일렬 자세
"tandem": 100, - 일렬 자세 
"gait_speed": 10, - 보행속도 검사
"musculus": 10 - 하지근력 검사
 */

const Record = () => {
  const isNonMobile = useMediaQuery("(min-width:1366px)");
  const searchParams = useLocation().search;
  //const query = qs.parse(searchParams);
  const id = new URLSearchParams(searchParams).get("id");
  const name = new URLSearchParams(searchParams).get("name");
  const [showGrade, setShowGrade] = useState(null);

  const handleFormSubmit = async (values) => {
    const data = {
      name: name,
      id: id,
      ...values,
      /*side_by_side: values.side_by_side,
      semi_tandem: values.semi_tandem,
      tandem: values.tandem,
      gait_speed: values.gait_speed,
      musculus: values.musculus,
      createdAt: moment().format("YYYY-MM-DD"),*/
    };
    setShowGrade((showGrade) => ({ ...data }));
  };

  return (
    <Box m="20px">
      {id && name ? (
        <>
          <Header
            title="노쇠 검사 진행"
            subtitle={`${name}님의 SPPB 노쇠 검사를 진행합니다`}
          />
        </>
      ) : (
        <>
          <Header title="노쇠 검사 진행" subtitle="SPPB 검사를 진행합니다" />
          <GetSenior type="record" />
        </>
      )}

      {/*{id && name ? (
        <ShowSenior id={id} name={name} />
      ) : (
        <GetSenior type="record" />
      )}*/}
      {id && name && (
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(3, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 3" },
                }}
              >
                <TextField
                  fullWidth
                  variant="filled"
                  disabled={true}
                  type="text"
                  label="name"
                  defaultValue={name}
                  value={values.name}
                  name="name"
                  /*onBlur={handleBlur}
                  onChange={handleChange}
                  error={!!touched.name && !!errors.name}
                  helperText={touched.name && errors.name}*/
                  sx={{ gridColumn: "span 1" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  disabled={true}
                  type="text"
                  label="id"
                  defaultValue={id}
                  value={values.id}
                  name="id"
                  /*onBlur={handleBlur}
                  onChange={handleChange}
                  error={!!touched.id && !!errors.id}
                  helperText={touched.id && errors.id}*/
                  sx={{ gridColumn: "span 1" }}
                />
                <TextField
                  id="date"
                  type="date"
                  label="등록일"
                  /*defaultValue={moment().format("YYYY-MM-DD")}*/
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.date}
                  name="date"
                  error={!!touched.date && !!errors.date}
                  helperText={touched.date && errors.date}
                  sx={{ gridColumn: "span 1" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="일반자세(초)"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.side_by_side}
                  name="side_by_side"
                  error={!!touched.side_by_side && !!errors.side_by_side}
                  helperText={touched.side_by_side && errors.side_by_side}
                  sx={{ gridColumn: "span 1" }}
                />
                {side_by_side_State(values.side_by_side) && (
                  <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    label="반일렬자세(초)"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.semi_tandem}
                    name="semi_tandem"
                    error={!!touched.semi_tandem && !!errors.semi_tandem}
                    helperText={touched.semi_tandem && errors.semi_tandem}
                    sx={{ gridColumn: "span 1" }}
                  />
                )}

                {side_by_side_State(values.side_by_side) &&
                  semi_tandem_State(values.semi_tandem) && (
                    <TextField
                      fullWidth
                      variant="filled"
                      type="number"
                      label="일렬자세(초)"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.tandem}
                      name="tandem"
                      error={!!touched.tandem && !!errors.tandem}
                      helperText={touched.tandem && errors.tandem}
                      sx={{ gridColumn: "span 1" }}
                    />
                  )}

                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="보행속도(초)"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.gait_speed}
                  name="gait_speed"
                  error={!!touched.gait_speed && !!errors.gait_speed}
                  helperText={touched.gait_speed && errors.gait_speed}
                  sx={{ gridColumn: "span 3" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="하지근력(초)"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.musculus}
                  name="musculus"
                  error={!!touched.musculus && !!errors.musculus}
                  helperText={touched.musculus && errors.musculus}
                  sx={{ gridColumn: "span 3" }}
                />
              </Box>
              <Box display="flex" justifyContent="end" mt="20px">
                <Button type="submit" color="secondary" variant="contained">
                  Preview Test Result
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      )}
      {showGrade && (
        <SPBBShowScore item={showGrade} setShowGrade={setShowGrade} />
      )}
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  side_by_side: yup
    .number()
    .required("입력값을 확인해주세요")
    .min(0, "음수는 유효하지 않습니다"),
  semi_tandem: yup.number().min(0, "음수는 유효하지 않습니다"),
  tandem: yup.number().min(0, "음수는 유효하지 않습니다"),
  date: yup.string().required("입력값을 확인해주세요"),
  gait_speed: yup
    .number()
    .required("입력값을 확인해주세요")
    .min(0, "음수는 유효하지 않습니다"),
  musculus: yup
    .number()
    .required("입력값을 확인해주세요")
    .min(0, "음수는 유효하지 않습니다"),
});
const initialValues = {
  side_by_side: "",
  semi_tandem: "",
  tandem: "",
  gait_speed: "",
  musculus: "",
  date : moment().format("YYYY-MM-DD")
};

export default Record;
