import { doc, getDoc, updateDoc, where } from "firebase/firestore";
import { db } from "../firebase/firebase"
import {
  query,
  orderBy,
  collection,
  getDocs,
  deleteDoc,
} from "firebase/firestore";

const baseUrl = "senior"

//모든 시니어 데이터 조회
export const getAllSenior = async () =>{
  try{
    const fbAllSeniorList = [];
    await getDocs(collection(db, "senior")).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        fbAllSeniorList.push({
          id: doc.id,
          /*idDoc: doc.id,*/
          createdAt: doc.data().createdAt,
          currentCoach: doc.data().currentCoach,
          currentCoachId: doc.data().currentCoachId,
          height: doc.data().height,
          initialCoach: doc.data().initialCoach,
          initialCoachId: doc.data().initialCoachId,
          name: doc.data().name,
          phoneNumber: doc.data().phoneNumber,
          sex: doc.data().sex,
          weight: doc.data().weight,
          age: doc.data().age,
          sppb: "",
          nutrition: "",
          fitness: "",
        });
      });

      //console.log(fbSeniorList);
    });
    return fbAllSeniorList;
  }
  catch(e){
    console.log(e)
  }
}


// 특정 시니어 Id의 데이터 조회
export const getFbSeniorById = async (id) => {
    //console.log(name);
    const docRef = doc(db, baseUrl, id);
    try {
      const docSnap=await getDoc(docRef);
      if(docSnap.exists()){ return docSnap.data()}
      else{console.log("document do not exist");}
    } catch (e) {
      console.log(e);
    }
  };

// 특정 시니어 Id의 데이터 수정
export const updateFbSeniorById = async (id, data) => {
  const docRef = doc(db, baseUrl, id);
  try {
    const docSnap = await updateDoc(docRef, data);
    console.log(
      "A New Document Field has been added to an existing document"
    );
  } catch (e) {
    console.log(e);
  }
};

// 특정 이름과 트레이너/관리자에 해당하는 모든 Senior 기록 데이터 조회
export const getFbSeniorDataByName = async (name) => {
  //console.log("getSeniordata", uid);
  const q = query(collection(db, "senior"), where("name", "==", name));
  const querySnapshot = await getDocs(q);

    try {
      const fbSeniorNameList = [];
      
      querySnapshot.forEach((doc) => {
        fbSeniorNameList.push({ id: doc.id, ...doc.data() });
      });

      //console.log("fbSeniorNameList", fbFilteredSeniorList);
      return fbSeniorNameList;

      //console.log(fbSeniorNameList);
    } catch (e) {
      console.log(e);
    }
  }

// 특정 트레이너 Id에 해당하는 모든 Senior 기록 데이터 조회
export const getFbSeniorDataByCurrentTrainer = async (uid) => {
  //console.log("getSeniordata", uid);
  const q = query(collection(db, "senior"), where("currentCoachId", "==", uid));
  const querySnapshot = await getDocs(q);

    try {
      const fbFilteredSeniorList = [];
      querySnapshot.forEach((doc) => {
        fbFilteredSeniorList.push({
          id: doc.id,
          /*idDoc: doc.id,*/
          createdAt: doc.data().createdAt,
          currentCoach: doc.data().currentCoach,
          currentCoachId: doc.data().currentCoachId,
          height: doc.data().height,
          initialCoach: doc.data().initialCoach,
          initialCoachId: doc.data().initialCoachId,
          name: doc.data().name,
          phoneNumber: doc.data().phoneNumber,
          sex: doc.data().sex,
          weight: doc.data().weight,
          age: doc.data().age,
          sppb: "",
          nutrition: "",
          fitness: "",
        });
      });

      //console.log("fbSeniorNameList", fbFilteredSeniorList);
      return fbFilteredSeniorList;

      //console.log(fbSeniorNameList);
    } catch (e) {
      console.log(e);
    }
  }


// 특정 시니어 Id에 해당하는 모든 SPBB 기록 데이터 조회
export const getFbSeniorRecordData = async (id) => {
  //console.log("getSeniordata",id)
    const q = query(
      collection(db, `/senior/${id}/record`),
      orderBy("date", "asc")
    );

    try {
      const fbRecordList = [];
      await getDocs(q).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const { senior, record, date } = doc.data();
          fbRecordList.push({
            id: doc.id,
            ...record,
            name: senior.name,
            date: date,
            seniorId: senior.id,
          });
        });
      });
      //console.log("fbSeniorNameList", fbRecordList);
      return fbRecordList;

      //console.log(fbSeniorNameList);
    } catch (e) {
      console.log(e);
    }
  }

//특정 시니어 Id로 시니어 정보 삭제
export const deleteFbSeniorById = async (id) =>{
  const docSeniorRef = doc(db, baseUrl, id);
  //console.log("delete", id, docRef);
   try {
     const docSnap = await deleteDoc(docSeniorRef);
     //console.log(docSnap); // res는 undefined
   } catch (e) {
     console.log(e);
   } finally {
     console.log("end");
   }
}

// 다수의 시니어 Id에 해당하는 모든 시니어 정보 삭제
export const deleteFbMultiSeniorById = async (seniorIds) =>{
  try{
    const fbRecordDataList = await Promise.all(
      seniorIds.map(async (id) => {
        const data = await deleteFbSeniorById(id);
        //console.log(data)
        return data;
      })
    );
    return fbRecordDataList;
    //console.log(fbRecordDataList);
  }
  catch(e){
    console.log(e)
}
}