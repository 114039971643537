const JAYCEE = "yDOszfs7Fgc47vTbhUTX1DrwHCX2";
const GREATER = "kHSJ72DOo9avLbjwF1Qw1mUwV6w2";

export function checkAdmin(uid) {
  return (uid === JAYCEE) || (uid === GREATER) ? true : false;
}


export function checkAbleToHandle(userId, currentCoachId, initialCoachId) {
  //console.log(userId, currentCoachId, initialCoachId);
  if (
    userId === currentCoachId ||
    userId === initialCoachId ||
    checkAdmin(userId)
  ) {
    return true;
  } else {
    return false;
  }
}
