import { useLocation } from "react-router";
//import qs from "query-string";
import { Senior, Nutrition, Exercise, Fitness } from "../../components/List";

const Record = () => {
  // In React Hooks:
  const searchParams = useLocation().search;
  //const query = qs.parse(searchParams);
  const type = new URLSearchParams(searchParams).get("type");
  //console.log(type)
  //const name = new URLSearchParams(searchParams).get("type");
  //const id = new URLSearchParams(searchParams).get("id");
  //console.log({ name, id });

  //return <>{type === "senior" ? <Senior /> : <Record />}</>;
  return (
    <div>
      {type === "senior" && <Senior />}
      {type === "exercise" && <Exercise />}
      {type === "nutrition" && <Nutrition />}
      {type === "fitness" && <Fitness/>}
    </div>
  );
};

export default Record;