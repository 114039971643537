import {
  useTheme,
  Box,
  Typography,
  useMediaQuery,
  Stack,
  Avatar,
} from "@mui/material";
import { tokens, nivoTheme } from "../../../../theme";
import {getOverAllColorByValue} from "../../../../utils/nutrition";

const OverAll = ({ record , fullWidth}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:1366px)");
  //console.log(record.overAll);
  return (
    <>
      <Typography
        variant="h3"
        color={colors.greenAccent[400]}
        fontWeight="bold"
        mb="15px"
      >
        영양 전체 결과
      </Typography>
      <Stack direction="column" spacing={2}>
        <OverAllScore
          score={record.overAll.score}
          total={record.overAll.total}
        />
        <OverAllStep value={record.overAll.value} />
        <OverAllValueInfo value={record.overAll.value} step={record.overAll.step}/>
      </Stack>
    </>
  );
};

const OverAllScore = ({ score , total}) => {
  return (
   
      <Stack direction="row" spacing={1} alignItems="flex-end">
        <Typography variant="h3" fontWeight="bold" color="textPrimary">
        {score}점
        </Typography>
        <Typography variant="h6" color="textSecondary">
          /{total}점
        </Typography>

      </Stack>
   
  );
};

const OverAllStep = ({ value }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  //console.log(value)
  return (
    <Stack direction="row" spacing={1} justifyContent="flex-start">
      <Avatar
        sx={{
          bgcolor:
            value === "미흡"
              ? getOverAllColorByValue(colors, "미흡")
              : getOverAllColorByValue(colors, "비활성화"),
          width: "50px",
          height: "50px",
        }}
      >
        미흡
      </Avatar>
      <Avatar
        sx={{
          bgcolor:
            value === "주의"
              ? getOverAllColorByValue(colors, "주의")
              : getOverAllColorByValue(colors, "비활성화"),
          width: "50px",
          height: "50px",
        }}
      >
        주의
      </Avatar>
      <Avatar
        sx={{
          bgcolor:
            value === "양호"
              ? getOverAllColorByValue(colors, "양호")
              : getOverAllColorByValue(colors, "비활성화"),
          width: "50px",
          height: "50px",
        }}
      >
        양호
      </Avatar>
    </Stack>
  );
};

const OverAllValueInfo = ({ step, value }) => {
  return (
    <>
      <Stack direction="row" alignItems="flex-end">
        <Typography
          variant="h3"
          fontWeight="bold"
          color="textPrimary"
          margin="0 3px"
        >
          {value}
        </Typography>
        <Typography variant="h6" color="textSecondary">
          단계로 &nbsp;
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {step}입니다.
        </Typography>
      </Stack>
    </>
  );
};

export default OverAll;
