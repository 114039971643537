import { collection, addDoc, doc, getDoc, deleteDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import { query, orderBy, getDocs } from "firebase/firestore";

const baseUrl = "nutrition";

// 특정 시니어 Id에 영양 정보 추가
export const AddNutritionById = async (seniorId, data) => {
  try {
    // 영양 기록 기록 구현
    const response = await addDoc(
      collection(db, `/senior/${seniorId}/${baseUrl}`),
      data
    );
    //console.log("handleFormSubmit finish", response);
    return response;
  } catch (e) {
    console.log(e);
  }
};

//특정 시니어 Id에 해당하는 모든 영양 정보 조회
export const getFbNutritionData = async (id) => {
  //console.log("getSeniordata",id)
    const q = query(
      collection(db, `/senior/${id}/${baseUrl}`),
      orderBy("date", "asc")
    );

    try {
      const fbRecordList = [];
      await getDocs(q).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          //console.log(doc.data())
          const { senior, date,        q1Data,
        q2Data,
        q3Data,
        q4Data,
        q5Data,
        BMIData, } = doc.data();
          fbRecordList.push({
            id: doc.id,
            q1:q1Data.value,
            q2:q2Data.value,
            q3:q3Data.value,
            q4:q4Data.value,
            q5:q5Data.value,
            bmi:BMIData.value,
            name: senior.name,
            date: date,
            seniorId: senior.id,
          });
        });
      });
      //console.log("fbSeniorNameList", fbRecordList);
      return fbRecordList;

      //console.log(fbSeniorNameList);
    } catch (e) {
      console.log(e);
    }
  }


  // 특정 시니어 Id와 특정 Nutrition 문서 Id에 해당하는 데이터 조회
  export const getFbNutritionById = async (seniorId, recordId)=>{
    const docRef = doc(db, `/senior/${seniorId}/${baseUrl}`, recordId);
      try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          //console.log("getFbNutritionById", docSnap.data());
          return docSnap.data();
        } else {
          console.log("document do not exist");
        }
      } catch (e) {
        console.log(e);
      }
  }


  // 특정 시니어 Id와 다수의 Nutrition 문서 Id에 해당하는 데이터 조회
  export const getFbMultiNutritionRecordById = async (seniorId, recordIds)=>{

    const fbNutritionDataList = await Promise.all(
      recordIds.map(async (recordId)=>{
        const data = await getFbNutritionById(seniorId, recordId);
        return data
      })
    )
    return fbNutritionDataList;
  }

//특정 영양 Id로 영양 정보 삭제
export const deleteFbNutritionById = async (seniorId, recordId) => {
  const docNutritionRef = doc(db, `/senior/${seniorId}/${baseUrl}`, recordId);
  //console.log("delete", id, docRef);
  try {
    const docSnap = await deleteDoc(docNutritionRef);
    //console.log(docSnap); // res는 undefined
  } catch (e) {
    console.log(e);
  } finally {
    console.log("end");
  }
};


  // 다수의 영양 Id에 해당하는 모든 영양 정보 삭제
export const deleteFbMultiNutritionById = async (seniorId,nutritionIds) =>{
  try{
    const fbRecordDataList = await Promise.all(
      nutritionIds.map(async (recordId) => {
        const data = await deleteFbNutritionById(seniorId, recordId);
        //console.log(data)
        return data;
      })
    );
    return fbRecordDataList;
    //console.log(fbRecordDataList);
  }
  catch(e){
    console.log(e)
}
}