import { collection, addDoc, getDoc, doc, deleteDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";

const baseUrl = "record";

//특정 시니어 Id에 SPBB 문서 추가
export const AddRecordById = async (seniorId, data) => {
  try {
    // 운동 기록 구현
    const response = await addDoc(
      collection(db, `/senior/${seniorId}/${baseUrl}`),
      data
    );
    //console.log("handleFormSubmit finish", response);
    return response;
  } catch (e) {
    console.log(e);
  }
};


// 특정 시니어 Id와 특정 SPBB 문서 Id 해당하는 데이터 조회
export const getFbRecordById = async (seniorId, recordId) => {
  //console.log(name);
  const docRef = doc(db, `/senior/${seniorId}/${baseUrl}`, recordId);
  try {
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      //console.log(docSnap.data())
      return docSnap.data();
    } else {
      console.log("document do not exist");
    }
  } catch (e) {
    console.log(e);
  }
};


// 특정 시니어 Id와 다수의 SPPB 문서 Id 해당하는 데이터 조회
export const getFbMultiRecordById = async (seniorId,recordIds)=>{
  //const fbRecordDataList = [];
  const fbRecordDataList = await Promise.all(
    recordIds.map(async (recordId) => {
      const data = await getFbRecordById(seniorId,recordId);
      //console.log(data)
      return data;
    })
  );
  return fbRecordDataList;

}

// 특정 시니어 Id와 특정 SPPB Id 문서 정보 삭제
export const deleteFbSPPBById = async (seniorId, recordId) => {
  const docNutritionRef = doc(db, `/senior/${seniorId}/${baseUrl}`, recordId);
  //console.log("delete", id, docRef);
  try {
    const docSnap = await deleteDoc(docNutritionRef);
    //console.log(docSnap); // res는 undefined
  } catch (e) {
    console.log(e);
  } finally {
    //console.log("end");
  }
};


  // 다수의 SPPB Id에 해당하는 모든 SPPB 정보 삭제
export const deleteFbMultiSPPBById = async (seniorId,sppbIds) =>{
  try{
    const fbRecordDataList = await Promise.all(
      sppbIds.map(async (recordId) => {
        const data = await deleteFbSPPBById(seniorId, recordId);
        //console.log(data)
        return data;
      })
    );
    return fbRecordDataList;
    //console.log(fbRecordDataList);
  }
  catch(e){
    console.log(e)
}
}