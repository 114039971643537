import { useState, useEffect } from "react";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import {
  CssBaseline,
  ThemeProvider,
  CircularProgress,
} from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import {  withFirebaseApi } from "../src/firebase";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { handleUserChange } from "./redux/userSlice";
import AppNav from "./AppNav";

//const OnboardingBase = (props) => {return <></>;};

//const Onboarding = withFirebaseApi(OnboardingBase);


//app
const isLoadingState = (state) => {
  return state.user.userId === undefined;
};


function App(props) {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const dispatch = useAppDispatch(); //필요
  const isLoading = useAppSelector(isLoadingState); //필요


  //console.log(userId, userInfo, userInfoLoadState)

  // 필요
  useEffect(() => {
return props.firebaseApi.onAuthStateChanged((user) => {
if (user) {
  dispatch(handleUserChange(props.firebaseApi, user.uid));
} else {
 dispatch(handleUserChange(props.firebaseApi, null));
}
});
}, []);

 if (isLoading) {
    return <CircularProgress sx={{ margin: "auto" }} />;
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <div className="app">
          <Sidebar isSidebar={isSidebar} />
          <main className="content">
            <Topbar setIsSidebar={setIsSidebar} />
            <AppNav/>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default withFirebaseApi(App);
