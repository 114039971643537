import { useEffect, useState } from "react";
import { tokens, nivoTheme } from "../../../../theme";
import { useTheme, Box, Typography, useMediaQuery, Stack } from "@mui/material";
import {
  getCompareResult,
  getCompareResultColor,
  getDataColorByScore,
} from "../../../../utils/fitness";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";


const ShowCompareValue = ({ current, previous }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  //console.log(current, previous)
  

  return (
    <Stack direction="row"  alignItems="center"  justifyContent="space-between" >
      <Box
        backgroundColor={getDataColorByScore(colors, previous.score)}
        padding="5px 8px"
        borderRadius="10px"
        color="white"
        fontWeight="bold"
      >
        {previous.value}
      </Box>
      <ArrowRightAltIcon fontSize="large" />
      <Box
        backgroundColor={getDataColorByScore(colors, current.score)}
        padding="5px 8px"
        borderRadius="10px"
        color="white"
        fontWeight="bold"
      >
        {current.value}
      </Box>
    </Stack>
  );
};




const ShowCompareScore = ({ current, previous }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const result = getCompareResult(current, previous);

  return (

      <Box
        backgroundColor={getCompareResultColor(colors, result)}
        padding="5px 8px"
        borderRadius="10px"
        color="white"
        fontWeight="bold"
      >
        {result}
      </Box>

  );
};


const CompareRecord = ({current, previous}) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:1366px)");
    const isNonTablet = useMediaQuery("(max-width:375px)");

  return (
    <>
      <Typography
        variant="h3"
        color={colors.greenAccent[400]}
        fontWeight="bold"
        m="18px 0"
      >
        기록 비교
      </Typography>
      <Stack direction="column" spacing={2} /*width="90%"*/ width="25rem">
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h4" fontWeight="bold" color="textPrimary">
            {current.muscle.title}
          </Typography>
          <Stack direction="row" spacing={5} /*width="250px"*/>
            <ShowCompareScore
              current={current.muscle.score}
              previous={previous.muscle.score}
            />
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h4" fontWeight="bold" color="textPrimary">
            {current.fat.title}
          </Typography>
          <Stack direction="row" spacing={5} /*width="250px"*/>
            <ShowCompareScore
              current={current.fat.score}
              previous={previous.fat.score}
            />
           
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h4" fontWeight="bold" color="textPrimary">
            {current.cardio.title}
          </Typography>
          <Stack direction="row" spacing={5} /*width="250px"*/>
            <ShowCompareScore
              current={current.cardio.score}
              previous={previous.cardio.score}
            />

          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h4" fontWeight="bold" color="textPrimary">
            {current.inferFlex.title}
          </Typography>
          <Stack direction="row" spacing={5} /*width="250px"*/>
            <ShowCompareScore
              current={current.inferFlex.score}
              previous={previous.inferFlex.score}
            />

          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h4" fontWeight="bold" color="textPrimary">
            {current.inferMuscle.title}
          </Typography>
          <Stack direction="row" spacing={5} /*width="250px"*/>
            <ShowCompareScore
              current={current.inferMuscle.score}
              previous={previous.inferMuscle.score}
            />

          </Stack>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h4" fontWeight="bold" color="textPrimary">
            {current.supFlex.title}
          </Typography>
          <Stack direction="row" spacing={5} /*width="250px"*/>
            <ShowCompareScore
              current={current.supFlex.score}
              previous={previous.supFlex.score}
            />

          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h4" fontWeight="bold" color="textPrimary">
            {current.supMuscle.title}
          </Typography>
          <Stack direction="row" spacing={5} /*width="250px"*/>
            <ShowCompareScore
              current={current.supMuscle.score}
              previous={previous.supMuscle.score}
            />

          </Stack>
        </Stack>
      </Stack>
    </>
  );
};


export default CompareRecord;