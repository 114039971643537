import {
  useTheme,
  Box,
  Typography,
  useMediaQuery,
  Stack,
  Avatar,
} from "@mui/material";
import { tokens, nivoTheme } from "../../../../theme";
import {getDataColorByScore} from "../../../../utils/fitness"

const BriefDetail = ({ record , fullWidth}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:1366px)");
  const isNonTablet= useMediaQuery("(max-width:375px)");
  //console.log(record)

  return (
    <>
      <Typography
        variant="h3"
        color={colors.greenAccent[400]}
        fontWeight="bold"
        mb="15px"
      >
        세부 결과
      </Typography>
      {record.map((item, idx) => (
        <Box
          display="flex"
          margin="8px 0"
          flexDirection={isNonMobile ? "row" : isNonTablet?fullWidth?"row":"column":fullWidth?"row":"column"}
          justifyContent="space-between"
          width="100%"
          key={idx}
        >
          <Typography variant="h4" fontWeight="bold" color="textPrimary">
            {item.title}
          </Typography>
          <Stack direction="column" width="50%" margin="8px 0">
            <Stack direction="row" spacing={1} marginBottom="8px">
              <Box
                backgroundColor={getDataColorByScore(colors, item.score)}
                padding="5px 10px"
                borderRadius="10px"
                color="white"
                fontWeight="bold"
              >
                {item.score}점 / {item.total}점
              </Box>
              <Box
                backgroundColor={getDataColorByScore(colors, item.score)}
                padding="5px 10px"
                borderRadius="10px"
                color="white"
                fontWeight="bold"
              >
                {item.value}
              </Box>
            </Stack>
          </Stack>
        </Box>
      ))}
    </>
  );
};

export default BriefDetail;
