import { useTheme, Box, Typography, useMediaQuery, Stack } from "@mui/material";
import { tokens, nivoTheme } from "../../../../theme";
import { ResponsiveLine } from "@nivo/line";
import { ResponsiveBar } from "@nivo/bar";
import { getBMIColorForScore } from "../../../../utils/nutrition";

function CreateBarData(record){
  const response = []
  record.map((item) =>
    response.push({
      date: item.date.slice(2),
      score: item.BMIData.value,
    })
  );
  return response
}


function CreateLineData(record) {
  const response = [];
  record.forEach((item) =>
    response.push({
      x: item.date.slice(2),
      y: item.BMIData.score,
    })
  );
  return [{
    id:"BMI Score",
    data:
    response}]
}

export const BMIBar = ({ record, title }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:1366px)");
  return (
    <Box height="45vh" paddingBottom="45px">
      <Typography
        variant="h3"
        color={colors.greenAccent[400]}
        fontWeight="bold"
        mb="15px"
      >
        {title} 비교
      </Typography>
      {
        <ResponsiveBar
          data={CreateBarData(record)}
          keys={["score"]}
          indexBy="date"
          margin={{ top: 50, right: 100, bottom: 100, left: 35 }}
          padding={0.3}
          theme={nivoTheme(colors)}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={{ scheme: "pastel1" }}
          borderColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 45,
            legend: "date",
            legendPosition: "middle",
            legendOffset: 70,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 10,
            tickRotation: 0,
            legend: "score",
            legendPosition: "middle",
            legendOffset: -40,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          legends={[
            {
              dataFrom: "keys",
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          role="application"
          ariaLabel="Nivo bar chart demo"
          barAriaLabel={function (e) {
            return (
              e.id + ": " + e.formattedValue + " in country: " + e.indexValue
            );
          }}
        />
      }
    </Box>
  );
};



export const BMILine = ({record, title})=>{
      const theme = useTheme();
      const colors = tokens(theme.palette.mode);
      const isNonMobile = useMediaQuery("(min-width:1366px)");

      return (
        <Box height="45vh" paddingBottom="45px">
          <Typography
            variant="h3"
            color={colors.greenAccent[400]}
            fontWeight="bold"
            mb="15px"
          >
            {title} 점수 비교
          </Typography>
          {
            <ResponsiveLine
              data= {CreateLineData(record)}
              margin={{ top: 50, right: 100, bottom: 50, left: 45 }}
              theme={nivoTheme(colors)}
              colors={{ scheme: "pastel1" }}
              xScale={{ type: "point" }}
              yScale={{
                type: "linear",
                min: "auto",
                max: "auto",
                stacked: true,
                reverse: false,
              }}
              yFormat=" >-.2f"
              axisTop={null}
              axisRight={null}
              axisBottom={{
                orient: "bottom",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 45,
                legend: "date",
                legendOffset: 70,
                legendPosition: "middle",
              }}
              axisLeft={{
                orient: "left",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "record",
                legendOffset: -40,
                legendPosition: "middle",
              }}
              pointSize={10}
              pointColor={{ theme: "background" }}
              pointBorderWidth={2}
              pointBorderColor={{ from: "serieColor" }}
              pointLabelYOffset={-12}
              useMesh={true}
              legends={[
                {
                  anchor: "bottom-right",
                  direction: "column",
                  justify: false,
                  translateX: 100,
                  translateY: 0,
                  itemsSpacing: 0,
                  itemDirection: "left-to-right",
                  itemWidth: 80,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: "circle",
                  symbolBorderColor: "rgba(0, 0, 0, .5)",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemBackground: "rgba(0, 0, 0, .03)",
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
            />
          }
        </Box>
      );



}

export const BMIInfo = ({record, title})=>{
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:1366px)");


    return (
      <Box paddingBottom="20px">
        <Typography
          variant="h3"
          color={colors.greenAccent[400]}
          fontWeight="bold"
          m="18px 0"
        >
          {title} 기록 요약
        </Typography>
        {record.map((item) => (
          <Stack direction="column" key={item.date}>
                  <Stack
                direction="row"
                spacing={3}
                alignItems="center"
                margin="8px 0"
   
              >
              <Typography variant="h4" fontWeight="bold" color="textPrimary">
                {item.date}
              </Typography>
              <Stack
                direction="row"
                spacing={3}
                alignItems="center"
              >
                <Box
                  backgroundColor={getBMIColorForScore(
                    colors,
                    item.BMIData.score
                  )}
                  padding="5px 10px"
                  borderRadius="10px"
                  color="white"
                  fontWeight="bold"
                >
                  {" "}
                  {item.BMIData.value}
                </Box>
                <Box
                  backgroundColor={getBMIColorForScore(
                    colors,
                    item.BMIData.score
                  )}
                  padding="5px 10px"
                  borderRadius="10px"
                  color="white"
                  fontWeight="bold"
                >
                  {" "}
                  {item.BMIData.score}점 / {item.BMIData.total}점
                </Box>
              </Stack>
            </Stack>
          </Stack>
        ))}
      </Box>
    );




}