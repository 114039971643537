import { useLocation } from "react-router";
import qs from "query-string";
import CompleteNotice from "../../components/Complete";

const Complete = () => {
  // In React Hooks:
  const searchParams = useLocation().search;
  //console.log(searchParams);
  const query = qs.parse(searchParams);
  //console.log(query);
  const type = new URLSearchParams(searchParams).get("type");
  //console.log(type)
  //const name = new URLSearchParams(searchParams).get("type");
  //const id = new URLSearchParams(searchParams).get("id");
  //console.log({ name, id });

  return (
    <>
      <CompleteNotice type={type} />
      {/*{type === "sppb" && <SPPB />}
      {type === "exercise" && <Record />}
      {type === "nutrition" && <Nutrition />}
  {type === "fitness" && <Fitness />}*/}
    </>
  );
};

export default Complete;
