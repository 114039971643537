import { useEffect, useState } from "react";
import { tokens } from "../../../../theme";
import { useTheme, Box, useMediaQuery, Typography } from "@mui/material";
import { getFbSeniorById } from "../../../../apis/senior";
import { getFbMultiFitnessById } from "../../../../apis/fitness";

import UserInfo from "../../../userInfo";
import CompareRecord from "./compareRecord";
import CompareRadial from "./compareRadial";
import CompareOverAll from "./compareOverAll";
import { Line, Bar, Detail } from "./detail";
import { Elements } from "../../../../utils/fitness";
import PngBtn from "../../../pngBtn";

const MultiReport = ({ id, name, recordId }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:1366px)");
  const isNonTablet = useMediaQuery("(max-width:500px)");
  const [fullWidth, setFullWidth] = useState(false)
  const [senior, setSenior] = useState(null);
  const [record, setRecord] = useState(null);

  useEffect(() => {
    async function fetchSeniorData() {
      const response = await getFbSeniorById(id);
      setSenior(response);
    }
    fetchSeniorData();
  }, [id]);

  useEffect(() => {
    async function fetchFitnessData() {
      const response = await getFbMultiFitnessById(id, recordId);
      //console.log("fetchNutritionData",response.at(-1));
      setRecord(response);
    }
  fetchFitnessData();
  }, [recordId]);

  //console.log("record", record );

  function setReportWidth(state){
    setFullWidth(state);
  }

  return (
    <Box m="20px">
      {senior && record && (
        <Box
          className="PdfArea"
          display="grid"
          gap="10px"
          gridTemplateColumns="repeat(12, minmax(0, 1fr))"
          backgroundColor={colors.primary[400]}
          borderRadius="10px"
          sx={{
            width: isNonMobile
              ? "auto"
              : isNonTablet
              ? fullWidth
                ? "1366px !important"
                : "500px":fullWidth
                ? "1366px !important"
              : "auto",
          }}
        >
          {/* User Info */}
          <Box m="0 20px" p="20px" sx={{ gridColumn: "span 12" }}>
            {senior && record && (
              <UserInfo
                user={senior}
                date={record.date}
                fullWidth={fullWidth}
              />
            )}
          </Box>
          {/* Compare Recent Record */}
          <Box m="0 10px" p="0 20px" sx={{ gridColumn: "span 12" }}>
            <Typography
              variant="h3"
              color={colors.greenAccent[400]}
              fontWeight="bold"
            >
              {record.at(-1) ? record.at(-1).date : "current date"} vs{" "}
              {record.at(-2) ? record.at(-2).date : "previous date"}
            </Typography>
          </Box>
          <Box
            m="0 10px"
            p="0 20px"
            sx={{
              gridColumn: isNonMobile
                ? "span 4"
                : fullWidth
                ? "span 4"
                : "span 12",
            }}
          >
            <CompareRecord
              current={record.at(-1)}
              previous={record.at(-2)}
              fullWidth={fullWidth}
            />
          </Box>
          <Box
            m="0 20px"
            p="20px"
            sx={{
              gridColumn: isNonMobile
                ? "span 4"
                : fullWidth
                ? "span 4"
                : "span 12",
            }}
          >
            <CompareRadial current={record.at(-1)} previous={record.at(-2)} />
          </Box>

          <Box
            m="0 20px"
            p="20px"
            sx={{
              gridColumn: isNonMobile
                ? "span 4"
                : fullWidth
                ? "span 4"
                : "span 12",
            }}
          >
            <CompareOverAll record={record} />
          </Box>

          {Elements.map((item) => (
            <>
              <Box
                m="0 20px"
                p="20px"
                sx={{
                  gridColumn: isNonMobile
                    ? "span 4"
                    : fullWidth
                    ? "span 4"
                    : "span 12",
                }}
              >
                <Line record={record} type={item.type} title={item.name} />
              </Box>
              <Box
                m="0 20px"
                p="20px"
                sx={{
                  gridColumn: isNonMobile
                    ? "span 4"
                    : fullWidth
                    ? "span 4"
                    : "span 12",
                }}
              >
                <Bar record={record} type={item.type} title={item.name} />
              </Box>
              <Box
                m="0 20px"
                p="20px"
                sx={{
                  gridColumn: isNonMobile
                    ? "span 4"
                    : fullWidth
                    ? "span 4"
                    : "span 12",
                }}
              >
                <Detail record={record} type={item.type} title={item.name} />
              </Box>
            </>
          ))}
        </Box>
      )}

      <PngBtn setReportWidth={setReportWidth} />
    </Box>
  );
};

export default MultiReport;
