import { useEffect, useState } from "react";
import {
  Box,
  Button,
  useTheme,
  Typography,
  Stack,
  useMediaQuery,
  TextField,
  RadioGroup,
  Card,
  CardHeader,
  Avatar,
  IconButton,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { tokens } from "../../theme";
import { Formik } from "formik";
import * as yup from "yup";
import { useAppSelector } from "../../redux/hooks";
import { useNavigate } from "react-router-dom";
import { getFbTrainerList } from "../../apis/trainer";
import DefaultImg from "../../assets/noricare.png";


const GetTrainer = ({ onClose, setSenior, getProfileImgUrl }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [searchName, setSearchName] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const isNonMobile = useMediaQuery("(min-width:1366px)");
  const userInfo = useAppSelector((state) => state.user.userInfo.value);
  console.log(getProfileImgUrl);

  const handleSearchTrainer = async () => {
    //console.log(searchName);
    const response = await getFbTrainerList(searchName);
    //console.log(response);
    setSearchResult(response);
    //e.stopPropagation();
  };
  const onChange = (event) => {
    //console.log(event.target.value);
    setSearchName(event.target.value);
  };

  useEffect(() => {
    //getFbSeniorList(searchName);
  }, [searchName]);
  useEffect(() => {}, [searchResult]);
  return (
    <>
      <Box
        borderRadius="3px"
        sx={{
          gridColumn: "span 2",
          "& > form": {
            width: "100%",
          },
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          borderRadius="3px"
          width="100%"
        >
          <TextField
            label="trainer name"
            color="primary"
            sx={{ flex: 1 }}
            onChange={onChange}
          />

          <Button
            onClick={handleSearchTrainer}
            color="secondary"
            variant="outlined"
            size="large"
            sx={{ gridColumn: "span 1", ml: "8px" }}
          >
            검색
          </Button>
        </Box>
      </Box>
      <Stack direction="column" spacing={2}>
        {searchResult.length === 0 ? (
          <Typography variant="h5" color={colors.grey[400]} sx={{ mt: 1.5 }}>
            검색 결과 없음
          </Typography>
        ) : (
          <>
            {/*<Typography variant="h5" color={colors.grey[400]} sx={{ mt: 1.5 }}>
              {searchName} 검색 결과
            </Typography>*/}

            {searchResult.map((item) => (
              <TrainerCard
                key={item.id}
                id={item.id}
                name={item.username}
                picHandler={item.profilePicHandle}
                setSenior={setSenior}
                onClose={onClose}
                getProfileImgUrl={getProfileImgUrl}
              />
            ))}

            <Button
              type="submit"
              color="secondary"
              variant="outlined"
              size="large"
              onClick={onClose}
            >
              닫기
            </Button>
          </>
        )}
      </Stack>
    </>
  );
};


export default GetTrainer;

const TrainerCard = ({
  id,
  name,
  picHandler,
  setSenior,
  onClose,
  getProfileImgUrl,
}) => {
  console.log(id, name, picHandler);
  const [profileImg, setProfileImg] = useState(null);

  async function getProfileUrl(picHandler, getProfileImgUrl) {
    //console.log("getProfileUrl", getProfileImgUrl);
    if (picHandler == null) {
      setProfileImg(DefaultImg);
    } else {
      //console.log(rest);
      const response = await getProfileImgUrl(picHandler);

      setProfileImg(response || DefaultImg);
      //console.log(response)
      return response;
    }
  }

  useEffect(() => {
    getProfileUrl(picHandler,getProfileImgUrl);
  }, [id]);

  const handleSelectCoachBtn = () => {
    setSenior((prevState) => ({
      ...prevState,
      currentCoachId: id,
      currentCoach: name,
    }));
    onClose();
  };
  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar alt={name} src={profileImg} sx={{ width: 50, height: 50 }} />
        }
        action={
          <IconButton onClick={handleSelectCoachBtn}>
            <CheckIcon />
          </IconButton>
        }
        title={name}
        subheader={id}
      />
    </Card>
  );
};




