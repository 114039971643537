import { ResponsiveRadialBar } from '@nivo/radial-bar'
import { useTheme, Box, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens, nivoTheme } from "../../../../theme";
import { ResponsiveBar } from "@nivo/bar";


export const BalanceBar = ({record})=>{
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:1366px)");
    //console.log(record)
    function createData(){
      const response = []
      record.forEach((item) =>
        response.push({
          date: item.date.slice(2),
          score:item.balance.score,
        })
      );
      //console.log(response)
      return response;
    }

  return (
    <Box height="45vh" width="45vh">
      <Box>
        <Typography
          variant="h3"
          color={colors.greenAccent[400]}
          fontWeight="bold"
          m="18px 0"
        >
          균형 평가 점수 비교
        </Typography>
      </Box>
      {(
        <ResponsiveBar
          data={createData()}
          keys={["score"]}
          indexBy="date"
          margin={{ top: 50, right: 100, bottom: 100, left: 35 }}
          padding={0.3}
          theme={nivoTheme(colors)}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={{ scheme: "pastel1" }}
          borderColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 45,
            legend: "date",
            legendPosition: "middle",
            legendOffset: 70,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 10,
            tickRotation: 0,
            legend: "score",
            legendPosition: "middle",
            legendOffset: -40,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          legends={[
            {
              dataFrom: "keys",
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          role="application"
          ariaLabel="Nivo bar chart demo"
          barAriaLabel={function (e) {
            return (
              e.id + ": " + e.formattedValue + " in country: " + e.indexValue
            );
          }}
        />
      )}
    </Box>
  );
}

export const BalanceRadial = ({record}) =>{
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:1366px)");
   // const [data, setData] = useState([])

    function createData(){
      const response = []
      record.forEach((item) =>
        response.push({
          id: item.date.slice(2),
          data: [
            {
              x: "일반 자세",
              y: item.record.side_by_side,
            },
            {
              x: "반정렬 자세",
              y: item.record.semi_tandem,
            },
            {
              x: "정렬 자세",
              y: item.record.tendem,
            },
          ],
        })
      );
      return response
    }



  return (
    <Box height="35vh" width="35vh">
      <Box>
        <Typography
          variant="h3"
          color={colors.greenAccent[400]}
          fontWeight="bold"
          m="18px 0"
        >
          균형 평가 기록 비교
        </Typography>
      </Box>
      { (
        <ResponsiveRadialBar
          data={createData()}
          valueFormat=">-.2f"
          theme={nivoTheme(colors)}
          colors={{ scheme: "pastel1" }}
          padding={0.4}
          cornerRadius={2}
          margin={{ top: 5, right: 75, bottom: 5, left: 10 }}
          radialAxisStart={{ tickSize: 5, tickPadding: 5, tickRotation: 0 }}
          circularAxisOuter={{ tickSize: 5, tickPadding: 12, tickRotation: 0 }}
          legends={[
            {
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 100,
              translateY: 0,
              itemsSpacing: 6,
              itemDirection: "left-to-right",
              itemWidth: 100,
              itemHeight: 18,
              itemTextColor: "#999",
              symbolSize: 18,
              symbolShape: "square",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemTextColor: "#000",
                  },
                },
              ],
            },
          ]}
        />
      )}
    </Box>
  );
}

