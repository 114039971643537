import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box, Button, TextField, Stack } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik} from "formik";
import * as yup from "yup";
import moment from "moment";
import { getFbSeniorById } from "../../apis/senior";
import Header from "../Header";
import LabeledRadio from "./labeledRadio";
import GetSenior from "./getSenior";
import {NutritionShowScore} from "./showScore";
import { Questions } from "../../utils/nutrition";



const Nutrition = () => {
  const isNonMobile = useMediaQuery("(min-width:1366px)");
  const searchParams = useLocation().search;
  //const query = qs.parse(searchParams);
  const id = new URLSearchParams(searchParams).get("id");
  const name = new URLSearchParams(searchParams).get("name");
  const [showGrade, setShowGrade] = useState(null);
  const [senior, setSenior] = useState(null);


  useEffect(() => {

    async function fetchSeniorData() {
      const response = await getFbSeniorById(id);
      //console.log(response);
      setSenior(response);
    }
  
    if (id) {
      fetchSeniorData();
    }
  }, [id]);




  const handleFormSubmit = async (values) => {
    //console.log(values)
    const {q1,q2,q3,q4,q5,date}=values
    const data = {
      name: name,
      id: id,
      q1: parseInt(q1),
      q2: parseInt(q2),
      q3: parseInt(q3),
      q4: parseInt(q4),
      q5: parseInt(q5),
      bmi:(senior.weight / (senior.height * senior.height)).toFixed(1),
      date:date
    };
    //console.log(data)
    setShowGrade((showGrade) => ({ ...data }));
  };

  return (
    <Box m="20px">
      {id && name ? (
        <>
          <Header
            title="영양 평가 진행"
            subtitle={`${name}님의 영양 평가를 진행합니다`}
          />
        </>
      ) : (
        <>
          <Header title="영양 평가 진행" subtitle="영양 평가를 진행합니다" />
          <GetSenior type="nutrition" />
        </>
      )}

      {id && name && senior && (
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(3, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 3" },
                }}
              >
                <TextField
                  fullWidth
                  variant="filled"
                  disabled={true}
                  type="text"
                  label="name"
                  defaultValue={name}
                  value={values.name}
                  name="name"
                  /*onBlur={handleBlur}
                  onChange={handleChange}
                  error={!!touched.name && !!errors.name}
                  helperText={touched.name && errors.name}*/
                  sx={{ gridColumn: "span 1" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  disabled={true}
                  type="text"
                  label="id"
                  defaultValue={id}
                  value={values.id}
                  name="id"
                  /*onBlur={handleBlur}
                  onChange={handleChange}
                  error={!!touched.id && !!errors.id}
                  helperText={touched.id && errors.id}*/
                  sx={{ gridColumn: "span 1" }}
                />
                <TextField
                  id="date"
                  type="date"
                  label="등록일"
                  /*defaultValue={moment().format("YYYY-MM-DD")}*/
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.date}
                  name="date"
                  error={!!touched.date && !!errors.date}
                  helperText={touched.date && errors.date}
                  sx={{ gridColumn: "span 1" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {Questions.map(({ q, ans }, idx) => (
                  <RadioQuestion q={q} ans={ans} key={idx} />
                ))}
                <Box sx={{ gridColumn: "span 3" }}>
                  <h3>체질량 지수</h3>
                  <Stack direction="row" spacing={2}>
                    <TextField
                      fullWidth
                      variant="filled"
                      disabled={true}
                      type="text"
                      label="weight"
                      defaultValue={senior.weight}
                      value={values.weight}
                      name="weight"
                      /*onBlur={handleBlur}
                  onChange={handleChange}
                  error={!!touched.name && !!errors.name}
                  helperText={touched.name && errors.name}*/
                      sx={{ gridColumn: "span 1" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      disabled={true}
                      type="text"
                      label="height"
                      defaultValue={senior.height}
                      value={values.height}
                      name="height"
                      /*onBlur={handleBlur}
                  onChange={handleChange}
                  error={!!touched.name && !!errors.name}
                  helperText={touched.name && errors.name}*/
                      sx={{ gridColumn: "span 1" }}
                    />

                    <TextField
                      fullWidth
                      variant="filled"
                      disabled={true}
                      type="number"
                      label="체질량 지수 (BMI)"
                      defaultValue={(
                        senior.weight /
                        (senior.height/100 * senior.height/100)
                      ).toFixed(1)}
                      value={values.bmi}
                      name="bmi"
                      /*onBlur={handleBlur}
                  onChange={handleChange}
                  error={!!touched.name && !!errors.name}
                  helperText={touched.name && errors.name}*/
                      sx={{ gridColumn: "span 1" }}
                    />
                  </Stack>
                </Box>
              </Box>
              <Box display="flex" justifyContent="end" mt="20px">
                <Button type="submit" color="secondary" variant="contained">
                  Preview Test Result
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      )}
      {showGrade && (
        <NutritionShowScore item={showGrade} setShowGrade={setShowGrade} />
      )}
    </Box>
  );
};



const checkoutSchema = yup.object().shape({
  date: yup.string().required("입력값을 확인해주세요"),
  q1: yup.number().required("입력값을 확인해주세요"),
  q2: yup.number().required("입력값을 확인해주세요"),
  q3: yup.number().required("입력값을 확인해주세요"),
  q4: yup.number().required("입력값을 확인해주세요"),
  q5: yup.number().required("입력값을 확인해주세요"),
});
const initialValues = {
  date: moment().format("YYYY-MM-DD"),
  
};

export default Nutrition;

const RadioQuestion = ({q, ans}) => {
  return (
    <Box sx={{ gridColumn: "span 3" }}>
      <h3>{q}</h3>
      {ans.map((a,idx) => (
        <LabeledRadio key={idx} name={a.name} value={String(a.value)} label={a.label} type="radio" />
      ))}

    </Box>
  );
};



