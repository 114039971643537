import { useState, useEffect } from "react";
import { getFbSeniorById } from "../../../../apis/senior";
import {  getFbMultiRecordById } from "../../../../apis/sppb";
import { useTheme, Box, Typography } from "@mui/material";
import { tokens } from "../../../../theme";
import useMediaQuery from "@mui/material/useMediaQuery";
import UserInfo from "../../../userInfo";
import CompareRecord from "./compareRecord";
import { BalanceRadial, BalanceBar } from "./balance";
import { SpeedBar, SpeedLine } from "./speed";
import {MusculusBar, MusculusLine} from "./musculus";
import CompareRadial from "./compareRadial";
import PngBtn from "../../../pngBtn";
import { OverAll, Balance, Speed, Musculus } from "./detail";

const MultiReport = ({ id, name, recordId }) => {
  //console.log(recordId);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:1366px)");
    const isNonTablet = useMediaQuery("(max-width:500px)");
    const [fullWidth, setFullWidth] = useState(false);
    const [senior, setSenior] = useState(null);
    const [record, setRecord] = useState([]);
    //console.log("MultiReport", recordId);

  function setReportWidth(state) {
    setFullWidth(state);
  }

  useEffect(() => {
    async function fetchSeniorData() {
      const response = await getFbSeniorById(id);
      setSenior(response);
    }
    fetchSeniorData();
  }, [id]);



  useEffect(() => {
    async function fetchRecordData() {
      const response = await getFbMultiRecordById(id, recordId);
      //console.log("multi", response)
      setRecord(response);
    }
    fetchRecordData();

    }
    //fetchRecordData();
    //console.log(fbRecordDataList)
  , [recordId]);
  return (
    <Box m="20px">
      {record && (
        <Box
          className="PdfArea"
          display="grid"
          p="35px"
          gap="10px"
          gridTemplateColumns="repeat(12, minmax(0, 1fr))"
          backgroundColor={colors.primary[400]}
          borderRadius="10px"
          sx={{
            width: isNonMobile
              ? "auto"
              : isNonTablet
              ? fullWidth
                ? "1366px !important"
                : "500px"
              : fullWidth
                ? "1366px !important": "auto",
          }}
        >
          {/* User Info */}
          <Box m="0 20px" p="20px" sx={{ gridColumn: "span 12" }}>
            {senior && record && (
              <UserInfo
                user={senior}
                date={record.date}
                fullWidth={fullWidth}
              />
            )}
          </Box>
          {/* Compare Recent Record */}
          <Box m="0 10px" sx={{ gridColumn: "span 12" }}>
            <Typography
              variant="h3"
              color={colors.greenAccent[400]}
              fontWeight="bold"
            >
              {record.at(-1) ? record.at(-1).date : "current date"} vs{" "}
              {record.at(-2) ? record.at(-2).date : "previous date"}
            </Typography>
          </Box>

          <Box
            m="0 20px"
            p="20px"
            sx={{
              gridColumn: isNonMobile
                ? "span 4"
                : fullWidth
                ? "span 4"
                : "span 12",
            }}
          >
            <CompareRecord current={record.at(-1)} previous={record.at(-2)} />
          </Box>
          <Box
            m="0 20px"
            p="20px"
            sx={{
              gridColumn: isNonMobile
                ? "span 4"
                : fullWidth
                ? "span 4"
                : "span 12",
            }}
          >
            <CompareRadial current={record.at(-1)} previous={record.at(-2)} />
          </Box>
          <Box
            m="0 20px"
            p="20px"
            sx={{
              gridColumn: isNonMobile
                ? "span 4"
                : fullWidth
                ? "span 4"
                : "span 12",
            }}
          >
            <OverAll record={record} length={record.length} />
          </Box>
          {/* Balance Record */}
          <Box
            m="0 20px"
            p="20px"
            sx={{
              gridColumn: isNonMobile
                ? "span 4"
                : fullWidth
                ? "span 4"
                : "span 12",
            }}
          >
            <BalanceRadial record={record} />
          </Box>
          <Box
            m="0 20px"
            p="20px"
            sx={{
              gridColumn: isNonMobile
                ? "span 4"
                : fullWidth
                ? "span 4"
                : "span 12",
            }}
          >
            <BalanceBar record={record} />
          </Box>
          <Box
            m="0 20px"
            p="20px"
            sx={{
              gridColumn: isNonMobile
                ? "span 4"
                : fullWidth
                ? "span 4"
                : "span 12",
            }}
          >
            <Balance record={record} />
          </Box>
          {/* Speed Record */}
          <Box
            m="0 20px"
            p="20px"
            sx={{
              gridColumn: isNonMobile
                ? "span 4"
                : fullWidth
                ? "span 4"
                : "span 12",
            }}
          >
            <SpeedLine record={record} />
          </Box>
          <Box
            m="0 20px"
            p="20px"
            sx={{
              gridColumn: isNonMobile
                ? "span 4"
                : fullWidth
                ? "span 4"
                : "span 12",
            }}
          >
            <SpeedBar record={record} />
          </Box>
          <Box
            m="0 20px"
            p="20px"
            sx={{
              gridColumn: isNonMobile
                ? "span 4"
                : fullWidth
                ? "span 4"
                : "span 12",
            }}
          >
            <Speed record={record} />
          </Box>
          {/* Musculus Record */}
          <Box
            m="0 20px"
            p="20px"
            sx={{
              gridColumn: isNonMobile
                ? "span 4"
                : fullWidth
                ? "span 4"
                : "span 12",
            }}
          >
            <MusculusLine record={record} />
          </Box>
          <Box
            m="0 20px"
            p="20px"
            sx={{
              gridColumn: isNonMobile
                ? "span 4"
                : fullWidth
                ? "span 4"
                : "span 12",
            }}
          >
            <MusculusBar record={record} />
          </Box>
          <Box
            m="0 20px"
            p="20px"
            sx={{
              gridColumn: isNonMobile
                ? "span 4"
                : fullWidth
                ? "span 4"
                : "span 12",
            }}
          >
            <Musculus record={record} />
          </Box>
        </Box>
      )}

      <PngBtn setReportWidth={setReportWidth} />
    </Box>
  );
};

export default MultiReport;