import { useTheme, Box, Typography } from "@mui/material";
import { tokens, nivoTheme } from "../../../../theme";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RemoveIcon from "@mui/icons-material/Remove";
import useMediaQuery from "@mui/material/useMediaQuery";

function calPercentDiff(current, previous) {
 // console.log(current, previous)
  const difference = (current - previous);
  const average = (current + previous) / 2;
  //console.log("average",average)
  const percentdiff = (difference / average) * 100;
  //console.log(difference, average, percentdiff);
  return { percentage: percentdiff.toFixed(2), difference: difference };
}

function rateIcon(difference){

  if (difference>0){
    //console.log("양수",difference);
    return (
        <ExpandLessIcon fontSize="small" />
    );
  }
  else if (difference<0){
    //console.log("음수", difference);
    return <ExpandMoreIcon fontSize="small" />;
  }
  else {
    //("정지", difference);
    return <RemoveIcon fontSize="small" />;
  }
}

const DifferentBox = ({ title,current, previous }) => {
  const isNonMobile = useMediaQuery("(min-width:1366px)");
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      justifyContent="space-between"
      margin="5px"
      sx={{ width: isNonMobile ? "110px" : "auto" }}
    >
      <Typography variant="h4" fontWeight="bold" color="textPrimary">
        {title}
      </Typography>
      <Box padding="2.5px 5px" borderRadius="10px" display="flex" flexDirection="row" alignItems="center">
        <Box>{calPercentDiff(current, previous).percentage}% </Box>
        <Box p="6px 3px">{rateIcon(calPercentDiff(current, previous).difference)}</Box>
      </Box>
      <Box padding="2.5px 5px" borderRadius="10px">
        {calPercentDiff(current, previous).difference} 초
      </Box>
    </Box>
  );
};

const Balance = ({current, previous}) =>{
  const isNonMobile = useMediaQuery("(min-width:1366px)");
  //console.log(current, previous)
  return (
    <Box
      display="flex"
      alignItems="center"
      margin="3px 0"
      justifyContent="space-between"
      flexDirection="column"
      sx={{ width: isNonMobile ? "auto" : "100%" }}
    >
      <Typography variant="h4" fontWeight="bold" color="textPrimary">
        balance
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        margin="3px 0"
        justifyContent="space-between"
        flexDirection="row"
        width="100%"
      >
        <DifferentBox
          title="일반 자세"
          current={current.side_by_side}
          previous={previous.side_by_side}
        />
        <DifferentBox
          title="반 일렬 자세"
          current={current.semi_tandem}
          previous={previous.semi_tandem}
        />
        <DifferentBox
          title="일렬 자세"
          current={current.tendem}
          previous={previous.tendem}
        />
      </Box>
    </Box>
  );
}

const Speed = ({current, previous})=>{
      const isNonMobile = useMediaQuery("(min-width:1366px)");
  return (
    <Box
      display="flex"
      alignItems="center"
      margin="3px 0"
      justifyContent="space-between"
      flexDirection="column"
    >
      <Typography variant="h4" fontWeight="bold" color="textPrimary">
        Speed
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        margin="3px 0"
        justifyContent="space-between"
        flexDirection="row"
      >
        <DifferentBox
          title="보행 속력"
          current={current.gait_speed}
          previous={previous.gait_speed}
        />
      </Box>
    </Box>
  );
}

const Musculus = ({current, previous})=>{
      
  return (
    <Box
      display="flex"
      alignItems="center"
      margin="3px 0"
      justifyContent="space-between"
      flexDirection="column"
    >
      <Typography variant="h4" fontWeight="bold" color="textPrimary">
        Musculus
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        margin="3px 0"
        justifyContent="space-between"
        flexDirection="row"
      >
        <DifferentBox
          title="하지 근력"
          current={current.musculus}
          previous={previous.musculus}
        />
      </Box>
    </Box>
  );
}


const CompareRecord = ({ current, previous }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  //console.log(current, previous)
  const isNonMobile = useMediaQuery("(min-width:1366px)");

  return (
    <>
      {current && previous && (
        <Box
          display="grid"
          gap="10px"
          gridTemplateColumns="repeat(6, minmax(0, 1fr))"
          backgroundColor={colors.primary[400]}
          borderRadius="10px"
        >
          <Box
            /*borderRadius="10px"  border="1px solid white"*/ sx={{
              gridColumn: "span 6",
            }}
          >
            <Box>
              <Typography
                variant="h3"
                color={colors.greenAccent[400]}
                fontWeight="bold"
                m="18px 0"
              >
                기록 비교
              </Typography>
            </Box>

            <Box
              display="flex"
              alignItems="flex-start"
              margin="3px 0"
              sx={{
                width: isNonMobile ? "80%" : "100%",
                flexDirection: "column",
              }}
              justifyContent="space-between"
              flexDirection="column"
            >
              <Balance current={current.record} previous={previous.record} />
              <Box
                display="flex"
                flexDirection="row"
                alignItems="flex-start"
                sx={{
                  width: "100%",
                  justifyContent: isNonMobile ? "flex-start" : "space-around",
                }}
              >
                <Speed
                  current={current.record}
                  previous={previous.record}
                  sx={{ gridColumn: isNonMobile ? undefined : "span 2" }}
                />
                <Musculus
                  current={current.record}
                  previous={previous.record}
                  sx={{ gridColumn: isNonMobile ? undefined : "span 2" }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default CompareRecord;
