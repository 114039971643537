import { ResponsiveLine } from "@nivo/line";
import { useTheme, Box, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens, nivoTheme } from "../../../../../theme";
import { ResponsiveBar } from "@nivo/bar";

const MuscleBar = ({ record , type,title}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:1366px)");
  function createData() {
    const response = [];
    record.forEach((item) =>
      response.push({
        date: item.date.slice(2),
        score: item[type].score,
      })
    );
    return response;
  }

  return (
    <Box height="45vh" width="45vh">
      <Box>
        <Typography
          variant="h3"
          color={colors.greenAccent[400]}
          fontWeight="bold"
          m="25px 0"
        >
          {title} 점수 비교
        </Typography>
      </Box>
      <ResponsiveBar
        data={createData()}
        keys={["score"]}
        indexBy="date"
        margin={{ top: 50, right: 100, bottom: 100, left: 35 }}
        padding={0.3}
        theme={nivoTheme(colors)}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={{ scheme: "pastel1" }}
        borderColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 45,
          legend: "date",
          legendPosition: "middle",
          legendOffset: 70,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 10,
          tickRotation: 0,
          legend: "score",
          legendPosition: "middle",
          legendOffset: -40,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        legends={[
          {
            dataFrom: "keys",
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        role="application"
        ariaLabel="Nivo bar chart demo"
        barAriaLabel={function (e) {
          return (
            e.id + ": " + e.formattedValue + " in country: " + e.indexValue
          );
        }}
      />
    </Box>
  );
};


export default MuscleBar