import { ResponsiveBullet } from "@nivo/bullet";
import { useTheme, Box, Typography, useMediaQuery, Stack } from "@mui/material";
import { tokens, nivoTheme } from "../../../../theme";
import { getBMIColorForScore } from "../../../../utils/nutrition";



const BulletDetail = ({ record, sex, fullWidth }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:1366px)");
  const isNonTablet = useMediaQuery("(max-width:375px)");

  //console.log("### BulletDetail", fullWidth);

  const { muscle, cardio, fat, inferFlex, inferMuscle, supFlex, supMuscle } = record;

  function createFatRange(sex) {
    return sex === 0 ? [0, 80, 85, 90] : [0, 75, 80, 85];
  }

  const fatRange = createFatRange(sex);
  const data = createData(); 



  function createData() {
    const bullet = [
      {
        id: muscle.title,
        ranges: [0, 8, 12, 15],
        measures: [muscle.value === "통증" ? 0 : muscle.value],
        markers: [muscle.value === "통증" ? 0 : muscle.value],
      },
      {
        id: cardio.title,
        ranges: [0, 70, 85, 100],
        measures: [cardio.value === "통증" ? 0 : cardio.value],
        markers: [cardio.value === "통증" ? 0 : cardio.value],
      },
      {
        id: fat.title,
        ranges: fatRange,
        measures: [fat.value === "통증" ? 0 : fat.value],
        markers: [fat.value === "통증" ? 0 : fat.value],
      },
      {
        id: inferFlex.title,
        ranges: [0, 5, 10, 15],
        measures: [inferFlex.value === "통증" ? 0 : inferFlex.value],
        markers: [inferFlex.value === "통증" ? 0 : inferFlex.value],
      },
      {
        id: supFlex.title,
        ranges: [0, 25, 30, 35],
        measures: [supFlex.value === "통증" ? 0 : supFlex.value],
        markers: [supFlex.value === "통증" ? 0 : supFlex.value],
      },
      {
        id: inferMuscle.title,
        ranges: [0, 12, 15, 20],
        measures: [inferMuscle.value === "통증" ? 0 : inferMuscle.value],
        markers: [inferMuscle.value === "통증" ? 0 : inferMuscle.value],
      },
      {
        id: supMuscle.title,
        ranges: [0, 8, 12, 15],
        measures: [supMuscle.value === "통증" ? 0 : supMuscle.value],
        markers: [supMuscle.value === "통증" ? 0 : supMuscle.value],
      },
    ];
    return bullet;
  }

  //console.log("bmi", record.bmi);

  return (
    <>
      {data && (
        <>
          <Box
            height="120vh"
            width={
              fullWidth
                ? "80rem":isNonMobile?"80rem":isNonTablet?
                 "30rem":"40rem"
            }
            padding="20px"
          >
            <Typography
              variant="h3"
              color={colors.greenAccent[400]}
              fontWeight="bold"
              mb="15px"
            >
              세부 기록
            </Typography>

              <ResponsiveBullet
                data={data}
                theme={nivoTheme(colors)}
                margin={{
                  top: 50,
                  right: 90,
                  bottom: 50,
                  left: isNonMobile ? 100 : isNonTablet ? 3 : 90,
                }}
                spacing={50}
                titleAlign="start"
                titleOffsetX={isNonMobile ? -90 : isNonTablet ? 0 : -100}
                titleOffsetY={isNonTablet ? -40 : -5}
                measureSize={0.2}
                rangeColors="pastel1"
              />
            </Box>
        </>
      )}
    </>
  );
};

export default BulletDetail;
