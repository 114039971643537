import { Checkbox, FormControlLabel } from "@mui/material";
import { useField } from "formik";
import { useEffect } from "react";

const LabeledCheckBox = ({ label,setTextInputShow, ...rest }) => {
  const [field] = useField(rest);

  useEffect(()=>{
    setTextInputShow(!field.checked);
  },[field.checked])
  //console.log(field)
  return <FormControlLabel control={<Checkbox />} label={label} {...field} />;
};

export default LabeledCheckBox;
