import Header from "../Header"
import { Typography, Box, Button, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";

const SignUp = ({ handleBtnClick }) => {
  //console.log("회원가입")
  const navigation = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <>
      <Header title="회원 가입이 완료되었습니다." />
      <Button
        sx={{ mt: 1.5 }}
        color="secondary"
        variant="contained"
        onClick={(e) => {
          handleBtnClick();
        }}
      >
        로그인으로 이동
      </Button>
    </>
  );
};

export default SignUp