import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FirebaseApi } from "../firebase";

const initialState = {
  userInfo: {
    value: undefined,
    loadState: "idle",
  },
};


export const asyncGetUserInfo = createAsyncThunk(
  "user/getUserInfo",
  async ({ firebaseApi, userId }) => {
    return await firebaseApi.asyncGetUserInfo(userId);
  }
);

export const asyncSetUserInfo = createAsyncThunk(
  "user/setUserInfo",
  async ( {
    firebaseApi,
    userId,
    userInfo,
  }) => {
    return await firebaseApi.asyncSetUserInfo(
      userId,
      userInfo
    );
  }
);

export const asyncUpdateUserInfo = createAsyncThunk(
  "user/updateUserInfo",
  async ({
    firebaseApi,
    userId,
    userInfo,
  }) => {
    return await firebaseApi.asyncUpdateUserInfo(
      userId,
      userInfo
    );
  }
);


export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setUserInfo: (
      state,
      action
    ) => {
      state.userInfo.value = action.payload.value;
      state.userInfo.loadState = action.payload.loadState;
    },
  },
  extraReducers: (builder) => {
        builder
          .addCase(asyncGetUserInfo.pending, (state) => {
            state.userInfo.value = undefined;
            state.userInfo.loadState = "loading";
          })
          .addCase(asyncGetUserInfo.fulfilled, (state, action) => {
            state.userInfo.value = action.payload;
            state.userInfo.loadState = "idle";
          })
          .addCase(asyncGetUserInfo.rejected, (state, action) => {
            state.userInfo.value = undefined;
            state.userInfo.loadState = "failed";
          })
          .addCase(asyncSetUserInfo.pending, (state) => {
            state.userInfo.value = undefined;
            state.userInfo.loadState = "loading";
          })
          .addCase(asyncSetUserInfo.fulfilled, (state, action) => {
            state.userInfo.value = action.payload;
            state.userInfo.loadState = "idle";
          })
          .addCase(asyncSetUserInfo.rejected, (state) => {
            state.userInfo.value = undefined;
            state.userInfo.loadState = "failed";
          })
          .addCase(asyncUpdateUserInfo.pending, (state) => {
            state.userInfo.value = undefined;
            state.userInfo.loadState = "loading";
          })
          .addCase(asyncUpdateUserInfo.fulfilled, (state, action) => {
            state.userInfo.value = action.payload;
            state.userInfo.loadState = "idle";
          })
          .addCase(asyncUpdateUserInfo.rejected, (state) => {
            state.userInfo.value = undefined;
            state.userInfo.loadState = "failed";
          });
  },
});

export const { setUserId, setUserInfo } = userSlice.actions;
export const handleUserChange =
  (firebaseApi,userId) =>
  (dispatch, getState) => {
    if (userId === getState().user.userId) {
      return;
    }
    dispatch(setUserId(userId));
    dispatch(
      setUserInfo({
        value: undefined,
        loadState: "idle",
      })
    );
    if (userId === null || userId === undefined) {
      return;
    }
    dispatch(asyncGetUserInfo({ firebaseApi, userId }));
  };
  
export default userSlice.reducer;
