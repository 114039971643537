import { ResponsiveBullet } from "@nivo/bullet";
import { ResponsiveRadar } from "@nivo/radar";
import { useTheme, Box, Typography, useMediaQuery } from "@mui/material";
import { tokens, nivoTheme } from "../../../../theme";
import { useEffect, useState } from "react";
import { getFbSeniorById } from "../../../../apis/senior";
import { getFbRecordById } from "../../../../apis/sppb";
import UserInfo from "../../../userInfo";
import PngBtn from "../../../pngBtn";
import OverAll from "./overAll";
import BriefDetail from "./briefDetail";

const SingleReport = ({ id, name, recordId }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:1366px)");
  const isNonTablet = useMediaQuery("(max-width:500px)");
  const [fullWidth, setFullWidth] = useState(false);
  const [senior, setSenior] = useState(null);
  const [record, setRecord] = useState([]);

  useEffect(() => {
    async function fetchSeniorData() {
      const response = await getFbSeniorById(id);
      setSenior(response);
    }
    fetchSeniorData();
  }, [id]);

  useEffect(() => {
    async function fetchRecordData() {
      const response = await getFbRecordById(id, recordId[0]);
      const radar = [
        {
          id: `균형 평가`,
          score: response.balance.score,
          value: response.balance.value,
        },
        {
          id: `보행 속도`,
          score: response.speed.score,
          value: response.speed.value,
        },
        {
          id: `하지 근력`,
          score: response.musculus.score,
          value: response.musculus.value,
        },
      ];
      const bullet = [
        {
          id: "일반 자세",
          ranges: [0, 10],
          measures: [response.record.side_by_side],
          markers: [response.record.side_by_side],
        },
        {
          id: "반일렬 자세",
          ranges: [0, 10],
          measures: [response.record.semi_tandem],
          markers: [response.record.semi_tandem],
        },
        {
          id: `일렬 자세`,
          ranges: [0, 3, 10],
          measures: [response.record.tendem],
          markers: [response.record.tendem],
        },
        {
          id: `보행 속도`,
          ranges: [0, 4.82, 6.21, 8.7],
          measures: [response.record.gait_speed],
          markers: [response.record.gait_speed],
        },
        {
          id: `하지 근력`,
          ranges: [0, 11.19, 16.7, 60],
          measures: [4],
          markers: [4],
        },
      ];
      const data = {
        radar: radar,
        bullet: bullet,
        date: response.date,
        overAll: response.overAll,
        balance: response.balance,
        speed: response.speed,
        musculus: response.musculus,
      };
      setRecord(data);
    }
    fetchRecordData();
  }, [recordId]);

  function setReportWidth(state) {
    setFullWidth(state);
  }

  return (
    <Box m="20px">
      <Box
        className="PdfArea"
        display="grid"
        gap="10px"
        gridTemplateColumns="repeat(3, minmax(0, 1fr))"
        backgroundColor={colors.primary[400]}
        borderRadius="10px"
        sx={{
          width: isNonMobile
            ? "auto"
            : isNonTablet
            ? fullWidth
              ? "1366px !important"
              : "500px"
            : fullWidth
            ? "1366px !important"
            : undefined,
          "& > div": {
            gridColumn: isNonMobile
              ? undefined
              : fullWidth
              ? undefined
              : "span 3",
          }
        }}
      >
        <Box m="0 20px" p="20px" sx={{ gridColumn: "span 3" }}>
          {senior && record && <UserInfo user={senior} date={record.date} fullWidth={fullWidth}/>}
        </Box>
        <Box m="0 20px" p="20px" sx={{ gridColumn: "span 1" }}>
          {record.radar && (
            <Box height="45vh" width="40vh">
              <Typography
                variant="h3"
                color={colors.greenAccent[400]}
                fontWeight="bold"
                mb="15px"
              >
                전체 점수
              </Typography>
              <ResponsiveRadar
                data={record.radar}
                keys={["score"]}
                theme={nivoTheme(colors)}
                indexBy="id"
                valueFormat=">-.2f"
                margin={{ top: 50, right: 80, bottom: 50, left: 80 }}
                borderColor={{ from: "color" }}
                gridLabelOffset={10}
                dotSize={3}
                dotColor={{ theme: "background" }}
                dotBorderWidth={2}
                colors={{ scheme: "pastel1" }}
                fillOpacity={0.9}
                blendMode="multiply"
                motionConfig="wobbly"
                legends={[
                  {
                    anchor: "top-left",
                    direction: "column",
                    translateX: -50,
                    translateY: -20,
                    itemWidth: 80,
                    itemHeight: 20,
                    itemTextColor: colors.grey[100],
                    symbolSize: 12,
                    symbolShape: "circle",
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemTextColor: colors.grey[100],
                        },
                      },
                    ],
                  },
                ]}
              />
            </Box>
          )}
        </Box>
        <Box m="0 20px" p="20px" sx={{ gridColumn: "span 1" }}>
          {senior && record && <OverAll user={senior} data={record.overAll} />}
        </Box>
        <Box m="0 20px" p="20px" sx={{ gridColumn: "span 1" }}>
          {senior && record && (
            <BriefDetail
              user={senior}
              balance={record.balance}
              speed={record.speed}
              musculus={record.musculus}
            />
          )}
        </Box>
        <Box m="20px" p="20px" sx={{ gridColumn: "span 3" }}>
          {record.bullet && (
            <Box
              height="60vh"
            >
              <Typography
                variant="h3"
                color={colors.greenAccent[400]}
                fontWeight="bold"
                mb="15px"
              >
                세부 기록
              </Typography>
              <ResponsiveBullet
                data={record.bullet}
                theme={nivoTheme(colors)}
                margin={{ top: 5, right: 90, bottom: 50, left: 90 }}
                spacing={46}
                titleAlign="start"
                titleOffsetX={-70}
                measureSize={0.2}
                rangeColors="pastel1"
              />
            </Box>
          )}
        </Box>
      </Box>
      <PngBtn setReportWidth={setReportWidth} />
    </Box>
  );
};

export default SingleReport;
