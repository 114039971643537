import { useTheme, Box, Typography, Stack } from "@mui/material";
import { tokens } from "../../../../../theme";
import {getMusculusAndSpeedColorForScore} from "../../../../../utils"

export const Balance = ({record}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box>
      <Typography
        variant="h3"
        color={colors.greenAccent[400]}
        fontWeight="bold"
        m="18px 0"
      >
        균형 평가 기록 요약
      </Typography>
      <Box
        display="flex"
        width="100%"
        margin="3px 0"
        flexDirection="column"
        justifyContent="space-between"
      >
        {record.map((item) => (
          <Box
            display="flex"
            margin="8px 0"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
            key={item.date}
          >
            <Typography variant="h4" fontWeight="bold" color="textPrimary">
              {item.date}
            </Typography>
            <Stack direction="column" width="65%" marginBottom="8px">
              <Stack direction="row" spacing={1} marginBottom="8px">
                <Box
                  backgroundColor={getMusculusAndSpeedColorForScore(
                    colors,
                    item.balance.value
                  )}
                  padding="5px 10px"
                  borderRadius="10px"
                  color="white"
                  fontWeight="bold"
                >
                  {item.balance.score}점 / 4점
                </Box>
                <Box
                  backgroundColor={getMusculusAndSpeedColorForScore(
                    colors,
                    item.balance.value
                  )}
                  padding="5px 10px"
                  borderRadius="10px"
                  color="white"
                  fontWeight="bold"
                >
                  {item.balance.value}
                </Box>
              </Stack>
              <Stack direction="row" spacing={3}>
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  color="textPrimary"
                >
                  {" "}
                  일반 : {item.record.side_by_side || 0}초
                </Typography>
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  color="textPrimary"
                >
                  {" "}
                  반 일렬 : {item.record.semi_tandem || 0}초
                </Typography>
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  color="textPrimary"
                >
                  일렬 : {item.record.tendem || 0}초
                </Typography>
              </Stack>
            </Stack>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
