/*
- 일반 자세 = 0 → 반일렬 & 일렬 모두 측정 X
- 일반 자세 = 1
    - → 반일렬 = 0 → 일렬 자세 측정 X
    - → 반일렬 = 1
        - → 일렬 자세 측정 = 2
        - → 일렬 자세 측정 = 1
        - → 일렬 자세 측정 = 0

"side_by_side": 0, - 일반 자세
"semi_tandem": 10, - 반일렬 자세
"tandem": 100, - 일렬 자세 
"gait_speed": 10, - 보행속도 검사
"musculus": 10 - 하지근력 검사
*/

import { useTheme } from "@mui/material";
import { tokens } from "../theme";


export function side_by_side_State(side_by_side) {
  const state = side_by_side >= 10 ? true : false;
  return state
}

export function semi_tandem_State(semi_tandem) {
  const state = semi_tandem >= 10 ? true : false;
  return state;
}

export const Value = ["양호", "보통", "미흡"];

export function balanceGrade(side_by_side, semi_tandem = 0, tadem = 0) {
  let balance = {
    tadem: 0,
    semi_tandem: 0,
    side_by_side: 0,
    total: 0,
    value: "미흡",
  };
  if (side_by_side_State(side_by_side)) {
    balance["side_by_side"] = 1;
    if (semi_tandem_State(semi_tandem)) {
      balance["semi_tandem"] = 1;
      //console.log(tadem);
      if (3 <= tadem && tadem < 10) {
        balance["tadem"] = 1;
        balance["value"] = "보통";
      } else if (10 <= tadem) {
        balance["tadem"] = 2;
        balance["value"] = "양호";
      }
    }
  }

  balance["total"] =
    balance["tadem"] + balance["semi_tandem"] + balance["side_by_side"];
  return { score: balance["total"], value: balance["value"] };
}


/**
 * 
평소 때의 속도로 걸으라고 지시하고 수행하지 못하였을 경우는 0점
8.7초 이상 소요되었을 경우는 1점
6.21∼8.7초는 2점 
4.82∼6.20초는 3점 
4.82초 이내의 경우는 4점 
 * 
 */

export function speedGrade(gait_speed) {
  let speed = { score: 0, value: "" };
  if (8.7 <= gait_speed) {
    speed["score"] = 4;
    speed["value"] = "양호";
  } else if (6.21 <= gait_speed && gait_speed < 8.7) {
    speed["score"] = 3;
    speed["value"] = "보통";
  } else {
    speed["value"] = "미흡";
    if (4.82 <= gait_speed && gait_speed < 6.21) {
      speed["score"] = 2;
    } else if (gait_speed && gait_speed < 4.82) {
      speed["score"] = 1;
    }
  }
  return speed;
}



/**
60초 이상 소요되거나 하지 못하면 0점
16.7초 이상이면 1점
13.7∼16.69초면 2점
11.2∼13.69초면 3점
11.19초 이내에 시행하면 4점 
 * 
 */


export function musculusGrade(musculus_strength) {
  let musculus = { score: 0, value: "" };
  if (0<musculus_strength && musculus_strength < 11.2) {
    musculus["score"] = 4;
    musculus["value"] = "양호";
  } else if (11.2 <= musculus_strength && musculus_strength < 13.7) {
    musculus["score"] = 3;
    musculus["value"] = "보통";
  } else {
    musculus["value"] = "미흡";
    if (13.7 <= musculus_strength && musculus_strength < 16.7) {
      musculus["score"] = 2;
    } else if (16.7 <= musculus_strength && musculus_strength < 60) {
      musculus["score"] = 1;
    } else if (60 <= musculus_strength) {
      musculus["score"] = 0;
    }
  }

  return musculus;
}


export function getScore(item){
    const balance = balanceGrade(
      item["side_by_side"],
      item["semi_tandem"],
      item["tandem"]
    );
    const speed = speedGrade(item["gait_speed"]);
    const musculus = musculusGrade(item["musculus"]);
    const report = {
      balance,
      speed,
      musculus,
    };
    return report
}

export const ovarAllValue = ["양호", "주의1", "주의2", "주의3","주의4","미흡"]
export const overAllDescription = [
  "신체기능이 전반적으로 양호합니다. 지금처럼 노리케어 선생님과 운동과 영양으로 꾸준히 건강을 관리해주세요.",
  "매우 큰 문제는 없으나 노쇠를 주의해야 합니다. 더 나빠지지 않게 노리케어 선생님과 꾸준히 건강을 관리해주세요.",
  "근기능과 보행 안정성이 많이 약해져서 노쇠를 주의해야 합니다. 더 나빠지지 않게 노리케어 선생님과 체계적인 관리가 필요합니다.",
  "근기능과 평형력, 보행 안정성이 많이 부족합니다. 노쇠 정도가 심한 편 입니다. 더 나빠지지 않게 의사의 진단 및 노리케어 선생님과 체계적인 관리가 필요합니다.",
  "근기능과 평형력, 보행 안정성이 매우 부족합니다. 노쇠 정도가 매우 심한 편 입니다. 의사의 진단이 필요하며, 노리케어 선생님과의 체계적인 관리가 필수적입니다.",
  "근기능과 평형력, 보행 안정성이 매우 부족합니다. 노쇠 정도가 매우 심한 편 입니다. 의사의 진단이 필요합니다.",
];

export function getOverallScore(item){
  //console.log(item)
  const {balance, speed, musculus} = item;
  //console.log(balance)
  let overall = { score: balance.score+ speed.score+ musculus.score, value: "" ,description:""};

  if (overall.score===12){
     overall.value = ovarAllValue[0];
     overall.description = overAllDescription[0];
  }
  else if (10<=overall.score && overall.score<=11){
     overall.value = ovarAllValue[1];
     overall.description = overAllDescription[1];
  }
  else if ((8<=overall.score) && (overall.score<=9)){
     overall.value = ovarAllValue[2];
     overall.description = overAllDescription[2];
  }
  else if ((6<=overall.score) && (overall.score<=7)){
     overall.value = ovarAllValue[3];
     overall.description = overAllDescription[3];
  }
  else if ((4<=overall.score) && (overall.score<=5)){
     overall.value = ovarAllValue[4];
     overall.description = overAllDescription[4];
  }
  else{
    overall.value = ovarAllValue[5];
    overall.description = overAllDescription[5];
  }
  return {score:overall.score, value:overall.value, description:overall.description}
}



export const getOverallColorForScore = (colors, data) => {


  if (data === ovarAllValue[0]) {
    return colors.greenAccent[500];
  } else if (data === ovarAllValue[1]) {
    return colors.redAccent[800];
  } else if (data === ovarAllValue[2]) {
    return colors.redAccent[700];
  } else if (data === ovarAllValue[3]) {
    return colors.redAccent[600];
  } else if (data === ovarAllValue[4]) {
    return colors.redAccent[500];
  } else if (data === ovarAllValue[5]) {
    return colors.redAccent[400];
  } else {
    return colors.redAccent[300];
  }
};


export const getMusculusAndSpeedColorForScore = (colors, data) => {


  if (data === Value[0]) {
    return colors.greenAccent[500];
  } else if (data === Value[1]) {
    return colors.blueAccent[800];
  } else if (data === Value[2]) {
    return colors.redAccent[500];
  }
};