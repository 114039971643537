import { tokens } from "../../../../theme";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
} from "@mui/lab";
import { Typography, useTheme, Stack, Box } from "@mui/material";
import LocalDiningIcon from "@mui/icons-material/LocalDining";


const TimeLine=({record,date})=>{
  console.log(date,record)
  return (date && record &&
    <TimelineItem>
      <TimelineOppositeContent
        sx={{ m: "auto 0" }}
        align="right"
        variant="body2"
        color="text.secondary"
      >
        {record.score}점 / {record.total}점
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineConnector />
        <TimelineDot>
          <LocalDiningIcon />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ py: "12px", px: 2 }}>
        <Typography variant="h6" component="span">
          {date}
        </Typography>
        <Typography> {record.value}</Typography>
      </TimelineContent>
    </TimelineItem>
  );
}

const QuestionTimeline = ({record,type,title}) => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  
  return (
    <>
      <Typography
        variant="h3"
        color={colors.greenAccent[400]}
        fontWeight="bold"
        m="18px 0"
      >
       {title}
      </Typography>
      <Timeline>
        {record.map((item) => (
          <TimeLine record={item[type]} date={item.date} />
        ))}
      </Timeline>
    </>
  );
};

export default QuestionTimeline;
