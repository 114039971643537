import { Typography, Stack, TextField, Button, useTheme ,Box} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {  withFirebaseApi } from "../../firebase";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { asyncSetUserInfo } from "../../redux/userSlice";
import { tokens } from "../../theme";
import Header from "../../components/Header"

const Onboarding = (props) => {
  const userId = useAppSelector((state) => state.user.userId);
  const dispatch = useAppDispatch();
  const [username, setUsername] = useState("");
  const [file, setFile] = useState(null);
  const navigation = useNavigate();
  //const userInfo = useAppSelector((state) => state.user.userInfo.value);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  let selectedProfilePic = null;
  if (file !== null) {
    selectedProfilePic = <img src={URL.createObjectURL(file)} width={200} />;
  }
  return (
    <Box m="20px">
      <Header title="Set My Information" subtitle="Complete My Information" />
      <Stack direction="row" spacing={2}>
        {/*<Typography
          variant="body1"
          align="left"
          sx={{ marginTop: "auto", marginBottom: "auto" }}
        >
          Username:
  </Typography>*/}
        <Typography
          variant="h4"
          color={colors.primary[100]}
          fontWeight="bold"
          m="18px 0"
          align="left"
        >
          Username:
        </Typography>
        <TextField
          value={username}
          label="Username"
          variant="filled"
          onChange={(e) => setUsername(e.target.value)}
        />
      </Stack>

      <Stack direction="column" spacing={2}>
        <Typography
          variant="h4"
          color={colors.primary[100]}
          fontWeight="bold"
          m="18px 0"
          align="left"
        >
          Profile Image:
        </Typography>
        {selectedProfilePic}
        <Stack direction="row" spacing={5}>
          <Button variant="contained" component="label">
            Upload
            <input
              hidden
              accept="image/*"
              onChange={(e) => {
                const files = e.target.files;
                if (files == null || files.length === 0) {
                  setFile(null);
                } else {
                  setFile(files[0]);
                }
              }}
              type="file"
            />
          </Button>
          <Button
            variant="contained"
            sx={{ marginTop: 2 }}
            onClick={async () => {
              if (file!=null){
                const handle = await props.firebaseApi.asyncUploadImage(
                  userId,
                  file
                );
                dispatch(
                  asyncSetUserInfo({
                    firebaseApi: props.firebaseApi,
                    userId: userId,
                    userInfo: {
                      username: username,
                      profilePicHandle: handle,
                    },
                  })
                );
              }
              else{
                  dispatch(
                    asyncSetUserInfo({
                      firebaseApi: props.firebaseApi,
                      userId: userId,
                      userInfo: {
                        username: username,
                        profilePicHandle: null,
                      },
                    })
                  );
              }

              navigation("/");
            }}
            disabled={/*file === null || */username.length === 0}
          >
            SUBMIT
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default withFirebaseApi(Onboarding);