import { useEffect, useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme , Button} from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import { useAppSelector } from "../../redux/hooks";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { withFirebaseApi /*FirebaseApi as firebaseApi*/ } from "../../firebase";
import DefaultImg from "../../assets/noricare.png"

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      /*onClick={() => setSelected(title)}*/
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [profileImg, setProfileImg] = useState(null);
  const [selected, setSelected] = useState("Dashboard");
  const userId = useAppSelector((state) => state.user.userId);
  const userInfo = useAppSelector((state) => state.user.userInfo.value);
  //console.log(props)

  async function getProfileUrl(props) {
    //console.log(userInfo.profilePicHandle);
    if (userInfo.profilePicHandle == null) {
      setProfileImg(DefaultImg);
      return "../../assets/noricare.png";
    } else {
      try{
        const response = await  props.firebaseApi.asyncGetURLFromHandle(
          userInfo.profilePicHandle
        );
       setProfileImg(response || DefaultImg);
       //console.log(response)
        return response;
      }catch(err){
       return
      }
       
    }
  }
  useEffect(()=>{
    if(userInfo){
        getProfileUrl(props);
    }
  },[userInfo])

    const logoutButton = (
      <Button
        size="large"
        sx={{ gridColumn: "span 1" }}
        onClick={props.firebaseApi.signOut}
      >
        Log out
      </Button>
    );
    const button = userId == null ? null : logoutButton;


  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
        "& .css-1ny7bi9-MuiButtonBase-root-MuiButton-root": {
          color: `${colors.primary[400]} !important`,
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                  Noricare
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                {userInfo && (
                  <img
                    alt="profile-user"
                    width="100px"
                    height="100px"
                    /*src={profileImg}*/
                    src={profileImg}
                    style={{ cursor: "pointer", borderRadius: "50%" }}
                  />
                )}
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0" }}
                >
                  {userInfo && userInfo.username}
                </Typography>
                {/*<Typography variant="h5" color={colors.greenAccent[500]}>
                  {userId && userId}
                </Typography>*/}
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>


            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              회원
            </Typography>
 
            <Item
              title="회원등록"
              to="/register?type=senior"
              icon={<ContactsOutlinedIcon />}
              /*selected={selected}
              setSelected={setSelected}*/
            />
            <Item
              title="회원검색"
              to="/record?type=senior"
              icon={<ReceiptOutlinedIcon />}
             /* selected={selected}
              setSelected={setSelected}*/
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              노쇠 검사
            </Typography>
            {/*<Item
              title="Search Record"
              to="/form"
              icon={<PersonOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />*/}
            <Item
              title="노쇠 검사 진행"
              to="/register?type=exercise"
              icon={<CalendarTodayOutlinedIcon />}
              /*selected={selected}
              setSelected={setSelected}*/
            />
            <Item
              title="노쇠 검사 보고서"
              to="/record?type=exercise"
              icon={<ReceiptOutlinedIcon />}
              /*selected={selected}
              setSelected={setSelected}*/
            />
            {/* <Item
              title="Report Info"
              to="/faq"
              icon={<HelpOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
          />*/}
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              영양 평가
            </Typography>
            {/*<Item
              title="Search Record"
              to="/form"
              icon={<PersonOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />*/}
            <Item
              title="영양 평가 진행"
              to="/register?type=nutrition"
              icon={<CalendarTodayOutlinedIcon />}
              /*selected={selected}
              setSelected={setSelected}*/
            />
            <Item
              title="영양 평가 보고서"
              to="/record?type=nutrition"
              icon={<ReceiptOutlinedIcon />}
              /*selected={selected}
              setSelected={setSelected}*/
            />
            {/*<Item
              title="nutrition Info"
              to="/faq"
              icon={<HelpOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
          />*/}

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              체력요소 평가
            </Typography>
            {/*<Item
              title="Search Record"
              to="/form"
              icon={<PersonOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />*/}
            <Item
              title="체력 요소 평가 진행"
              to="/register?type=fitness"
              icon={<CalendarTodayOutlinedIcon />}
              /*selected={selected}
              setSelected={setSelected}*/
            />
            <Item
              title="체력요소 평가 보고서"
              to="/record?type=fitness"
              icon={<ReceiptOutlinedIcon />}
              /*selected={selected}
              setSelected={setSelected}*/
            />
            {/*<Item
              title="nutrition Info"
              to="/faq"
              icon={<HelpOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
          />*/}

            {userInfo && (
              <>
                <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  sx={{ m: "15px 0 5px 20px" }}
                >
                  마이페이지
                </Typography>
                <Item
                  title="내 정보"
                  to="/profile"
                  icon={<PeopleOutlinedIcon />}
                  /*selected={selected}
                  setSelected={setSelected}*/
                />
              </>
            )}
            {button}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default withFirebaseApi(Sidebar);
