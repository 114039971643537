import { Box, Button, useTheme, useMediaQuery } from "@mui/material";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import ElderlyIcon from "@mui/icons-material/Elderly";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { tokens } from "../../../theme";
import { useNavigate } from "react-router-dom";
import StatBox from "../../StateCard";
import { getScore, getOverAllData } from "../../../utils/fitness";
import { AddFitnessById } from "../../../apis/fitness";


/**
cardio: ['-1']
date:"2023-02-26"
fat: 3
inferFlex:['-1']
inferMuscle: ['-1']
muscle: 3
supFlex: ['-1']
supMuscle: ['-1']
 */

const ShowScore = ({ item,sex, setShowGrade }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:1366px)");
  const navigate = useNavigate();

  const { cardio, fat, inferFlex, inferMuscle, muscle, supFlex, supMuscle } =
    getScore(item, sex);
  const overAll = getOverAllData({
    cardio,
    fat,
    inferFlex,
    inferMuscle,
    muscle,
    supFlex,
    supMuscle,
  });


const handleSubmitBtn = async (
  e,
    cardio, fat, inferFlex, inferMuscle, muscle, supFlex, supMuscle 

) => {

  //console.log(supMuscle, inferMuscle, supFlex, inferFlex, cardio, fat, muscle);
  //console.log("handleSubmitBtn", cardio, fat);
  const data = {
    senior: {
      id: item.id,
      name: item.name,
    },
    date: item.date,
    cardio, fat, inferFlex, inferMuscle, muscle, supFlex, supMuscle ,
    overAll,
  };
  //console.log(data)
  const response = await AddFitnessById(item.id, data);
  setShowGrade(null);
  navigate("/complete?type=fitness");
};

  return (
    <>
      <Box
        display="grid"
        mt="30px"
        gap="30px"
        gridTemplateColumns="repeat(10, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 5" },
        }}
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={supMuscle.value}
            subtitle={supMuscle.title}
            progress={supMuscle.score / supMuscle.total}
            content={supMuscle.score}
            icon={
              <AccessibilityIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={inferMuscle.value}
            subtitle={inferMuscle.title}
            progress={inferMuscle.score / inferMuscle.total}
            content={inferMuscle.score}
            icon={
              <AccessibilityIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={supFlex.value}
            subtitle={supFlex.title}
            progress={supFlex.score / supFlex.total}
            content={supFlex.score}
            icon={
              <AccessibilityIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={inferFlex.value}
            subtitle={inferFlex.title}
            progress={inferFlex.score / inferFlex.total}
            content={inferFlex.score}
            icon={
              <AccessibilityIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={cardio.value}
            subtitle={cardio.title}
            progress={cardio.score / cardio.total}
            content={cardio.score}
            icon={
              <AccessibilityIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={fat.value}
            subtitle={fat.title}
            progress={fat.score / fat.total}
            content={fat.score}
            icon={
              <AccessibilityIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={muscle.value}
            subtitle={muscle.title}
            progress={muscle.score / muscle.total}
            content={muscle.score}
            icon={
              <AccessibilityIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={overAll.value}
            subtitle={overAll.step}
            progress={overAll.score / overAll.total}
            content={overAll.score}
            icon={
              <AccessibilityIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
      </Box>
      <Box display="flex" justifyContent="end" m="20px 0">
        <Button
          color="secondary"
          variant="contained"
          onClick={(e) =>
            handleSubmitBtn(e, 
              cardio,
              fat,
              inferFlex,
              inferMuscle,
              muscle,
              supFlex,
              supMuscle,
            )
          }
        >
          Submit Test Result
        </Button>
      </Box>
    </>
  );
};


export default ShowScore;