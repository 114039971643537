import { ResponsiveBullet } from "@nivo/bullet";
import { useTheme, Box, Typography, useMediaQuery,Stack } from "@mui/material";
import { tokens, nivoTheme } from "../../../../theme";
import {getBMIColorForScore} from "../../../../utils/nutrition"

function createData(bmi){
  const bullet = [
    {
      id: bmi.sub,
      ranges:[0,19,21,23],
      measures: [bmi.value],
      markers: [bmi.value],
    },
  ];
  return bullet
}

const BMI = ({record, fullWidth}) => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:1366px)");
  const isNonTablet = useMediaQuery("(max-width:500px)");
  const data  = createData(record.bmi)

  //console.log("bmi", record.bmi);

  return (
    <>
      {data && (
        <><Box height={isNonMobile?"15vh":"20vh"} paddingBottom="35px">
          <Typography
            variant="h3"
            color={colors.greenAccent[400]}
            fontWeight="bold"
            mb="15px"
          >
            {record.bmi.sub}
          </Typography>
          <ResponsiveBullet
            data={data}
            theme={nivoTheme(colors)}
            margin={{ top: 5, right: 90, bottom: 50, left: 90 }}
            spacing={46}
            titleAlign="start"
            titleOffsetX={-70}
            measureSize={0.2}
            rangeColors="pastel1" />

        </Box>
        <Stack direction="row" spacing={3} alignItems="center" paddingLeft={2}>
            <Box
              backgroundColor={getBMIColorForScore(colors, record.bmi.score)}
              padding="5px 10px"
              borderRadius="10px"
              color="white"
              fontWeight="bold"
            >
              {" "}
              {record.bmi.value}
            </Box>
            <Box
              backgroundColor={getBMIColorForScore(colors, record.bmi.score)}
              padding="5px 10px"
              borderRadius="10px"
              color="white"
              fontWeight="bold"
            >
              {" "}
              {record.bmi.score}점 / {record.bmi.total}점
            </Box>
          </Stack>
          
        </>
      )}
    </>
  );
};


export default BMI;