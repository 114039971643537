export const Questions = [
  {
    q: "지난 3개월 동안 밥맛이 없거나, 소화가 잘 안되거나, 씹고 삼키는 것이 어려워서 식사량이 줄었습니까?",
    sub: "식사량 변화",
    ans: [
      {
        name: "q1",
        label: "변화없다",
        value: 2,
      },
      {
        name: "q1",
        label: "어느 정도 줄었다",
        value: 1,
      },
      {
        name: "q1",
        label: "많이 줄었다.",
        value: 0,
      },
    ],
    total: 2,
  },
  {
    q: "지난 3 개월 동안 몸무게가 줄었습니까?",
    sub: "몸무게 변화",
    ans: [
      {
        name: "q2",
        label: "체중감소 없음",
        value: 3,
      },
      {
        name: "q2",
        label: "모르겠다",
        value: 2,
      },
      {
        name: "q2",
        label: "1-3kg 감소",
        value: 1,
      },
      {
        name: "q2",
        label: "3kg 이상 감소",
        value: 0,
      },
    ],
    total: 3,
  },
  {
    q: "지난 3 개월 동안 정신적 스트레스를 경험했거나 급성 질환을 앓았던 적이 있습니까?",
    sub: "정신 및 신체 건강",
    ans: [
      {
        name: "q3",
        label: "아니요",
        value: 2,
      },
      {
        name: "q3",
        label: "예",
        value: 0,
      },
    ],
    total: 2,
  },
  {
    q: "거동 능력",
    sub: "거동 능력",
    ans: [
      {
        name: "q4",
        label: "외출 가능, 활동제약 없음",
        value: 2,
      },
      {
        name: "q4",
        label: "외출 불가, 집에서만 활동가능",
        value: 1,
      },
      {
        name: "q4",
        label: "외출불가, 침대나 의자에서만 생활 가능",
        value: 0,
      },
    ],
    total: 2,
  },
  {
    q: "신경 정신적 문제",
    sub: "신경 정신 건강",
    ans: [
      {
        name: "q5",
        label: "없음",
        value: 2,
      },
      {
        name: "q5",
        label: "경증 치매",
        value: 1,
      },
      {
        name: "q5",
        label: "중증 치매 또는 우울증",
        value: 0,
      },
    ],
    total: 2,
  },
];


export function getBMIScore (BMI){

  let score;

  if (23<=BMI){
    score=3
  }
  else if(21<=BMI && BMI<23){
     score = 2;
  }
  else if (19 <= BMI && BMI < 21) {
     score = 1;
  } else {
     score = 0;
  }

  return score
}

export function getBMIColorForScore (colors,BMI){
 //console.log(BMI)

    if (BMI===3){
      return colors.greenAccent[500];
    }
    else if (BMI === 2) {
      return colors.blueAccent[800];
    } else if (BMI === 1) {
      return colors.redAccent[500];
    } else {
      return colors.redAccent[100];
    }

}

export function getQ3LabelMean(label){
 // 지난 3 개월 동안 정신적 스트레스를 경험했거나 급성 질환을 앓았던 적이 있습니까?
  if(label==="아니요"){
    return "최근 정신적 스트레스 및 급성 질환 없음"
  }
  else{
    return "최근 정신적 스트레스 및 급성 질환 있음";
  }

}

function findLabelByValue(question,value){
  const result =question.ans.filter((a)=>(
      a.value === value )).map(item=>item.label).toString()
  return result
}

function  findSubByValue(question){
    const result = question.sub
    return result;
}

function findTotalByValue(question) {
  const result = question.total;
  return result;
}

export function getScore ({q1,q2,q3,q4,q5,bmi}){

  const q1Data = {
    value: findLabelByValue(Questions[0], q1),
    score: q1,
    sub: findSubByValue(Questions[0]),
    total: findTotalByValue(Questions[0]),
  };
  const q2Data = {
    value: findLabelByValue(Questions[1], q2),
    score: q2,
    sub: findSubByValue(Questions[1]),
    total: findTotalByValue(Questions[1]),
  };
  const q3Data = {
    value: findLabelByValue(Questions[2], q3),
    score: q3,
    sub: findSubByValue(Questions[2]),
    total: findTotalByValue(Questions[2]),
  };
  const q4Data = {
    value: findLabelByValue(Questions[3], q4),
    score: q4,
    sub: findSubByValue(Questions[3]),
    total: findTotalByValue(Questions[3]),
  };
  const q5Data = {
    value: findLabelByValue(Questions[4], q5),
    score: q5,
    sub: findSubByValue(Questions[4]),
    total: findTotalByValue(Questions[4]),
  };

  const BMIData = {
    value: bmi,
    score: getBMIScore(bmi),
    sub: "BMI 지수",
    total:3
  };

  const data = {
    q1Data,
    q2Data,
    q3Data,
    q4Data,
    q5Data,
    BMIData,
  };
//console.log(data)

 return data


}

const overAllData = [
  { value: "양호", step: "정상" },
  { value: "주의", step: "영양불량 위험" },
  { value: "미흡", step: "영양불량" },
];

export function getOverAllScore(results){
  let score = 0
  const keys = Object.keys(results); // [ 'a', 'b', 'c', '9', 'e' ]
  for (let i = 0; i < keys.length; i++) {
    const k = keys[i];
    const v = results[k].score;
    score+=v
  }
  return score
};


export function getOverAllValue(score){

  let value;
  let step;

  if (0 <= score && score <= 7) {
    value = overAllData[2].value;
    step = overAllData[2].step;
  } else if (8 <= score && score <= 11) {
    value = overAllData[1].value;
    step = overAllData[1].step;
  } else if (12 <= score && score <= 14) {
    value = overAllData[0].value;
    step = overAllData[0].step;
  } 

  return {value, step}

}

export function getOverAllData(results){
  const score = getOverAllScore(results);
  const {value, step} = getOverAllValue(score);
  const data = { score, value, step ,total:14};
  return data
}

export function getOverAllColorByValue(colors,value){

  //console.log(value, overAllData[0].value)

  if (value === overAllData[0].value) {
    return colors.greenAccent[300];
  } else if (value === overAllData[1].value) {
    return colors.redAccent[700];
  } else if (value === overAllData[2].value) {
    return colors.redAccent[500];
  }
  else  {
    return colors.grey[800]
  }

}

const CompareResult = ["저하", "유지","향상"]

export function getCompareResult(current, previous){
  if (current<previous){
    return CompareResult[0];
  }
  else if (current===previous){
    return CompareResult[1];
  }
  else if (current>previous){
    return CompareResult[2];
  }
}

export function getCompareResultColor(colors, result) {
  if (result === CompareResult[0]){
    return colors.redAccent[500]

  }
  else if (result === CompareResult[1]) {
    return colors.greenAccent[400];

  }
  else if (result === CompareResult[2]) {
 return colors.greenAccent[300];
  }
}