import { ResponsiveBullet } from "@nivo/bullet";
import { ResponsiveRadar } from "@nivo/radar";
import { useTheme, Box, Typography, useMediaQuery } from "@mui/material";
import { tokens, nivoTheme } from "../../../../theme";
import { useEffect, useState } from "react";

const Radiar = ({ record }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:1366px)");
    const isNonTablet = useMediaQuery("(max-width:375px)");

  function createData (){
    
    const data = [
      {
        id: record.muscle.title,
        score: record.muscle.score,
      },
      {
        id: record.cardio.title,
        score: record.cardio.score,
      },
      {
        id: record.fat.title,
        score: record.fat.score,
      },
      {
        id: record.inferFlex.title,
        score: record.inferFlex.score,
      },
      {
        id: record.inferMuscle.title,
        score: record.inferMuscle.score,
      },
      {
        id: record.supFlex.title,
        score: record.supFlex.score,
      },
      {
        id: record.supMuscle.title,
        score: record.supMuscle.score,
      },
    ];
    //console.log(data);
    return data
  }

  return (
    <Box height="45vh" /*width={isNonMobile?"35vh":isNonTablet?"30vh":"45vh"}*/ width="40vh">
      <Typography
        variant="h3"
        color={colors.greenAccent[400]}
        fontWeight="bold"
        mb="15px"
      >
        전체 점수
      </Typography>
      <ResponsiveRadar
        data={createData()}
        keys={["score"]}
        theme={nivoTheme(colors)}
        indexBy="id"
        valueFormat=">-.2f"
        margin={{ top: 50, right: 80, bottom: 50, left: 80 }}
        borderColor={{ from: "color" }}
        gridLabelOffset={20}
        dotSize={3}
        dotColor={{ theme: "background" }}
        dotBorderWidth={2}
        colors={{ scheme: "pastel1" }}
        fillOpacity={0.9}
        blendMode="multiply"
        motionConfig="wobbly"
        legends={[
          {
            anchor: "top-left",
            direction: "column",
            translateX: -50,
            translateY: -20,
            itemWidth: 80,
            itemHeight: 20,
            itemTextColor: colors.grey[100],
            symbolSize: 12,
            symbolShape: "circle",
            effects: [
              {
                on: "hover",
                style: {
                  itemTextColor: colors.grey[100],
                },
              },
            ],
          },
        ]}
      />
    </Box>
  );
};


export default Radiar
