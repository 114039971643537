import { firebaseConfig } from "./firebase";
import { rejectedWithValue } from "@reduxjs/toolkit";
import { FirebaseApp, initializeApp } from "firebase/app";
import { Analytics, getAnalytics } from "firebase/analytics";
import {
  Auth,
  getAuth,
  GoogleAuthProvider,
  NextOrObserver,
  onAuthStateChanged,
  signInWithRedirect,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  Unsubscribe,
  User,
} from "firebase/auth";
import {
  doc,
  Firestore,
  getDoc,
  getFirestore,
  setDoc,
} from "firebase/firestore";
import {
  FirebaseStorage,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from "firebase/storage";


export  class FirebaseApi {
  app;
  analytics;
  auth;
  googleAuthProvider;
  firestore;
  storage;

  constructor() {
    this.app = initializeApp(firebaseConfig);
    this.analytics = getAnalytics(this.app);
    this.auth = getAuth(this.app);
    this.googleAuthProvider = new GoogleAuthProvider();
    this.firestore = getFirestore(this.app);
    this.storage = getStorage(this.app);
  }
  createUserWithEmailAndPassword = async (email, password) => {
    try {
      const response = await createUserWithEmailAndPassword(
        this.auth,
        email,
        password
      );
      return {
        token: await response.user.getIdToken(),
        email: response.user.email,
        uid: response.user.uid,
      };
    } catch (err) {
      //throw new Error(err);
      console.log(err)
      alert(err)
    }
  };

  signInWithEmailAndPassword=async (email, password) =>{
    try {
      const response = await signInWithEmailAndPassword(this.auth,email,password);
      return {
        token: await response.user.getIdToken(),
        email : response.user.email,
        uid: response.user.uid
      }
    }
    catch (err){
      throw new Error("로그인 실패");
    }
  };

  onAuthStateChanged = (nextOrObserver) => {
    return onAuthStateChanged(this.auth, nextOrObserver);
  };

  signInWithGoogleRedirect = () => {
    return signInWithRedirect(this.auth, this.googleAuthProvider);
  };

  signOut = () => {
    return signOut(this.auth);
  };
  getUserRef = (userId) => {
    return doc(this.firestore, "users", userId);
  };

  asyncSetUserInfo = async (userId, userInfo) => {
    await setDoc(this.getUserRef(userId), userInfo);
    return await this.asyncGetUserInfo(userId);
  };

  asyncUpdateUserInfo = async (userId, userInfo) => {
    await setDoc(this.getUserRef(userId), userInfo, { merge: true });
    return await this.asyncGetUserInfo(userId);
  };

  asyncGetUserInfo = async (userId) => {
    const docSnap = await getDoc(this.getUserRef(userId));
    if (!docSnap.exists()) {
      return null;
    }
    return {
      username: docSnap.data().username,
      profilePicHandle: docSnap.data().profilePicHandle ?? null,
    };
  };
  asyncUploadImage = async (userId, file) => {
    const path = userId + "/" + file.name;
    const storageRef = ref(this.storage, path);
    const ret = await uploadBytes(storageRef, file);
    return ret.ref.fullPath;
  };
  asyncGetURLFromHandle = async (handle) => {
    const url = await getDownloadURL(ref(this.storage, handle));
    return url;
  };
}