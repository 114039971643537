import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getFirestore,

} from "firebase/firestore";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithRedirect,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyAKBUQlWXZjbk6UcpCoBnVQXrVMx4ZcU0Y",
  //authDomain: "noricare-report.firebaseapp.com",
  //authDomain: "xn--o80bp9m9nk8kj.com",
  authDomain: "노리케어.com",
  projectId: "noricare-report",
  storageBucket: "noricare-report.appspot.com",
  messagingSenderId: "421408022474",
  appId: "1:421408022474:web:f3485a796b764af21b7933",
  measurementId: "G-10Q6WYXDS7",
};

// Initialize Firebase


export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
