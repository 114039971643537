import { useEffect, useState } from "react";
import { tokens } from "../../../../theme";
import { useTheme, Box, useMediaQuery, Typography } from "@mui/material";
import { getFbSeniorById } from "../../../../apis/senior";
import {getFbFitnessById} from "../../../../apis/fitness";

import UserInfo from "../../../userInfo";
import Radiar from "../../Fitness/Single/radial"
import Step from "../../Fitness/Single/step"
import BriefDetail from "../../Fitness/Single/brief";
import BulletDetail from "./bullet";
import PngBtn from "../../../pngBtn";

const SingleReport = ({ id, name, recordId }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:1366px)");
  const isNonTablet = useMediaQuery("(max-width:500px)");
  const [senior, setSenior] = useState(null);
  const [record, setRecord] = useState(null);
  const [fullWidth, setFullWidth] = useState(false);
  //const [createReport, setCreateReport] = useState(false);

  useEffect(() => {
    async function fetchSeniorData() {
      const response = await getFbSeniorById(id);
      setSenior(response);
    }
    fetchSeniorData();

  }, [id]);

  useEffect(() => {
    async function fetcFitnessData() {
      const response = await getFbFitnessById(id, recordId[0]);
      //console.log("fetchNutritionData",response);
      setRecord(response);
    }
    fetcFitnessData();
  }, [recordId]);


    function setReportWidth(state) {
      //console.log(state)
      setFullWidth(state);
    }



  return (
    <Box m="20px">
      <Box
        className="PdfArea"
        display="grid"
        gap="10px"
        gridTemplateColumns="repeat(12, minmax(0, 1fr))"
        backgroundColor={colors.primary[400]}
        borderRadius="10px"
        sx={{
          width: isNonMobile
            ? "auto"
            : isNonTablet
            ? fullWidth
              ? "1366px !important"
              : "500px"
            : fullWidth
            ? "1366px !important"
            : undefined,
        }}
      >
        {/* User Info */}
        <Box m="0 20px" p="20px" sx={{ gridColumn: "span 12" }}>
          {senior && record && (
            <UserInfo user={senior} date={record.date} fullWidth={fullWidth} />
          )}
        </Box>
        <Box
          m="20px"
          p={isNonMobile ? "20px" : "0px"}
          sx={{
            gridColumn: isNonMobile
              ? "span 4"
              : isNonTablet
              ? fullWidth
                ? "span 4"
                : "span 12"
              : fullWidth
              ? "span 4"
              : "span 12",
          }}
        >
          {senior && record && <Radiar record={record} />}
        </Box>
        {/* BMI */}
        <Box
          m="20px"
          p={isNonMobile ? "20px" : "0px"}
          sx={{
            gridColumn: isNonMobile
              ? "span 4"
              : isNonTablet
              ? fullWidth
                ? "span 4"
                : "span 12"
              : fullWidth
              ? "span 4"
              : "span 12",
          }}
        >
          {senior && record && <Step record={record} />}
        </Box>
        <Box
          m="20px"
          p={isNonMobile ? "20px" : "0px"}
          sx={{
            gridColumn: isNonMobile
              ? "span 4"
              : isNonTablet
              ? fullWidth
                ? "span 4"
                : "span 12"
              : fullWidth
              ? "span 4"
              : "span 12",
          }}
        >
          {senior && record && (
            <BriefDetail
              fullWidth={fullWidth}
              record={[
                record.muscle,
                record.cardio,
                record.fat,
                record.inferFlex,
                record.inferMuscle,
                record.supFlex,
                record.supMuscle,
              ]}
            />
          )}
        </Box>
        <Box
          m="20px"
          p={isNonMobile ? "20px" : "0px"}
          /*sx={{
            gridColumn: isNonMobile
              ? "span 12"
              : isNonTablet
              ? fullWidth
                ? "span 12"
                : "span 4"
              : fullWidth
              ? "span 12"
              : "span 4",
          }}*/
        >
          {senior && record && (
            <BulletDetail
              record={record}
              sex={senior.sex}
              fullWidth={fullWidth}
              sx={{ gridColumn: "span 12" }}
            />
          )}
        </Box>
      </Box>
      <PngBtn setReportWidth={setReportWidth} />
    </Box>
  );
};

export default SingleReport;
