import { useEffect, useState } from "react";
import { tokens, nivoTheme } from "../../../../theme";
import { useTheme, Box, Typography, useMediaQuery } from "@mui/material";
import { getFbSeniorById } from "../../../../apis/senior";
import UserInfo from "../../../userInfo";
import CompareRecord from "./compareRecord";
import CompareRadial from "./compareRadial";
import CompareOverAll from "./compareOverAll"
import QuestionTimeline from "./questionTimeline";
import { BMIBar, BMILine,BMIInfo } from "./bmi";
import {getFbMultiNutritionRecordById} from "../../../../apis/nutrition";
import PngBtn from "../../../pngBtn";

const MultiReport = ({ id, name, recordId }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:1366px)");
  const isNonTablet = useMediaQuery("(max-width:500px)");
  const [fullWidth, setFullWidth] = useState(false);
  const [senior, setSenior] = useState(null);
  const [record, setRecord] = useState([]);

  useEffect(() => {
    async function fetchSeniorData() {
      const response = await getFbSeniorById(id);
      setSenior(response);
    }
    fetchSeniorData();
  }, [id]);


  useEffect(() => {
    async function fetchNutritionData(){
      const response = await getFbMultiNutritionRecordById(id, recordId);
      //console.log("multi", response);
      setRecord(response);
    }
fetchNutritionData();
  }, [recordId]);
  return (
    <>
      <Box m="20px">
        {senior && record[0] && (
          <Box
            className="PdfArea"
            display="grid"
            gap="10px"
            gridTemplateColumns="repeat(12, minmax(0, 1fr))"
            backgroundColor={colors.primary[400]}
            borderRadius="10px"
            sx={{
              width: isNonMobile
                ? "auto"
                : isNonTablet
                ? fullWidth
                  ? "1366px !important"
                  : "500px"
                : fullWidth
                ? "1366px !important"
                : undefined,
            }}
          >
            <Box m="0 20px" p="20px" sx={{ gridColumn: "span 12" }}>
              {senior && record && (
                <UserInfo
                  user={senior}
                  date={record.date}
                  fullWidth={fullWidth}
                />
              )}
            </Box>
            {/* Compare Recent Record */}
            <Box m="0 10px" p="0 20px" sx={{ gridColumn: "span 12" }}>
              <Typography
                variant="h3"
                color={colors.greenAccent[400]}
                fontWeight="bold"
              >
                {record.at(-1) ? record.at(-1).date : "current date"} vs{" "}
                {record.at(-2) ? record.at(-2).date : "previous date"}
              </Typography>
            </Box>
            <Box
              m="0 10px"
              p="0 20px"
              sx={{
                gridColumn: fullWidth
                  ? "span 4"
                  : isNonMobile
                  ? "span 4"
                  : "span 12",
              }}
            >
              <CompareRecord current={record.at(-1)} previous={record.at(-2)} />
            </Box>
            <Box
              m="0 10px"
              p="0 20px"
              sx={{
                gridColumn: fullWidth
                  ? "span 4"
                  : isNonMobile
                  ? "span 4"
                  : "span 12",
              }}
            >
              <CompareRadial current={record.at(-1)} previous={record.at(-2)} />
            </Box>
            <Box
              m="0 10px"
              p="0 20px"
              sx={{
                gridColumn: fullWidth
                  ? "span 4"
                  : isNonMobile
                  ? "span 4"
                  : "span 12",
              }}
            >
              <CompareOverAll record={record} />
            </Box>
            {/* Compare Question Record */}
            <Box
              m="0 10px"
              p="0 20px"
              sx={{
                gridColumn: fullWidth
                  ? "span 4"
                  : isNonMobile
                  ? "span 4"
                  : "span 12",
              }}
            >
              <QuestionTimeline
                record={record}
                type="q1Data"
                title={record[0].q1Data.sub}
              />
            </Box>
            <Box
              m="0 10px"
              p="0 20px"
              sx={{
                gridColumn: fullWidth
                  ? "span 4"
                  : isNonMobile
                  ? "span 4"
                  : "span 12",
              }}
            >
              <QuestionTimeline
                record={record}
                type="q2Data"
                title={record[0].q2Data.sub}
              />
            </Box>
            <Box
              m="0 10px"
              p="0 20px"
              sx={{
                gridColumn: fullWidth
                  ? "span 4"
                  : isNonMobile
                  ? "span 4"
                  : "span 12",
              }}
            >
              <QuestionTimeline
                record={record}
                type="q3Data"
                title={record[0].q3Data.sub}
              />
            </Box>
            <Box
              m="0 10px"
              p="0 20px"
              sx={{
                gridColumn: fullWidth
                  ? "span 4"
                  : isNonMobile
                  ? "span 4"
                  : "span 12",
              }}
            >
              <QuestionTimeline
                record={record}
                type="q4Data"
                title={record[0].q4Data.sub}
              />
            </Box>
            <Box
              m="0 10px"
              p="0 20px"
              sx={{
                gridColumn: fullWidth
                  ? "span 4"
                  : isNonMobile
                  ? "span 4"
                  : "span 12",
              }}
            >
              <QuestionTimeline
                record={record}
                type="q5Data"
                title={record[0].q5Data.sub}
              />
            </Box>
            <Box
              m="0 10px"
              p="0 20px"
              sx={{
                gridColumn: fullWidth
                  ? "span 4"
                  : isNonMobile
                  ? "span 4"
                  : "span 0",
                display: fullWidth ? "auto" : isNonMobile ? "auto" : "none",
              }}
            ></Box>
            <Box
              m="0 10px"
              p="0 20px"
              sx={{
                gridColumn: fullWidth
                  ? "span 4"
                  : isNonMobile
                  ? "span 4"
                  : "span 12",
              }}
            >
              <BMIBar record={record} title={record[0].BMIData.sub} />
            </Box>
            <Box
              m="0 10px"
              p="0 20px"
              sx={{
                gridColumn: fullWidth
                  ? "span 4"
                  : isNonMobile
                  ? "span 4"
                  : "span 12",
              }}
            >
              <BMILine record={record} title={record[0].BMIData.sub} />
            </Box>
            <Box
              m="0 10px"
              p="0 20px"
              sx={{
                gridColumn: fullWidth
                  ? "span 4"
                  : isNonMobile
                  ? "span 4"
                  : "span 12",
              }}
            >
              <BMIInfo record={record} title={record[0].BMIData.sub} />
            </Box>
          </Box>
        )}
        <PngBtn setReportWidth={setFullWidth} />
      </Box>
    </>
  );
};

export default MultiReport;
