import { useEffect, useState } from "react";
import { Box, useTheme, Button,Stack } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../Header";
import { useNavigate } from "react-router-dom";
import GetSenior from "../Form/getSenior";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useLocation } from "react-router-dom";
import { getFbFitnessData, deleteFbMultiFitnessById } from "../../apis/fitness";

const Fitness = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [recordList, setRecordList] = useState([]);
  const [selectedRecordList, setSelectedRecordList] = useState([]);
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:1366px)");
  const searchParams = useLocation().search;
  const id = new URLSearchParams(searchParams).get("id");
  const name = new URLSearchParams(searchParams).get("name");

  useEffect(() => {
    async function fetchData() {
      const response = await getFbFitnessData(id);

      setRecordList(response);
    }
    fetchData();
  }, [name, id]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
    },
    {
      field: "name",
      headerName: "Name",
      cellClassName: "name-column--cell",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "fat",
      headerName: "체지방률",
      flex: 0.5,
      type: "number",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "muscle",
      headerName: "근육량",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "cardio",
      headerName: "심폐지구력",
      flex: 0.5,
      type: "number",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "inferFlex",
      headerName: "하지유연성",
      flex: 0.5,
      type: "number",
      headerAlign: "center",
      align: "center",
    },

    {
      field: "inferMuscle",
      headerName: "하지근기능",
      flex: 0.5,
      type: "string",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "supFlex",
      headerName: "상지유연성",
      flex: 0.5,
      type: "string",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "supMuscle",
      headerName: "상지근기능",
      flex: 0.5,
      type: "string",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "date",
      headerName: "등록일",
      flex: 0.5,
      type: "string",
      headerAlign: "center",
      align: "center",
    },
  ];


    function createDeleteWarn(deleteList) {
      let deleteInfo = "";
      deleteList.map((item) => (deleteInfo += `${item}, `));

      if (deleteInfo.length === 0) {
        return "선택한 기록이 존재하지 않습니다.";
      } else if (deleteInfo.length > 0) {
        return `선택한 건강 체력 기록에 ${deleteInfo} 삭제를 진행하겠습니까?`;
      }
    }
    async function handleFitnessDeleteBtn() {
      if (window.confirm(createDeleteWarn(selectedRecordList))) {
        const response = await deleteFbMultiFitnessById(id, selectedRecordList);
        //console.log(response);
        //alert("삭제되었습니다.");
        window.location.replace(`/record?type=fitness&id=${id}&name=${name}`);
      } else {
        alert("취소합니다.");
      }
    }

  const handleCreateReportBtn = () => {
    //console.log(selectedRecordList)
    if (!selectedRecordList.length) {
      alert("선택된 기록이 없어 리포트 생성이 불가능 합니다!");
    } else if (selectedRecordList.length === 1) {
      //console.log("create report", selectedRecordList);
      navigate(`/report?type=fitness&id=${id}&name=${name}`, {
        state: { selectedRecordList },
      });
    } else {
      navigate(`/report?type=fitness&id=${id}&name=${name}`, {
        state: { selectedRecordList },
      });
    }
  };

  return (
    <Box m="20px">
      <Header
        title="체력 요소 평가 보고서"
        subtitle="체력 요소 평가 보고서를 다운로드 받습니다"
      />
      {!id | !name ? <GetSenior type="report-fitness" /> : <></>}
      {id && name && (
        <>
          <Box
            m="20px 0 0 0"
            height="75vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .css-1ny7bi9-MuiButtonBase-root-MuiButton-root ": {
                color: `${colors.primary[200]} !important`,
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
            }}
          >
            <DataGrid
              checkboxSelection
              loading={!recordList}
              rows={recordList}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
              onSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedRows = recordList
                  .filter((item) => selectedIDs.has(item.id))
                  .map((item) => item.id);
                //setSelectedRow(selectedRows);
                setSelectedRecordList(selectedRows);
                //console.log("data grid", selectedRows.length);
              }}
            />
          </Box>
          <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={1}
            mt="10px"
          >
            <Button
              color="secondary"
              variant="contained"
              onClick={handleFitnessDeleteBtn}
            >
              검사 기록 삭제
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={handleCreateReportBtn}
            >
              보고서 생성
            </Button>
          </Stack>
        </>
      )}
    </Box>
  );
};

export default Fitness;
