import { Box, IconButton, useTheme, Button,TextField } from "@mui/material";
import { useAppSelector } from "../../redux/hooks";
import { withFirebaseApi } from "../../firebase";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
import { OnboardingProfile } from "../profile";

const Login = (props) => {
    const currentUserId = useAppSelector((state) => state.user.userId);
    const userInfo = useAppSelector((state) => state.user.userInfo.value);
    const isNonMobile = useMediaQuery("(min-width:1366px)");
    const navigation = useNavigate();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const handleFormSubmit = async (values) => {
      const data = {
        email: values.email,
        password: values.password,
      };
      //console.log(data)
      try {
      const response = await props.firebaseApi.signInWithEmailAndPassword(data.email, data.password);
      if (response.token) {
        /*if (userInfo === null) {
          return <OnboardingProfile />;
        }*/
        navigation("/record?type=senior");
      }
      }catch (err){
        alert(err);
      }

    };

    const handleSignInBtn = async () =>{

      navigation("/signup")
    }


  const loginWithGoogleButton = (
    <Button
      color="secondary"
      variant="outlined"
      size="large"
      sx={{ width: "100%" }}
      onClick={props.firebaseApi.signInWithGoogleRedirect}
    >
      Login with Google
    </Button>
  );
  const logoutButton = (
    <Button
      color="inherit"
      size="large"
      sx={{ gridColumn: "span 1" }}
      onClick={props.firebaseApi.signOut}
    >
      Log out
    </Button>
  );
  const button = currentUserId == null ? loginWithGoogleButton : logoutButton;

  return (
    <Box m="20px 70px">
      {!currentUserId && (
        <>
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  display="grid"
                  gap="20px"
                  gridTemplateColumns="repeat(3, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 3",
                    },
                  }}
                >
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="email"
                    /*defaultValue={email}*/
                    value={values.email}
                    name="email"
                    onChange={handleChange}
                    /*onBlur={handleBlur}
                  onChange={handleChange}
                  error={!!touched.name && !!errors.name}
                  helperText={touched.name && errors.name}*/
                    sx={{ gridColumn: "span 3" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="password"
                    /*defaultValue={password}*/
                    value={values.password}
                    name="password"
                    onChange={handleChange}
                    /*onBlur={handleBlur}
                  onChange={handleChange}
                  error={!!touched.id && !!errors.id}
                  helperText={touched.id && errors.id}*/
                    sx={{ gridColumn: "span 3" }}
                  />
                </Box>
                <Box
                  display="grid"
                  m="20px 0"
                  gap="5px"
                  gridTemplateColumns="repeat(1, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 2",
                    },
                  }}
                >
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    size="large"
                    sx={{ gridColumn: "span 1" }}
                  >
                    로그인
                  </Button>
                  {button}
                  <Button
                    onClick={handleSignInBtn}
                    color="secondary"
                    /*variant="outlined"*/
                    size="large"
                    sx={{ gridColumn: "span 1" }}
                  >
                    회원가입
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </>
      )}
      \
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  email: yup.string().required("입력값을 확인해주세요").min(0),
  password: yup.string().required("입력값을 확인해주세요"),
});
const initialValues = {
  email: "",
  password: "",
};

export default withFirebaseApi(Login);
