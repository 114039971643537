import { useTheme, Box, Typography, useMediaQuery,Stack } from "@mui/material";
import { tokens, nivoTheme } from "../../../../theme";
import {
  getCompareResult,
  getCompareResultColor,
} from "../../../../utils/nutrition";

const ShowCompareResult = ({current, previous})=>{
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const result = getCompareResult(current, previous);
  //console.log(result);

  return (
    <Box
      backgroundColor={getCompareResultColor(colors, result)}
      padding="5px 8px"
      borderRadius="10px"
      color="white"
      fontWeight="bold"
    >
      {result}
    </Box>
  );
  
}


const CompareRecord = ({ current, previous }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  //console.log("current",current)
  //console.log("previous", previous)
  const isNonMobile = useMediaQuery("(min-width:1366px)");
  return (
    <>
      <Typography
        variant="h3"
        color={colors.greenAccent[400]}
        fontWeight="bold"
        m="18px 0"
      >
        기록 비교
      </Typography>
      <Stack direction="column" spacing={2} width="300px">
        <Stack direction="row" spacing={10} justifyContent="space-between">
          <Typography variant="h4" fontWeight="bold" color="textPrimary">
            {current.q1Data.sub}
          </Typography>
          <ShowCompareResult
            current={current.q1Data.score}
            previous={previous.q1Data.score}
          />
        </Stack>
        <Stack direction="row" spacing={10} justifyContent="space-between">
          <Typography variant="h4" fontWeight="bold" color="textPrimary">
            {current.q2Data.sub}
          </Typography>
          <ShowCompareResult
            current={current.q2Data.score}
            previous={previous.q2Data.score}
          />
        </Stack>
        <Stack direction="row" spacing={10} justifyContent="space-between">
          <Typography variant="h4" fontWeight="bold" color="textPrimary">
            {current.q3Data.sub}
          </Typography>
          <ShowCompareResult
            current={current.q3Data.score}
            previous={previous.q3Data.score}
          />
        </Stack>
        <Stack direction="row" spacing={10} justifyContent="space-between">
          <Typography variant="h4" fontWeight="bold" color="textPrimary">
            {current.q4Data.sub}
          </Typography>
          <ShowCompareResult
            current={current.q4Data.score}
            previous={previous.q4Data.score}
          />
        </Stack>
        <Stack direction="row" spacing={10} justifyContent="space-between">
          <Typography variant="h4" fontWeight="bold" color="textPrimary">
            {current.q5Data.sub}
          </Typography>
          <ShowCompareResult
            current={current.q5Data.score}
            previous={previous.q5Data.score}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default CompareRecord;
