import {
  Typography,
  Stack,
  Box,
  Button,
  MenuItem,
  useTheme,
  useMediaQuery,
  TextField,
  Modal,
} from "@mui/material";

import { Formik } from "formik";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
import GetTrainer from "./getTrainer";
import { getFbSeniorById, updateFbSeniorById } from "../../apis/senior";


const SearchTrainerModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function SearchTrainerModal({ setSenior, getProfileImgUrl }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  //console.log("SearchTrainerModal", getProfileImgUrl);

  return (
    <Box sx={{ gridColumn: "span 1" }}>
      <Button
        color="secondary"
        variant="outlined"
        onClick={handleOpen}
        fullWidth
        sx={{ padding: "15px" }}
      >
        Change Current Coach
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={SearchTrainerModalStyle}>
          <GetTrainer
            onClose={handleClose}
            setSenior={setSenior}
            getProfileImgUrl={getProfileImgUrl}
          />
        </Box>
      </Modal>
    </Box>
  );
}

const SeniorProfile = ({ getProfileImgUrl }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [senior, setSenior] = useState(null);
  //const [selectedRecordList, setSelectedRecordList] = useState(null);
  //const [showSearchTrainer, setShowSearchTrainer]=useState(false);
  //console.log(getProfileImgUrl);

  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:1366px)");
  const searchParams = useLocation().search;
  const id = new URLSearchParams(searchParams).get("id");
  const name = new URLSearchParams(searchParams).get("name");

  useEffect(() => {
    async function fetchData() {
      const response = await getFbSeniorById(id);

      const data = {
        name: response.name,
        age: response.age,
        sex: response.sex,
        phoneNumber: response.phoneNumber,
        height: response.height,
        weight: response.weight,
        initialCoach: response.initialCoach,
        currentCoach: response.currentCoach,
        currentCoachId: response.currentCoachId,
      };
      //console.log(data);

      setSenior(data);
    }
    fetchData();
  }, []);

  useEffect(() => {}, [senior]);

  const currencies = [
    {
      value: 1,
      label: "여성",
    },
    {
      value: 0,
      label: "남성",
    },
  ];

  const handleFormSubmit = async (values) => {
    //console.log(senior);

    const data = {
      name: values.name,
      age: values.age,
      sex: values.sex,
      phoneNumber: values.phoneNumber,
      height: values.height,
      weight: values.weight,
      initialCoach: values.initialCoach,
      currentCoachId: senior.currentCoachId,
      currentCoach: senior.currentCoach,
    };
    //console.log("handleFormSubmit start", data);
    try {
      const response = await updateFbSeniorById(id, data);
    } catch (e) {
      alert(e);
    }
    navigate("/record?type=senior");
  };
  return (
    senior != null && (
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={senior}
        /*validationSchema={checkoutSchema}*/
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} id="form1">
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                "& > button": {
                  gridColumn: isNonMobile ? undefined : "span 4",
                },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={senior.name}
                disabled
                name="name"
                error={!!touched.name && !!errors.name}
                helperText={touched.name && errors.name}
                sx={{ gridColumn: "span 4" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="Age"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.age}
                name="age"
                error={!!touched.age && !!errors.age}
                helperText={touched.age && errors.age}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                id="outlined-select-currency"
                select
                label="Sex"
                name="sex"
                onChange={handleChange}
                value={values.sex}
                sx={{ gridColumn: "span 2" }}
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Phone Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phoneNumber}
                name="phoneNumber"
                error={!!touched.phoneNumber && !!errors.phoneNumber}
                helperText={touched.phoneNumber && errors.phoneNumber}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="Height"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.height}
                name="height"
                error={!!touched.height && !!errors.height}
                helperText={touched.height && errors.height}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="Weight"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.weight}
                name="weight"
                error={!!touched.weight && !!errors.weight}
                helperText={touched.weight && errors.weight}
                sx={{ gridColumn: "span 2" }}
              />
              {/*<TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Initial Coach"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.initialCoach}
                  name="initialCoach"
                  disabled={true}
                  error={!!touched.initialCoach && !!errors.initialCoach}
                  helperText={touched.initialCoach && errors.initialCoach}
                  sx={{ gridColumn: "span 1" }}
                  />*/}
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Current Coach"
                onBlur={handleBlur}
                onChange={handleChange}
                value={senior.currentCoach}
                name="currentCoach"
                disabled={true}
                error={!!touched.currentCoach && !!errors.currentCoach}
                helperText={touched.currentCoach && errors.currentCoach}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Current Coach"
                onBlur={handleBlur}
                onChange={handleChange}
                value={senior.currentCoachId}
                name="currentCoach"
                disabled={true}
                error={!!touched.currentCoachId && !!errors.currentCoachId}
                helperText={touched.currentCoachId && errors.currentCoachId}
                sx={{ gridColumn: "span 2" }}
              />

              <SearchTrainerModal
                setSenior={setSenior}
                getProfileImgUrl={getProfileImgUrl}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                form="form1"
              >
                Update Senior
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    )
  );
};

export default SeniorProfile