import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";

const currencies = [
  {
    value: "1",
    label: "여성",
  },
  {
    value: "0",
    label: "남성",
  },
];

const Form = () => {
  const isNonMobile = useMediaQuery("(min-width:1366px)");
  const navigate = useNavigate();
  const userInfo = useAppSelector((state) => state.user.userInfo.value);
  const userId = useAppSelector((state) => state.user.userId);

const initialValues = {
  name: "",
  age: 70,
  sex: 1,
  phoneNumber: "010XXXXYYYY",
  height: 170,
  weight: 45,
  initialCoach: userInfo.username,
  initialCoachId: userId,
};
  const handleFormSubmit = async (values) => {
    const data = {
      ...values,
      age: Number(values.age),
      weight: Number(values.weight),
      height: Number(values.height),
      sex: Number(values.sex),
      createdAt: moment().format("YYYY-MM-DD"),
      currentCoach: values.initialCoach,
      currentCoachId: values.initialCoachId,
    };
    //console.log("handleFormSubmit start", data);

    try {
      const response = await addDoc(collection(db, "senior"), data);
      //console.log("handleFormSubmit finish", response);
      navigate(`/record?type=senior`);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Box m="20px">
      <Header title="회원등록" subtitle="새로운 회원을 등록합니다" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                name="name"
                error={!!touched.name && !!errors.name}
                helperText={touched.name && errors.name}
                sx={{ gridColumn: "span 4" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Age"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.age}
                name="age"
                error={!!touched.age && !!errors.age}
                helperText={touched.age && errors.age}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                id="outlined-select-currency"
                select
                label="Sex"
                name="sex"
                onChange={handleChange}
                value={values.sex}
                sx={{ gridColumn: "span 2" }}
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Phone Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phoneNumber}
                name="phoneNumber"
                error={!!touched.phoneNumber && !!errors.phoneNumber}
                helperText={touched.phoneNumber && errors.phoneNumber}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Height"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.height}
                name="height"
                error={!!touched.height && !!errors.height}
                helperText={touched.height && errors.height}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Weight"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.weight}
                name="weight"
                error={!!touched.weight && !!errors.weight}
                helperText={touched.weight && errors.weight}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Coach"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.initialCoach}
                name="initialCoach"
                disabled
                error={!!touched.initialCoach && !!errors.initialCoach}
                helperText={touched.initialCoach && errors.initialCoach}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Coach Id"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.initialCoachId}
                name="coachId"
                disabled
                error={!!touched.initialCoachId && !!errors.initialCoachId}
                helperText={touched.initialCoachId && errors.initialCoachId}
                sx={{ gridColumn: "span 4" }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Create New Senior
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const phoneRegExp =
  /^([0]{1}|\+?[234]{3})([7-9]{1})([0|1]{1})([\d]{1})([\d]{7})$/g;

const checkoutSchema = yup.object().shape({
  name: yup.string().required("입력값을 확인해주세요"),
  age: yup.number().required("입력값을 확인해주세요"),

  sex: yup.string().required("입력값을 확인해주세요"),
  phoneNumber: yup
    .string()
    .required("입력값을 확인해주세요"),
  height: yup.number().required("입력값을 확인해주세요"),
  weight: yup.number().required("입력값을 확인해주세요"),
});


export default Form;
