import { Radio, FormControlLabel } from "@mui/material";
import {  useField } from "formik";

const LabeledRadio = ({ label, ...rest }) => {

  const [field] = useField(rest);
  return <FormControlLabel control={<Radio />} label={label} {...field} />;
};

export default LabeledRadio;
