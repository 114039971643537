import Header from "../Header";
import { Typography, Box, Button, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";

const Nutrition = ({ handleBtnClick }) => {
  //console.log("회원가입")
  const navigation = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <>
      <Box m="20px">
        <Header title="영양 평가 진행" />
        <Typography variant="body1" color={colors.grey[100]}>
          검사값 입력이 완료되었습니다.
        </Typography>
        <Typography variant="body1" color={colors.grey[100]}>
          영양 평가 보고서 탭으로 이동해 보고서를 추출하세요.
        </Typography>

        <Button
          sx={{ mt: 1.5 }}
          color="secondary"
          variant="contained"
          onClick={(e) => {
            handleBtnClick();
          }}
        >
          영양 평가 검사 보고서로 이동
        </Button>
      </Box>
    </>
  );
};

export default Nutrition;
