import { useTheme, Box, Typography, Stack } from "@mui/material";
import { tokens } from "../../../../../theme";
import { getDataColorByScore } from "../../../../../utils/fitness";

const MuscleDetail = ({ record ,type,title}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box>
      <Typography
        variant="h3"
        color={colors.greenAccent[400]}
        fontWeight="bold"
        m="18px 0"
      >
        {title} 기록 요약
      </Typography>
      <Box
        display="flex"
        margin="3px 0"
        flexDirection="column"
        justifyContent="space-between"
        /*width="80%"*/
        width="30rem"
      >
        {record.map((item) => (
          <Box
            display="flex"
            margin="5px 0"
            flexDirection="row"
            justifyContent="space-between"
            key={item.date}
          >
            <Typography variant="h4" fontWeight="bold" color="textPrimary">
              {item.date}
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center" width="45%">
              <Box
                backgroundColor={getDataColorByScore(colors, item[type].score)}
                padding="5px 10px"
                borderRadius="10px"
                color="white"
                fontWeight="bold"
              >
                {item[type].value}
              </Box>

              <Box
                backgroundColor={getDataColorByScore(colors, item[type].score)}
                padding="5px 10px"
                borderRadius="10px"
                color="white"
                fontWeight="bold"
              >
                {item[type].score}점 / {item[type].total}점
              </Box>
            </Stack>
          </Box>
        ))}
      </Box>
    </Box>
  );
};


export default MuscleDetail;