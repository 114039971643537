import { Box, IconButton, useTheme, Button, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { withFirebaseApi } from "../../firebase";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik } from "formik";
import * as yup from "yup";

const Signup = (props) => {
  const isNonMobile = useMediaQuery("(min-width:1366px)");
  const navigation = useNavigate();

  const handleFormSubmit = async (values) => {
    const data = {
      email: values.email,
      password: values.password,
    };


   const response = await props.firebaseApi.createUserWithEmailAndPassword(
      data.email,
      data.password
    );
    //console.log("회원 가입 성공",response);
    if (response.token){
      navigation("/complete?type=signup")
    }

  };

  const handleLoginBtn = ()=>{
    navigation("/login")
  }



  return (
    <Box m="20px 70px">
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="20px"
              gridTemplateColumns="repeat(3, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 3" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="email"
                /*defaultValue={email}*/
                value={values.email}
                name="email"
                onChange={handleChange}
                /*onBlur={handleBlur}
                  
                  error={!!touched.name && !!errors.name}
                  helperText={touched.name && errors.name}*/
                sx={{ gridColumn: "span 3" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="password"
                /*defaultValue={password}*/
                value={values.password}
                name="password"
                onChange={handleChange}
                /*onBlur={handleBlur}
                  
                  error={!!touched.id && !!errors.id}
                  helperText={touched.id && errors.id}*/
                sx={{ gridColumn: "span 3" }}
              />
            </Box>
            <Box
              display="grid"
              m="20px 0"
              gap="5px"
              gridTemplateColumns="repeat(1, minmax(0, 1fr))"
              sx={{
                "& > div": {
                  gridColumn: isNonMobile ? undefined : "span 2",
                },
              }}
            >
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                size="large"
              >
                회원가입
              </Button>
              <Button
                onClick={handleLoginBtn}
                color="secondary"
                variant="outlined"
                size="large"
              >
                로그인
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  email: yup.string().required("입력값을 확인해주세요").min(0),
  password: yup.string().required("입력값을 확인해주세요"),
});
const initialValues = {
  email: "",
  password: "",
};

export default withFirebaseApi(Signup);
