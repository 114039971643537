import { useTheme, Box, Typography, useMediaQuery, Stack } from "@mui/material";
import { ResponsiveRadar } from "@nivo/radar";
import { tokens, nivoTheme } from "../../../../theme";
import { useState, useEffect } from "react";


const CompareRadial = ({current, previous}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:1366px)");
  const [data, setData] = useState([]);

    useEffect(() => {
      if (current && previous) {
        const response = [
          {
            id: current.q1Data.sub,
            [current.date]: current.q1Data.score,
            [previous.date]: previous.q1Data.score,
          },
          {
            id: current.q2Data.sub,
            [current.date]: current.q2Data.score,
            [previous.date]: previous.q2Data.score,
          },
          {
            id: current.q3Data.sub,
            [current.date]: current.q3Data.score,
            [previous.date]: previous.q3Data.score,
          },
          {
            id: current.q4Data.sub,
            [current.date]: current.q4Data.score,
            [previous.date]: previous.q4Data.score,
          },
          {
            id: current.q5Data.sub,
            [current.date]: current.q5Data.score,
            [previous.date]: previous.q5Data.score,
          },
        ];

        setData(response);
      } else {
        return;
      }
    }, [current, previous]);
  return (
    <Box height="45vh" width="35vh">
      <Typography
        variant="h3"
        color={colors.greenAccent[400]}
        fontWeight="bold"
        m="18px 0"
      >
        총점 비교
      </Typography>
      <ResponsiveRadar
        data={data}
        keys={[`${current.date}`, `${previous.date}`]}
        theme={nivoTheme(colors)}
        colors={{ scheme: "pastel1" }}
        indexBy="id"
        valueFormat=">-.2f"
        fillOpacity={0.9}
        blendMode="multiply"
        motionConfig="wobbly"
        margin={{ top: 50, right: 80, bottom: 50, left: 80 }}
        borderColor={{ from: "color" }}
        gridLabelOffset={20}
        dotSize={3}
        dotColor={{ theme: "background" }}
        dotBorderWidth={2}
        legends={[
          {
            anchor: "top-left",
            direction: "column",
            translateX: -50,
            translateY: -40,
            itemWidth: 80,
            itemHeight: 20,
            itemTextColor: colors.grey[100],
            symbolSize: 12,
            symbolShape: "circle",
            effects: [
              {
                on: "hover",
                style: {
                  itemTextColor: colors.grey[100],
                },
              },
            ],
          },
        ]}
      />
    </Box>
  );
};

export default CompareRadial;
