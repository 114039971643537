import { Box, Button, useTheme, useMediaQuery } from "@mui/material";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import ElderlyIcon from "@mui/icons-material/Elderly";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { tokens } from "../../../theme";
import { useNavigate } from "react-router-dom";
import StatBox from "../../StateCard";
import { getScore, getOverAllData } from "../../../utils/nutrition";
import { AddNutritionById } from "../../../apis/nutrition";

const ShowScore = ({ item, setShowGrade }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:1366px)");
  const navigate = useNavigate();

  const handleSubmitBtn = async (
    e,
    q1Data,
    q2Data,
    q3Data,
    q4Data,
    q5Data,
    BMIData
  ) => {
    const data = {
      senior: {
        id: item.id,
        name: item.name,
      },
      date: item.date,
      q1Data,
      q2Data,
      q3Data,
      q4Data,
      q5Data,
      BMIData,
      overAll,
    };
    const response = await AddNutritionById(item.id, data);
    setShowGrade(null);
    navigate("/complete?type=nutrition");
  };

  const { q1Data, q2Data, q3Data, q4Data, q5Data, BMIData } = getScore(item);
  const overAll = getOverAllData({
    q1Data,
    q2Data,
    q3Data,
    q4Data,
    q5Data,
    BMIData,
  });

  return (
    <>
      <Box
        display="grid"
        mt="30px"
        gap="30px"
        gridTemplateColumns="repeat(10, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 5" },
        }}
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={q1Data.value}
            subtitle={q1Data.sub}
            progress={q1Data.score / q1Data.total}
            content={q1Data.score}
            icon={
              <AccessibilityIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={q2Data.value}
            subtitle={q2Data.sub}
            progress={q2Data.score / q2Data.total}
            content={q2Data.score}
            icon={
              <DirectionsWalkIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={q3Data.value}
            subtitle={q3Data.sub}
            progress={q3Data.score / q3Data.total}
            content={q3Data.score}
            icon={
              <ElderlyIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={q4Data.value}
            subtitle={q4Data.sub}
            progress={q4Data.score / q4Data.total}
            content={q4Data.score}
            icon={
              <ContentPasteIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={q5Data.value}
            subtitle={q5Data.sub}
            progress={q5Data.score / q5Data.total}
            content={q5Data.score}
            icon={
              <ContentPasteIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={BMIData.value}
            subtitle={BMIData.sub}
            progress={BMIData.score / BMIData.total}
            content={BMIData.score}
            icon={
              <ContentPasteIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={overAll.value}
            subtitle={overAll.step}
            progress={overAll.score / overAll.total}
            content={overAll.score}
            icon={
              <ContentPasteIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
      </Box>
      <Box display="flex" justifyContent="end" m="20px 0">
        <Button
          color="secondary"
          variant="contained"
          onClick={(e) =>
            handleSubmitBtn(e, q1Data, q2Data, q3Data, q4Data, q5Data, BMIData)
          }
        >
          Submit Test Result
        </Button>
      </Box>
    </>
  );
};

export default ShowScore;
