import { useEffect, useState } from "react";
import InputBase from "@mui/material/InputBase";
import {
  Box,
  IconButton,
  useTheme,
  Typography,
  Button,
  Card,
  CardActions,
  CardContent,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { tokens } from "../../../theme";
import { Formik } from "formik";
import * as yup from "yup";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebase/firebase";
import { query, orderBy, where } from "firebase/firestore";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAppSelector } from "../../../redux/hooks";
import { useNavigate } from "react-router-dom";
import { checkAbleToHandle,checkAdmin } from "../../../utils";
import { getFbSeniorDataByName } from "../../../apis/senior";

  const handleCardBtnClick = (e, item, navigate, type) => {
    //console.log(item);
    if (type === "record") {
      navigate(`/register?type=exercise&id=${item.id}&name=${item.name}`);
    } else if (type === "report") {
      navigate(`/record?type=exercise&id=${item.id}&name=${item.name}`);
    } else if (type === "nutrition") {
      navigate(`/register?type=nutrition&id=${item.id}&name=${item.name}`);
    } else if (type === "report-nutrition") {
      navigate(`/record?type=nutrition&id=${item.id}&name=${item.name}`);
    } else if (type === "fitness") {
      navigate(
        `/register?type=fitness&id=${item.id}&name=${item.name}&sex=${item.sex}`
      );
    } else if (type === "report-fitness") {
      navigate(`/record?type=fitness&id=${item.id}&name=${item.name}`);
    }
  };




const SeniorCard = ({ colors, item, type }) => {

  //console.log(type)

  const navigate = useNavigate();

  return (
    <Card variant="outlined" sx={{ mt: 1.5 }}>
      <CardContent>
        <Typography
          sx={{ fontSize: 14 }}
          color={colors.primary[300]}
          gutterBottom
        >
          {item.id}
        </Typography>
        <Typography variant="h4" component="div">
          {item.name}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color={colors.primary[100]}>
          {item.sex === 1 ? "여성" : "남성"} / {item.age}세
        </Typography>
        <Typography variant="body2" color={colors.grey[100]}>
          전화 번호 : {item.phoneNumber}
          <br />
          {item.height}cm / {item.weight}kg
          <br />
          등록 코치 : {item.initialCoach}
        </Typography>
      </CardContent>
      <CardActions>
        {type === "record" && (
          <Button
            color="secondary"
            onClick={(e) => {
              handleCardBtnClick(e, item, navigate, type);
            }}
          >
            기록 등록하기
          </Button>
        )}

        {type === "report" && (
          <Button
            color="secondary"
            onClick={(e) => {
              handleCardBtnClick(e, item, navigate, type);
            }}
          >
            레포트 조회하기
          </Button>
        )}

        {type === "nutrition" && (
          <Button
            color="secondary"
            onClick={(e) => {
              handleCardBtnClick(e, item, navigate, type);
            }}
          >
            영양 등록하기
          </Button>
        )}
        {type === "report-nutrition" && (
          <Button
            color="secondary"
            onClick={(e) => {
              handleCardBtnClick(e, item, navigate, type);
            }}
          >
            영양 조회하기
          </Button>
        )}
        {type === "fitness" && (
          <Button
            color="secondary"
            onClick={(e) => {
              handleCardBtnClick(e, item, navigate, type);
            }}
          >
            건강 체력 등록하기
          </Button>
        )}
        {type === "report-fitness" && (
          <Button
            color="secondary"
            onClick={(e) => {
              handleCardBtnClick(e, item, navigate, type);
            }}
          >
            건강 체력 조회하기
          </Button>
        )}
      </CardActions>
    </Card>
  );
};


const GetSenior = ({type}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [searchName, setSearchName] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const isNonMobile = useMediaQuery("(min-width:1366px)");
  const userInfo = useAppSelector((state) => state.user.userInfo.value);
  const userId = useAppSelector((state) => state.user.userId);


  async function fetchData(uid, searchName) {
    console.log(uid, searchName)
    const response = checkAdmin(uid)
      ? await getFbSeniorDataByName(searchName)
      : await getFbSeniorDataByName(searchName);
    
    return response;
  }

  const handleFormSubmit = async (values, e) => {
    // setSearchName(values.name);
    const searchName = values.name; 
    const response = await fetchData(userId, searchName);
    console.log(response.length)
    setSearchResult([...response]);
  };

  useEffect(() => {
    console.log(searchResult)
  }, [searchResult]);
  return (
    <>
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
        width="48%"
        sx={{
          "& > form": {
            width: "100%",
          },
        }}
      >
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                backgroundColor={colors.primary[400]}
                borderRadius="3px"
                width="100%"
              >
                <InputBase
                  sx={{ ml: 2, flex: 1 }}
                  placeholder="홍길동"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  name="name"
                  error={!!touched.name && !!errors.name}
                />
                <IconButton
                  type="button"
                  sx={{ p: 1 }}
                  onClick={(e) => handleFormSubmit(values, e)}
                >
                  <SearchIcon />
                </IconButton>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
        {searchResult.length > 0 ? (
          <>
            {searchResult.map(
              (item, idx) =>
                checkAbleToHandle(userId, item.currentCoachId, item.initialCoachId) && (
                  <SeniorCard
                    colors={colors}
                    item={item}
                    key={idx}
                    type={type}
                    sx={{ gridColumn: "span 4" }}
                  />
                )
            )}
          </>
        ) : (
          <Typography variant="h5" color={colors.grey[400]} sx={{ mt: 1.5 }}>
            검색 결과 없음
          </Typography>
        )}
      </Box>
    </>
  );
};

const checkoutSchema = yup.object().shape({
  name: yup.string().required("입력값을 확인해주세요"),
});
const initialValues = {
  name: "",
};

export default GetSenior;
