import { ResponsiveLine } from "@nivo/line";
import { useTheme, Box, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens, nivoTheme } from "../../../../../theme";
import { ResponsiveBar } from "@nivo/bar";

const MuscleLine = ({ record, type ,title}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:1366px)");

  function createData() {
    const response = [];
    record.forEach((item) =>
      response.push({
        x: item.date.slice(2),
        y: item[type].value === "통증"?0:item[type].value,
      })
    );
    return [
      {
        id: title,
        data: response,
      },
    ];
  }

  return (
    <Box height="45vh" width="45vh">
      <Box>
        <Typography
          variant="h3"
          color={colors.greenAccent[400]}
          fontWeight="bold"
          m="18px 0"
        >
          {title} 기록 비교
        </Typography>
      </Box>
      <Box height="45vh" width="45vh">
        <ResponsiveLine
          data={createData()}
          margin={{ top: 50, right: 100, bottom: 100, left: 70 }}
          theme={nivoTheme(colors)}
          colors={{ scheme: "pastel1" }}
          xScale={{ type: "point" }}
          yScale={{
            type: "linear",
            min: "auto",
            max: "auto",
            stacked: true,
            reverse: false,
          }}
          yFormat=" >-.2f"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            orient: "bottom",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 45,
            legend: "date",
            legendOffset: 70,
            legendPosition: "middle",
          }}
          axisLeft={{
            orient: "left",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "record",
            legendOffset: -50,
            legendPosition: "middle",
          }}
          pointSize={10}
          pointColor={{ theme: "background" }}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          pointLabelYOffset={-12}
          useMesh={true}
          legends={[
            {
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 100,
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: "left-to-right",
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: "circle",
              symbolBorderColor: "rgba(0, 0, 0, .5)",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemBackground: "rgba(0, 0, 0, .03)",
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      </Box>
    </Box>
  );
};


export default MuscleLine