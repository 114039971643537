import Header from "../../../Header"
import { Typography, Box, useTheme, Avatar } from "@mui/material";
import { tokens } from "../../../../theme";

const BriefDetail = ({balance, speed, musculus}) =>{
  //console.log(balance, speed, musculus)
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (balance&& speed&&musculus&&
    <>
      <Typography
        variant="h3"
        color={colors.greenAccent[400]}
        fontWeight="bold"
        mb="15px"
      >
       세부 점수
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        margin="3px 0"
        width="250px"
        justifyContent="space-between"
      >
        <Box>
          <Typography variant="h4" fontWeight="bold" color="textPrimary">
            균형
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          width="120px"
          justifyContent="space-between"
          margin="5px 0"
        >
          <Box
            backgroundColor={
              balance.value === "미흡"
                ? colors.redAccent[700]
                : balance.value === "보통"
                ? colors.blueAccent[800]
                : colors.greenAccent[500]
            }
            padding="5px 8px"
            borderRadius="10px"
          >
            {balance.value}
          </Box>
          <Box
            backgroundColor={
              balance.value === "미흡"
                ? colors.redAccent[900]
                : balance.value === "보통"
                ? colors.blueAccent[800]
                : colors.greenAccent[800]
            }
            padding="5px 10px"
            borderRadius="10px"
          >
            {balance.score}점 / 4점
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        margin="3px 0"
        width="250px"
      >
        <Box>
          <Typography variant="h4" fontWeight="bold" color="textPrimary">
            도보
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          width="120px"
          justifyContent="space-between"
          margin="3px 0"
        >
          <Box
            backgroundColor={
              speed.value === "미흡"
                ? colors.redAccent[700]
                : speed.value === "보통"
                ? colors.blueAccent[800]
                : colors.greenAccent[500]
            }
            padding="5px 8px"
            borderRadius="10px"
          >
            {speed.value}
          </Box>
          <Box
            backgroundColor={
              speed.value === "미흡"
                ? colors.redAccent[900]
                : speed.value === "보통"
                ? colors.blueAccent[800]
                : colors.greenAccent[800]
            }
            padding="3px 10px"
            borderRadius="10px"
          >
            {speed.score}점 / 4점
          </Box>
        </Box>
      </Box>
      <Box
        width="250px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        margin="3px 0px"
      >
        <Box marginRight="30px">
          <Typography variant="h4" fontWeight="bold" color="textPrimary">
            하지 근력
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          width="120px"
          justifyContent="space-between"
          margin="3px 0"
        >
          <Box
            backgroundColor={
              musculus.value === "미흡"
                ? colors.redAccent[700]
                : musculus.value === "보통"
                ? colors.blueAccent[500]
                : colors.greenAccent[500]
            }
            padding="5px 8px"
            borderRadius="10px"
          >
            {musculus.value}
          </Box>
          <Box
            backgroundColor={
              musculus.value === "미흡"
                ? colors.redAccent[900]
                : musculus.value === "보통"
                ? colors.blueAccent[800]
                : colors.greenAccent[800]
            }
            padding="5px 10px"
            borderRadius="10px"
          >
            {musculus.score}점 / 4점
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default BriefDetail;